@charset "UTF-8";
/* ----------------------------------------------------------------
拠点・グループ会社　国内拠点のみのスタイル
-----------------------------------------------------------------*/
.c-container--officer {
    .c-inner--officer--details {
        @media #{$querySp} {
            margin-bottom: 40px;
        }
        @media #{$queryPc} {
            margin-bottom: 100px;
        }
        h3 {
            font-weight: 600;
            background: #efefef;
            text-align: center;
            @media #{$querySp} {
                font-size: 2.0rem;
                line-height: 2.4;
                margin-bottom: 20px;
            }
            @media #{$queryPc} {
                font-size: 2.4rem;
                line-height: 3;
                margin-top: 80px;
                margin-bottom: 50px;
            }
        }
        .wrap-officer {
            @media #{$querySp} {
            }
            @media #{$queryPc} {
                margin-bottom: 0;
            }
            .wrap--inner--officer {
                @media #{$querySp} {
                    width: 100%;
                    padding: 0 0 15px;
                }
                @media #{$queryPc} {
                    margin-bottom: 40px;
                    padding-bottom: 40px;
                }
                .photo {
                    @media #{$querySp} {
                        width: 95%;
                        margin: 0 auto 20px;
                    }
                    @media #{$queryPc} {
                        width: 320px;
                    }
                }
                
                .officer-details {
                    @media #{$querySp} {
                        width: 100%;
                        margin: 0 auto 30px;
                    }
                    @media #{$queryPc} {
                        padding: 0 20px;
                    }
                    .p-position {
                        font-weight: 600;
                        /*letter-spacing: .11em;*/
                        @media #{$querySp} {
                            font-size: 1.6rem;
                            margin: 0 0 10px;
                        }
                        @media #{$queryPc} {
                            font-size: 1.8rem;
                            margin: 15px 0 20px;
                        }
                    }
                    .p-name {
                        font-weight: 600;
                        /*letter-spacing: .11em;*/
                        @media #{$querySp} {
                            font-size: 2rem;
                            margin-bottom: 30px;
                        }
                        @media #{$queryPc} {
                            font-size: 2.4rem;
                            line-height: 1;
                            margin-bottom: 40px;
                            
                        }
                        span {
                            font-weight: normal;
                            display: block;
                            line-height: 1.8;
                            @media #{$querySp} {
                                font-size: 1.2rem;
                            }
                            @media #{$queryPc} {
                                font-size: 1.4rem;
                            }
                        }
                    }
                }
            }
            .biography {
                    @media #{$querySp} {
                        width: 100%;
                    }
                    @media #{$queryPc} {
                        width: 63%;
                        
                    }
                    .p-biography {
                        font-weight: 600;
                        line-height: 1;
                        @media #{$querySp} {
                            font-size: 2rem;
                            margin-bottom: 20px;
                        }
                        @media #{$queryPc} {
                            font-size: 2.4rem;
                            margin-bottom: 30px;
                        }
                        
                    }
                table {
                    width: 100%;
                    tr {
                        border-bottom: solid 1px #000;
                        &:last-child {
                            border-bottom: none;
                        }
                        th {
                            font-weight: 600;
                            @media #{$querySp} {
                                /*font-size: 1.4rem;*/
                                display: block;
                                width: 100%;
                                padding: 10px 0 0;
                            }
                            @media #{$queryPc} {
                                /*font-size: 1.6rem;*/
                                width: 17%;
                                padding: 10px 0;
                            }
                        }
                        td {
                            font-weight: 600;
                            @media #{$querySp} {
                                /*font-size: 1.4rem;*/
                                display: block;
                                width: 100%;
                                padding: 0 0 10px;
                            }
                            @media #{$queryPc} {
                                /*font-size: 1.6rem;*/
                                width: 80%;
                                padding: 10px 0;
                                
                            }
                        }
                    }
                }
                }
        }
    }
}