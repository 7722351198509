@charset "UTF-8";
/* ----------------------------------------------------------------
社長メッセージのみのスタイル
-----------------------------------------------------------------*/
.c-container--vision {
    @media #{$querySp} {
        width: 95%;
        margin: auto;
	}
	@media #{$queryPc} {
	}
    .p-leed {
        font-weight: 600;
        @media #{$querySp} {
            font-size: 1.6rem;
            margin-bottom: 39px;
            line-height: 24px;
        }
        @media #{$queryPc} {
            font-size: 2rem;
            margin-bottom: 78px;
            line-height: 40px;
        }
    }
    p {
        letter-spacing: .07em;
        @media #{$querySp} {
            font-size: 1.4rem;
            margin-bottom: 35px;
            line-height: 24px;
        }
        @media #{$queryPc} {
            font-size: 1.6rem;
            margin-bottom: 60px;
            line-height: 36px;
        }
    }
    .bg_gray {
        background: #efefef;
        @media #{$querySp} {
            padding: 30px 0 10px;
            margin-bottom: 70px;
        }
        @media #{$queryPc} {
            padding: 42px 0;
            margin-bottom: 140px;
        }
    }
    .wrap--vision {
        margin: 0 auto;
        div {
            position: relative;
            background: #fff;
            @media #{$querySp} {
                width: 100%;
                margin: 0 0 20px;
            }
            @media #{$queryPc} {
                width: 31%;
            }
            h3 {
                color: #fff;
                background: #5d639e;
                font-weight: 600;
                text-align: center;
                @media #{$querySp} {
                    font-size: 1.6rem;
                    margin: 0 0 15px;
                    padding: 10px 0;
                }
                @media #{$queryPc} {
                    font-size: 2.4rem;
                    width: 100%;
                    margin: 45px 0 30px -22px;
                    padding: 10px 0;
                }
            }
            ul {
                @media #{$querySp} {
                    padding-bottom: 20px;
                }
                @media #{$queryPc} {
                    padding-bottom: 45px;
                }
                
                li {
                    font-weight: 600;
                    text-align: center;
                    @media #{$querySp} {
                        font-size: 1.4rem;
                        line-height: 1.6;
                    }
                    @media #{$queryPc} {
                        font-size: 2rem;
                        line-height: 2;
                    }
                }
            }
        }
    }
}