@charset "UTF-8";
/* ----------------------------------------------------------------
研究開発のみのスタイル
-----------------------------------------------------------------*/
/* 部門長メッセージ
-----------------------------------------------------------------*/
.c-container--research {
    @media #{$querySp} {
    }
    @media #{$queryPc} {
    }
    .list--report {
        width: 100%;
        @media #{$querySp} {
            margin: 20px 0 25px 0;
        }
        @media #{$queryPc} {
            margin: 30px 0 50px 0;
        }
        li {
            float:left;
            @media #{$querySp} {
                width: 48%;
                margin: 0 2% 10px 0;
                &:nth-child(2n) {
                    float: right;
                    margin: 0 0 10px 2%;
                }
            }
            @media #{$queryPc} {
                width: 160px;
                margin: 0 4px 4px 0;
                &:nth-child(6n) {
                    margin: 0 0 4px 0;
                }
            }
        a {
            color: #000;
            display: block;
            padding: 10px 0;
            font-size: 1em;
            font-weight: bold;
            border:1px solid #000;
            box-sizing: border-box;
            text-align: center;
            &:hover {
                color: #FFF;
                background: $clrKey;
                -webkit-transition: all .2s ease-in-out 0s;
                transition: all .2s ease-in-out 0s;
            }
            }
        }
    }
    .section--report {
        clear: both;
        @media #{$querySp} {
            margin-bottom: 40px;
        }
        @media #{$queryPc} {
            margin-bottom: 80px;
        }
        .scroll-table {
            @media #{$querySp} {    
                overflow: scroll;
                      -webkit-overflow-scrolling: touch;
            }
            @media #{$queryPc} {
                margin-top: 30px;
            }
        th {
            border: solid 1px #cccccc;
            @media #{$querySp} {
            }
            @media #{$queryPc} {
            }
            &:nth-child(1) {
                width: 23%;
            }
            &:nth-child(2) {
                width: 13%;
            }
            &:nth-child(3) {
                width: 42%;
            }
            &:nth-child(4) {
                width: 22%;
            }
        }
        td {
            border-top: solid 1px #cccccc;
            border-right: solid 1px #cccccc;
            border-bottom: solid 1px #cccccc !important;
            border-left: solid 1px #cccccc;
            @media #{$querySp} {
            }
            @media #{$queryPc} {
            }
        }
    }
    }
    .clearfix::after {
   content: "";
   display: block;
   clear: both;
}
}

