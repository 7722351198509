@charset "UTF-8";
/* ----------------------------------------------------------------
 ir/report
-----------------------------------------------------------------*/
.c-container--ir {
    .c-inner--md {
        padding: 0;
        @media #{$querySp} {
            width: 95%;
            margin: 0 auto 40px;
        }
    }
    .c-inner--md.c-inner--980{
        padding: 0 10px;
    }
}

.wrap--ir {
    &.wrap--ir_report {
        .wrap-report_pdf {
            .wrap-date_box {
                @media #{$querySp} {
                    margin: 0 0 40px 0;
                }
                @media #{$queryPc} {
                    margin: 0 0 80px 0;
                }
                a {
                    float:left;
                    display: block;
                    box-sizing: border-box;
                    border: 1px solid #000;
                    text-align: center;
                    color: #000;
                    font-weight: bold;
                    &:hover {
                        color: #FFF;
                        background: $clrKey;
                        -webkit-transition: all .2s ease-in-out 0s;
                        transition: all .2s ease-in-out 0s;
                    }
                    @media #{$querySp} {
                        width: 48%;
                        padding: 6px 0;
                        margin: 0 2% 10px 0;
                        &:nth-child(2n) {
                            margin: 0 0 10px 2%;
                        }
                    }
                    @media #{$queryPc} {
                        width: 136px;
                        margin-right: 4.66666px;
                        margin-bottom: 4px;
                        padding: 10px 0;
                        &:nth-child(7n) {
                            margin-right: 0;
                        }
                    }
                }
                &.col_8 {
                    a {
                        @media #{$querySp} {

                        }
                        @media #{$queryPc} {
                            width: 120px;
                            margin-right: 2.85px;
                            padding: 10px 0;
                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
            .wrap-date_content {
                @media #{$querySp} {
                    margin: 0 0 20px 0;
                }
                @media #{$queryPc} {
                    margin: 0 0 40px 0;
                }
                h5 {
                    font-size: 1.5em;
                    font-weight: bold;
                    @media #{$querySp} {
                        margin: 0 0 9px 0;
                    }
                    @media #{$queryPc} {
                        margin: 0 0 18px 0;
                    }
                }
                .wrap-pdf_table {
                    table {
                        &.tbl_type_01 {
                            width: 100%;
                            th {
                                background: #efefef;
                                border: 1px solid #000;
                                box-sizing: border-box;
                                text-align: center;
                                @media #{$querySp} {
                                    padding: 6px 0;
                                }
                                @media #{$queryPc} {
                                    padding: 12px 0;
                                    width: 160px;
                                    &:first-child {
                                        width: 340px;
                                    }
                                }
                            }
                            td {
                                border: 1px solid #000;
                                box-sizing: border-box;
                                text-align: center;
                                font-weight: initial;
                                @media #{$querySp} {
                                    padding: 6px 0;
                                    &:last-child {
                                        width: 26%;
                                    }
                                }
                                @media #{$queryPc} {
                                    padding: 12px 0;
                                }
                                a {
                                    position: relative;
                                    color: #000;
                                    &:hover{
                                        /*text-decoration: underline;*/
                                    }
                                    &.pdf {
                                        &:after {
                                            position: absolute;
                                            display: inline-block;
                                            content: "";
                                            background: url("../images/ico_pdf.png") no-repeat center top;
                                            @media #{$querySp} {
                                                right: -3px;
                                                top: 3px;
                                                width: 10px;
                                                height: 12px;
                                                padding: 0 0 4px 0;
                                                background-size: 10px auto;
                                            }
                                            @media #{$queryPc} {
                                                right: -8px;
                                                top: 3px;
                                                width: 18px;
                                                height: 22px;
                                                padding: 0 0 8px 0;
                                                background-size: 18px auto;
                                            }
                                        }
                                    }
                                    &.movie {
                                        &:after {
                                            position: absolute;
                                            display: inline-block;
                                            content: "";
                                            background: url("../images/ir/report/briefing_02.jpg") no-repeat center top;
                                            @media #{$querySp} {
                                                top: 1px;
                                                right: -20px;
                                                width: 20px;
                                                height: 20px;
                                                background-size: 12px auto;
                                            }
                                            @media #{$queryPc} {
                                                top: -3px;
                                                right: -30px;
                                                width: 20px;
                                                height: 20px;
                                                background-size: 20px auto;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &.tbl_type_02 {
                            @media #{$querySp} {
                                width: 100%;
                            }
                            th {
                            }
                            td {
                                border: 1px solid #000;
                                box-sizing: border-box;
                                text-align: center;
                                font-weight: initial;
                                @media #{$querySp} {
                                    padding: 6px;
                                    &:nth-child(2){
                                        width: 10%;
                                    }
                                }
                                @media #{$queryPc} {
                                    padding: 12px 0;
                                    &:nth-child(1){
                                        width: 434px;
                                    }
                                    &:nth-child(2){
                                        width: 316px;
                                    }
                                }
                                a {
                                    position: relative;
                                    color: #000;
                                    &:hover{
                                        text-decoration: underline;
                                    }
                                    &.pdf {
                                        &:after {
                                            position: absolute;
                                            display: inline-block;
                                            content: "";
                                            background: url("../images/ico_pdf.png") no-repeat center top;
                                            @media #{$querySp} {
                                                /*right: -16px;*/
                                                width: 10px;
                                                height: 12px;
                                                padding: 0 0 4px 0;
                                                background-size: 10px auto;
                                                margin-left: -4px;
                                            }
                                            @media #{$queryPc} {
                                                right: -9px;
                                                top: 3px;
                                                width: 18px;
                                                height: 22px;
                                                padding: 0 0 8px 0;
                                                background-size: 18px auto;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .wrap-annual {
            h5 {
                font-size: 1.5em;
                font-weight: bold;
                margin-bottom: 0;
            }
            .annual_date {
                text-align: right;
                @media #{$querySp} {
                    margin-bottom: 8px;
                }
                @media #{$queryPc} {
                    margin-bottom: 16px;
                }
            }
            .wrap-left_box {
                @media #{$querySp} {
                    .wrap-img {
                        width: 60%;
                        margin: 0 auto 20px;
                        img {
                            width: 100%;
                        }
                    }
                }
                @media #{$queryPc} {
                    float: left;
                    width: 490px;
                }
            }
            .wrap-right_box {
                @media #{$querySp} {
                    width: 100%;
                    margin: 0 0 20px 0;
                }
                @media #{$queryPc} {
                    float: left;
                    width: 490px;
                }
                .wrap-all_dl {
                    a {
                        position: relative;
                        display: inline-block;
                        /*border: 1px solid #000;*/
                        background: #ADB0CE;
                        color: #FFF;
                        &:hover {
                            background-color: #5d639e;
                            color: #FFF;
                        }
                        @media #{$querySp} {
                            width: 100%;
                            padding: 9px 0 9px 40px;
                            margin-bottom: 10px;
                            &:before {
                                position: absolute;
                                display: inline-block;
                                content: "";
                                width: 18px;
                                height: 22px;
                                background: url("../images/ico_pdf_wh.png") no-repeat center top;
                                background-size: 18px auto;
                                left: 16px;
                            }
                            &:after{
                                width: 6px;
                                height: 10px;
                                content: "";
                                display: inline-block;
                                background: url("../images/ico_arrow_r_wh.svg") no-repeat center top;
                                background-size: 6px auto;
                                position: absolute;
                                right: 17px;
                                top: 43%;
                            }
                        }
                        @media #{$queryPc} {
                            width: 300px;
                            padding: 18px 0 18px 40px;
                            margin-bottom: 20px;
                            &:before {
                                position: absolute;
                                top: 20px;
                                left: 16px;
                                display: inline-block;
                                content: "";
                                width: 18px;
                                height: 22px;
                                background: url("../images/ico_pdf_wh.png") no-repeat center top;
                                background-size: 18px auto;

                            }
                            &:after{
                                width: 6px;
                                height: 10px;
                                content: "";
                                display: inline-block;
                                background: url("../images/ico_arrow_r_wh.svg") no-repeat center top;
                                background-size: 6px auto;
                                position: absolute;
                                right: 17px;
                                top: 43%;
                            }
                        }
                    }
                }
                .wrap-single_dl {
                    ul {
                        li {
                            @media #{$querySp} {
                                margin-bottom: 12px;
                            }
                            @media #{$queryPc} {
                                margin-bottom: 8px;
                            }
                            a {
                                color: #ff5611;
                                position: relative;
                                padding: 0 0 0 24px;
                                &:hover{
                                    text-decoration: underline;
                                }
                                &:after{
                                    position: absolute;
                                    top: -2px;
                                    left: 0px;
                                    display: inline-block;
                                    content: "";
                                    width: 18px;
                                    height: 22px;
                                    background: url("../images/ico_pdf.png") no-repeat center top;
                                    background-size: 18px auto;
                                }
                            }
                        }
                    }
                }
            }
            .wrap-backnumber {
                @media #{$querySp} {
                    margin-bottom: 95px;
                }
                @media #{$queryPc} {
                    margin-bottom: 190px;
                }
                h5 {
                    @media #{$querySp} {
                        margin-bottom: 14px;
                    }
                    @media #{$queryPc} {
                        margin-bottom: 28px;
                    }
                }
                .wrap-backnumber_pdf {
                    a {
                        float: left;
                        display: block;
                        margin-right: 17.5px;
                        text-align: center;
                        .wrap-img {
                            @media #{$querySp} {
                                height: auto;
                                img {
                                    width: 100%;
                                }
                            }
                            @media #{$queryPc} {
                                height: 260px;
                            }
                        }
                        @media #{$querySp} {
                            width:48%;
                            &:nth-child(odd){
                                margin: 0 2% 20px 0;
                            }
                            &:nth-child(even){
                                margin: 0 0 20px 2%;
                            }
                        }
                        @media #{$queryPc} {
                            width:182px;
                            &:nth-child(5n) {
                                margin-right: 0px;
                            }
                        }
                        .wrap-ttl {
                            position: relative;
                            color: #ff5611;
                            padding: 0 0 0 24px;
                            &:hover {
                                text-decoration: underline;
                            }
                            @media #{$querySp} {
                                margin: 6px auto;
                                &:after{
                                    position: absolute;
                                    top: -2px;
                                    left: 8%;
                                    display: inline-block;
                                    content: "";
                                    width: 18px;
                                    height: 22px;
                                    background: url("../images/ico_pdf.png") no-repeat center top;
                                    background-size: 18px auto;
                                }
                            }
                            @media #{$queryPc} {
                                margin: 12px auto;
                                &:after{
                                    position: absolute;
                                    top: 2px;
                                    left: 0px;
                                    display: inline-block;
                                    content: "";
                                    width: 18px;
                                    height: 22px;
                                    background: url("../images/ico_pdf.png") no-repeat center top;
                                    background-size: 18px auto;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.col_2 {
        justify-content: initial;
        .wrap-menu {
            @media #{$querySp} {
                margin-bottom: 30px;
            }
            @media #{$queryPc} {
                float: left;
                margin: 0 2% 0 0;
            }
        }
        :last-child.wrap-menu {
            margin: 0 0 0 0;
        }
    }
}
