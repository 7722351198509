@charset "UTF-8";
/* ----------------------------------------------------------------
SDSのみのスタイル
-----------------------------------------------------------------*/
/* SDS
-----------------------------------------------------------------*/
.sds-contents {
    
    @media #{$querySp} {
        width: 95%;
        margin: 60px auto;

	}
	@media #{$queryPc} {
        margin: 60px auto 100px;
	}
    ul {
        &.checkbox {
            text-align: left;
            @media #{$querySp} {
                margin: 30px auto;
            }
            @media #{$queryPc} {
                
            }
        }
        
        li {
            @media #{$querySp} {
                width: 100%;
                margin: 0 0 15px;
            }
            @media #{$queryPc} {
                display: inline-block;
                width: 33%;
                line-height: 1;
                margin: 0 0 20px;
                padding-right: 30px;
            }
            &:nth-child(3n) {
                padding: 0;
            }
            
            label {
                font-size: 1.4rem;
                span {
                    @media #{$querySp} {}
                    @media #{$queryPc} {
                        display: inline-block;
                        width: 90%;
                        line-height: 1.3;
                        vertical-align: top;
                    }
                }
            }
        }
        
    }
    .move {
        @media #{$querySp} {
        }
        @media #{$queryPc} {
            width: 60%;
        }
    }
}

