@charset "UTF-8";
/* ----------------------------------------------------------------
CSRのみのスタイル
-----------------------------------------------------------------*/
/* トクヤマグループのマテリアリティ
-----------------------------------------------------------------*/
.c-container--csr {
    @media #{$querySp} {
    }
    @media #{$queryPc} {
    }
    h3 {
        &.bottom-line {
            font-weight: 600;
            position: relative;
            display: block;
            border-bottom: solid 1px #000;
            @media #{$querySp} {
                font-size: 1.8rem;
                margin-bottom: 10px;
            }
            @media #{$queryPc} {
                font-size: 2rem;
                margin-bottom: 20px;
            }
        }
    }
    h4.h4 {
        span {
            @media #{$querySp} {
                display: block;
            }
            @media #{$queryPc} {
                margin-left: 10px;
            }
            img {
                margin-right: 5px;
            }
        }
    }
    div {
        &.u-indent {
            @media #{$querySp} {
                margin-bottom: 20px;
            }
            @media #{$queryPc} {
                margin-bottom: 40px;
            }
            .u-indent {
                text-indent: 0;
                padding-left: 2em;
                @media #{$querySp} {
                }
                @media #{$queryPc} {
                }
            }
        }
    }
}
.c-container--csr--materiality {
    .section--csr {
        @media #{$querySp} {
        }
        @media #{$queryPc} {
        }
        &.section--csr--materiality {
            @media #{$querySp} {
            }
            @media #{$queryPc} {
            }
            ul {
                @media #{$querySp} {
                    margin-left: 20px;
                }
                @media #{$queryPc} {
                }
                li {
                    list-style: disc;
                    ul {
                        li {
                            list-style: none;
                        }
                    }
            }
            }
        }
    }
    .s-section--csr {
        @media #{$querySp} {
        }
        @media #{$queryPc} {
        }
        &.section--csr--materiality {
            @media #{$querySp} {
            }
            @media #{$queryPc} {
            }
            ul {
                @media #{$querySp} {
                    margin-left: 20px;
                }
                @media #{$queryPc} {
                    margin-left: 25px;
                }
                li {
                    list-style: disc;
                    ul {
                        @media #{$querySp} {
                            margin-left: 5px;
                        }
                        @media #{$queryPc} {
                            margin-left: 5px;
                        }
                        li {
                            list-style: none;
                        }
                    }
            }
            }
        }
    }
    .indent {
        @media #{$querySp} {
            margin-bottom: 20px;
            margin-left: 10px;
        }
        @media #{$queryPc} {
            margin-bottom: 30px;
            margin-left: 40px;
        }
    }
}
