@charset "UTF-8";
/*----------------------------------------------------------------
パンくずのコンポーネント
-----------------------------------------------------------------*/
.c-breadcrumb {

}

.c-breadcrumbBody {

}

.c-breadcrumb__item {
	display: inline-block;
	& span {
	}
	&:not(:last-child) {
		&:after {
			content: ">";
		}
	}
}

//modifier
.c-breadcrumb--home {

}

.c-breadcrumb--active {

}

/*----------------------------------------------------------------
パンくずのコンポーネント
-----------------------------------------------------------------*/
.c-breadcrumb {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		padding-left: 32px;
	}
	&--bordertop {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			border-top: 1px solid #000;
		}
	}
}

.c-breadcrumbBody {
	@media #{$querySp} {
		display: none;
	}
	@media #{$queryPc} {
		padding: 8px 0;
	}
}

.c-breadcrumb__item {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		display: inline-block;
		margin-right: 5px;
		font-size: 1.2rem;
		line-height: 1;
	}
	a {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			color: #000;
			@include trans($duration: .1s,$delay: 0s)
		}
		&:hover {
			@media #{$querySp} {
			}
			@media #{$queryPc} {
				@include opacity(.5);
			}
		}
	}
	&:not(:last-child) {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
		&:after {
			@media #{$querySp} {
			}
			@media #{$queryPc} {
				// content: "・";
				display: inline-block;
				padding-left: 5px;
				color: #000;
			}
		}
	}
}
