@charset "UTF-8";
/* ----------------------------------------------------------------
テーブルのコンポーネント
-----------------------------------------------------------------*/

.c-table {
	width: 100%;
	border-bottom: 1px solid $clrBorderTable;
	border-left: 1px solid $clrBorderTable;
	&:not(:first-of-type) {
		margin-top: #{$mgNormal}px;
	}
}

//thead
.c-table thead {}

.c-table thead tr {}

.c-table thead tr th {
	border-top: 1px solid $clrBorderTable;
	border-right: 1px solid $clrBorderTable;
}

.c-table thead tr td {}

//tbody
.c-table tbody {}

.c-table tbody tr {}

.c-table tbody tr  {
	& th , td {
		border-top: 1px solid $clrBorderTable;
		border-right: 1px solid $clrBorderTable;
		vertical-align: middle;
		font-weight: normal;
	}
}

.c-table tbody tr th {}

.c-table tbody tr td {}

/* ----------------------------------------------------------------
テーブルのコンポーネント
-----------------------------------------------------------------*/

.c-table {
	&:not(:first-of-type) {
	}
}

//thead
.c-table thead {}

.c-table thead tr {
	background-color: $clrMain;
	color: $clrWht;
}

.c-table thead tr th {
	font-weight: 600;
	text-align: center;
	border: none;
	vertical-align: middle;
	@media #{$querySp} {
		padding: 10px;
		white-space: nowrap;
	}
	@media #{$queryPc} {
		white-space: nowrap;
		padding: 15px 30px;
	}
}

.c-table thead tr td {}


//tbody
.c-table tbody {}

.c-table tbody tr {}

.c-table tbody tr  {
	& th , td {
		@media #{$querySp} {
			padding: 10px;
		}
		@media #{$queryPc} {
			/*min-width: 215px;*/
			padding: 15px 30px;
		}
	}
	// @media ( min-width : 768px ) {
	// 	&:nth-child(even) {
	// 		background-color: #fafafa;
	// 	}
	// }

	&:nth-child(even) {
		background-color: #fafafa;
	}
}

.c-table tbody tr th {
	font-weight: 600;
}

.c-table tbody tr td {}

.c-table--thLeft thead tr th {
	text-align: left;
}

.c-table--tdCenter tbody tr th {
	text-align: center;
}

.c-table--thCenter tbody tr td {
	text-align: center;
}

.c-table--listType {
	border-left: none;
}

.c-table--listType tbody tr {
	& th , td {
		border-right: none;
	}
	&:nth-child(even) {
		background-color: #fafafa;
	}
}

.c-table--dlType {
	border-left: none;
}

.c-table--dlType tbody tr {
	& th , td {
		border-right: none;
	}
	&:nth-child(even) {
		background-color: #fafafa;
	}
}

.c-table--dlType tbody tr th {
	font-weight: 600;
	color: $clrBlck;
	@media #{$querySp} {
		display: block;
		padding-bottom: 4px;
	}
	@media #{$queryPc} {
		width: 240px;
	}
}

.c-table--dlType tbody tr td {
	@media #{$querySp} {
		display: block;
		border-top: none;
		padding-top: 0;
	}
	@media #{$queryPc} {
		padding-left: 0;
	}
}

.c-tableFolded {
	@media #{$querySp} {
		border: none;
	}
	@media #{$queryPc} {
	}
}

.c-tableFolded thead {
	@media #{$querySp} {
		display: none;
	}
	@media #{$queryPc} {
	}
}

.c-tableFolded thead tr th {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		white-space: normal;
		padding: 15px 10px;
	}
}

.c-tableFolded tbody tr {
	@media #{$querySp} {
		display: block;
		border: none;
		padding: 10px;
	}
	@media #{$queryPc} {
	}
}

.c-tableFolded tbody tr td {
	@media #{$querySp} {
		display: block;
		width: 100%;
		border: none;
		padding: 5px 0;
	}
	@media #{$queryPc} {
		padding: 15px 10px;
	}
}

.c-tableFolded__th {
	@media #{$querySp} {
		display: block;
		margin-bottom: 8px;
		font-size: 1.2rem;
		font-weight: 600;
		border-bottom: 1px solid #e5e5e5;
	}
	@media #{$queryPc} {
		display: none;
	}
}

.c-table{
	.c-textLink{
		display: inline-block;
		+ .c-textLink{
			margin-top: .5em;
		}
	}

	.c-textData{
		display: inline-block;
		+ .c-textData{
			margin-top: .5em;
		}
	}
}

@media ( max-width : 767px ) {
	.c-table_list{
		tr{
			background-color: inherit !important;
		}
		td{
			display: block;
			&:last-child{
				background-color: #fafafa;
			}
		}
	}
}
