@charset "UTF-8";
/* ----------------------------------------------------------------
フォームの設定
-----------------------------------------------------------------*/

.c-form {
	width: 100%;
}

/* 基本設定 */
.c-form input {
	display: none;
	&[type="text"], &[type="tel"], &[type="number"], &[type="email"], &[type="date"], &[type="month"], &[type="week"], &[type="time"], &[type="url"], &[type="password"] {
		font-size: $elmTextSize;
		color: $elmTextColor;
		padding: .5em;
		border: 1px solid $elmBoxItemBdColor;
		background-color: $elmBoxItemBgColor;
		cursor: text;
		display: block;
		width: 100%;
		box-sizing: border-box;
		&:focus {
			border-color: $elmColorPrimary;
		}
	}
}

/* Chrome, Safari */
input::-webkit-input-placeholder{
    color: $elmPlaceHolderColor;
}

/* Firefox */
input::-moz-placeholder{
    color: $elmPlaceHolderColor;
}

/* Firefox 18以前 */
input:-moz-placeholder{
    color: $elmPlaceHolderColor;
}

/* IE */
input:-ms-input-placeholder{
    color: $elmPlaceHolderColor;
}


/* ファイルアップロード */
.c-form input[type="file"]{
	display: inline-block;
}

/* チェックボックス、ラジオボックス　基本設定 */
.c-form input + span {
	line-height: 1.5;
	font-size: $elmTextSize;
	padding-left: $lblPl;
	display: inline-block;
    vertical-align: top;
    margin: 0;

}

/* チェックボックス */
.c-form input[type="checkbox"] {
	& + span {
		position: relative;
		&:before, &:after {
			content: "";
			position: absolute;
			display: block;
			box-sizing: border-box;
		}
		&:before {
			width: $elmWidth;
			height: $elmHeight;
			margin-top: $elmMt;
			top: 50%;
			left: 0;
			background-color: $elmBoxItemBgColor;
			border: 1px solid $elmBoxItemBdColor;
		}
		&:after {
			top: 50%;
			left: 0;
		}
		&:hover {
			cursor: pointer;
		}
	}

	&:checked {
		& + span {
			&:before {
				background-color: $elmColor;
			}
			&:after {
				width: $elmCheckWidth;
				height: $elmCheckHeight;
				margin-top: $elmCheckAftrMt;
				margin-left: $elmCheckAftrMl;
				border: 3px solid #fff;
				border-left: none;
				border-bottom: none;
				transform: rotate(135deg);
				border-radius: 0;
			}
		}
	}
}

/* ラジオボックス */
.c-form input[type="radio"] {
	& + span {
		position: relative;
		&:before, &:after {
			content: "";
			position: absolute;
			display: block;
			border-radius: 9999px;
			box-sizing: border-box;
		}
		&:before {
			width: $elmWidth;
			height: $elmHeight;
			margin-top: $elmMt;
			top: 50%;
			left: 0;
			background-color: $elmBoxItemBgColor;
			border: 1px solid $elmBoxItemBdColor;
			z-index: 2;
		}
		&:after {
			top: 50%;
			left: 0;
			z-index: 3;
		}
		&:hover {
			cursor: pointer;
		}
	}
	&:checked {
		& + span {
			&:after {
				width: $elmRadioWidth;
				height: $elmRadioHeight;
				margin-top: $elmAfterMt;
				margin-left: $elmAfterMl;
				background-color: $elmColor;
			}
		}
	}
}


/* 送信ボタン */
.c-form input[type="submit"] {
	display: inline-block;
	border: none;
	cursor: pointer;
  	-webkit-appearance: none;
}

/* テキストエリア */
.c-form textarea {
	border: 1px solid $elmBoxItemBdColor;
	width: 100%;
	padding: .8em;
	font-size: $elmTextSize;
	max-width: 100%;
	box-sizing: border-box;
	display: block;
	margin: 0 0 0 auto;
	line-height: 1.7;
	&:focus {
		border-color: #15c8d0;
	}
}

.c-form {
	// required
	[type="text"].required,
	// [type="tel"].required,
	[type="email"].required,
	textarea.required {

		& + label {
			line-height: inherit;
			padding: 0;
			padding-left: 0;
			margin-top: 0;
			display: block;
			position: absolute;
			top: .5em;
			right: .5em;
			z-index: 1;
			overflow: hidden;
			width: 10px;
			&:after {
				content: "＊";
				font-size: .75em;
				font-weight: bold;
				color: $elmColor;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 2;
				@include trans(.2s);
			}
		}

		&:focus + label:after {
			left: .8em;
		}
	}
}



/* クラスによるスタイルの設定 */
.c-form .mwform-checkbox-field, .c-form .mwform-radio-field {
	display: block;
	&:not(:nth-last-of-type(1)) {
		margin-bottom: $elmBoxItmeMg;
	}
}

/* セレクトボックス */
.c-formItem__select {
	position: relative;
	font-size: $elmTextSize;
	z-index: 1;
	&:after {
		content: '▼';
		position: absolute;
  		top: 50%;
	  	right: 0;
  		transform: translateY(-50%) translateX(-50%);
		font-size: .8em;
		box-sizing: border-box;
	}
	& select {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		width: 100%;
		padding: .5em;
		background-color: #fff;
		border: 1px solid $elmBoxItemBdColor;
		border-radius: 0;
	}
}

/* MW WP CSS */
.mw_wp_form .horizontal-item + .horizontal-item {
    margin-left: 0!important;
}

/* ----------------------------------------------------------------
formのスタイル
-----------------------------------------------------------------*/
.c-form {
	background-color: #fafafa;
	border: 1px solid $clrGry;
	@media #{$querySp} {
		padding: 10px;
		@include borderRadius(3px);
	}
	@media #{$queryPc} {
		padding: 30px 60px;
		@include borderRadius(5px);
	}
}

.c-form--confirm .c-formItem__select:after {
	display: none;
}

.c-form--confirm .c-formItem__select {
	font-size: 1.4rem;
	color: #555;
}

.c-formList2Col {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		display: flex;
    	justify-content: space-between;
	}
}

.c-formList2Col .c-formList {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		width: 48%;
	}
}

.c-formList {
	@media #{$querySp} {
		margin-bottom: 20px;
	}
	@media #{$queryPc} {
		margin-bottom: 33px;
	}
}

.c-formList__label {
	color: #000;
	@media #{$querySp} {
		margin-bottom: 3px;
		font-size: 1.4rem;
	}
	@media #{$queryPc} {
		margin-bottom: 5px;
		font-size: 1.6rem;
	}
}

/* 基本設定 */
.c-form input {
	&[type="text"], &[type="tel"], &[type="number"], &[type="email"], &[type="date"], &[type="month"], &[type="week"], &[type="time"], &[type="url"], &[type="password"] {
		border: 1px solid $clrGry;
		background-color: #fff;
		font-weight: normal;
		@media #{$querySp} {
			@include borderRadius(3px);
			padding: 5px 10px;
			font-size: 1.3rem;
		}
		@media #{$queryPc} {
			@include borderRadius(5px);
			padding: 7px 20px;
		}
	}
}

/* 郵便番号 */
.mwform-zip-field {
	display: block;
	width: 100%;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
	input {
		display: inline-block !important;
		width: auto !important;
		margin: 0 5px;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
		&:first-child {
			margin-left: 10px;
		}
	}
}

/* チェックボックス */
.c-formList__checkbox {
	span {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			display: inline-block !important;
			margin-right: 16px;
		}
	}
}

.c-form input[type="checkbox"] {
	& + span {
		@media #{$querySp} {
			margin-bottom: 5px;
			font-size: 1.3rem;
		}
		&:before {
			margin-top: -9px;
			border: 1px solid $elmBoxItemBdColor;
			@include borderRadius(5px);
		}
		&:after {
			top: 0;
		}
	}

	&:checked {
		& + span {
			&:before {
				background-color: $elmColor;
				border: 1px solid $clrMain;
				@include borderRadius(5px);
			}
			&:after {
				width: 16px;
				height: 16px;
				margin-top: 2px;
				margin-left: 0;
				border: 1px solid $clrMain;
				border-left: none;
				border-bottom: none;
				transform: none;
				@include borderRadius(5px);
				background: url("../images/ico_check.png") no-repeat center top 3px $clrMain;
			}
		}
	}
}

/* ラジオボックス */
.c-formList__radio {
	span {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			display: inline-block !important;
			margin-right: 16px;
		}
	}
}

.c-form input[type="radio"] {
	& + span {
		@media #{$querySp} {
			margin-bottom: 5px;
			font-size: 1.3rem;
		}
		&:before {
			margin-top: -9px;
		}
		&:after {
			top: 0;
		}
	}
	&:checked {
		& + span {
			&:after {
				width: 16px;
				height: 16px;
				margin-top: 2px;
				margin-left: 0;
				background-color: $elmBoxItemBgColor;
				border: 5px solid $clrMain;
			}
		}
	}
}

/* セレクトボックス */
.c-formItem__select {
	color: #000;
	@media #{$querySp} {
		font-size: 1.3rem;
	}
	@media #{$queryPc} {
		font-size: 1.6rem;
	}
	&:after {
		content: "";
		border: 0px;
		-ms-transform: rotate(135deg);
		-webkit-transform: rotate(135deg);
		transform: rotate(135deg);
		position: absolute;
		bottom: 0;
		/*margin: auto 0;*/
		@include borderRadius(1px)
		border-top: solid 2px #15c8d0;
		border-right: solid 2px #15c8d0;
		width: 8px;
		height: 8px;
		right: 16px;
		pointer-events: none;
		@media #{$querySp} {
			/*top: 12px;*/
		}
		@media #{$queryPc} {
			/*top: 17px;*/
		}
	}
	& select {
		@media #{$querySp} {
			padding: 5px 10px;
			@include borderRadius(3px);
		}
		@media #{$queryPc} {
			padding: 7px 20px;
			@include borderRadius(5px);
		}
	}
}

/* テキストエリア */
.c-form textarea {
	background-color: #fff;
	@media #{$querySp} {
		margin-bottom: 5px;
		min-height: 150px;
		@include borderRadius(3px);
		font-size: 1.3rem;
	}
	@media #{$queryPc} {
		min-height: 300px;
		margin-bottom: 10px;
		padding: 10px 20px;
		@include borderRadius(5px);
	}
}

.c-formList__button--2col {
	width: 100%;
	overflow: hidden;
	.c-formList__button {
		display: inline-block;
		width: 50%;
		box-sizing: border-box;
		float: left;
		&:first-child {
			padding-right: 20px;
		}
		&:last-child {
			padding-left: 20px;
		}
	}
}

/* 送信ボタン */
.c-formList__button {
	position: relative;
	&:after {
		content: "";
		border: 0px;
		-ms-transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
		position: absolute;
		top: 0;
		bottom: 0;
		margin: auto;
		@include borderRadius(1px)
		border-top: solid 2px #fff;
		border-right: solid 2px #fff;
		width: 12px;
		height: 12px;
		right: 30px;
	}
}

.c-form input[type="submit"] {
	width: 100%;
	padding: 21px;
	color: #fff;
	text-align: center;
	font-size: 1.8rem;
	background-color: #000;
	/*@include borderRadius(5px);*/
	&:hover {
		opacity: .7;
		@include trans($duration: .2s,$delay: 0s)
	}
}

.c-formList__button--back input[type="submit"] {
	background-color: #808080;
}

.c-formList__button--back.c-formList__button:after {
	-ms-transform: rotate(-135deg);
	-webkit-transform: rotate(-135deg);
	transform: rotate(-135deg);
	left: 30px;
	right: inherit;
	top: 0;
	bottom: 0;
	margin: auto;
}

.c-formError--input {
	input, textarea {
		border: 2px solid #e90303;
		background-color: #faf0f0;
	}
}

/* セレクトボックス */
.c-formItem__select {
	& select {
		color: #000;
		padding: 7px 20px;
		@include borderRadius(5px);
		font-weight: normal;
	}
}

.c-formItem__select--inline {
	display: inline-block;
	width: 120px;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

/* 確認・戻る・送信ボタン */
.c-formList__btn {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

.c-formList__btn--2col {
	display: flex;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

.c-formList__btnL {
	width: 50%;
	@media #{$querySp} {
		padding-right: 5px;
	}
	@media #{$queryPc} {
		padding-right: 10px;
	}
}

.c-formList__btnR {
	width: 50%;
	@media #{$querySp} {
		padding-left: 5px;
	}
	@media #{$queryPc} {
		padding-left: 10px;
	}
}

.c-formBtn {
	display: block;
	position: relative;
	width: 100%;
	padding: 21px;
	color: #fff;
	text-align: center;
	font-size: 1.8rem;
	background-color: #000;
	@include borderRadius(5px);
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
	&:hover {
		opacity: .7;
		@include trans($duration: .2s,$delay: 0s)
	}
	&:after {
		content: "";
		border: 0px;
		-ms-transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
		position: absolute;
		top: 0;
		bottom: 0;
		margin: auto;
		@include borderRadius(1px)
		border-top: solid 2px #fff;
		border-right: solid 2px #fff;
		width: 12px;
		height: 12px;
		right: 30px;
	}
}

.c-formBtn--back {
	background-color: #808080;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
	&:after {
		-ms-transform: rotate(-135deg);
		-webkit-transform: rotate(-135deg);
		transform: rotate(-135deg);
	    right: initial;
		left: 30px;
	}
}

/* エラー */
.mw_wp_form .c-form .error {
	display: block;
	padding-left: 0;
	color: #e90303;
	@media #{$querySp} {
		margin-top: 5px;
		font-size: 1.0rem;
	}
	@media #{$queryPc} {
		margin-top: 10px;
		font-size: 1.2rem;
	}
}

.mw_wp_form .c-form .c-formList__radio span.error {
	display: block !important;
}

.input_error-message {
	color: #e90303;
	@media #{$querySp} {
		margin-top: 5px;
		font-size: 1.0rem;
	}
	@media #{$queryPc} {
		margin-top: 10px;
		font-size: 1.2rem;
	}
}

select::-ms-expand {
    display: none;
}
