@charset "UTF-8";
/* ----------------------------------------------------------------
採用情報のみのスタイル
-----------------------------------------------------------------*/
/* 採用情報トップ
-----------------------------------------------------------------*/
.c-container--misc {
    @media #{$querySp} {
    }
    @media #{$queryPc} {
    }
    .c-inner--md {
        @media #{$querySp} {
        }
        @media #{$queryPc} {
        }
        .section--misc {
            @media #{$querySp} {
                margin-bottom: 30px;
            }
            @media #{$queryPc} {
                margin-bottom: 50px;
            }
             a {
                 color: #de5b11;
                 @media #{$querySp} {
                 }
                 @media #{$queryPc} {
                 }
                 &:hover {
                     text-decoration: underline;
                 }
            }
            .wrap-txt_cot_box_04 {
                border:1px solid #000;
                padding: 24px 32px;
                @media #{$querySp} {
                    margin: 0 0 20px 0;
                }
                @media #{$queryPc} {
                    margin: 0 0 20px 0;
                }
                h3 {
                    &.under_line {
                        font-size: 1.125em;
                        font-weight: bold;
                        border-bottom: 1px solid #000;
                        @media #{$querySp} {
                            width: 100%;
                            padding:0 0 7px 0;
                            margin: 0 0 10px 0;
                        }
                        @media #{$queryPc} {
                            width: 498px;
                            padding:0 0 14px 0;
                            margin: 0 0 20px 0;
                        }
                    }

                }
                
                .wrap-txt--li {
                @media #{$querySp} {
                    margin-left: 20px;
                }
                @media #{$queryPc} {
                    margin-left: 27px;
                }
                li {
                    list-style: disc;
                   @media #{$querySp} {
                       
                    }
                    @media #{$queryPc} {
                    } 
                }
            }
            .wrap-txt--ol {
                @media #{$querySp} {
                    margin-left: 20px;
                }
                @media #{$queryPc} {
                    margin-left: 27px;
                }
                li {
                    list-style: decimal;
                   @media #{$querySp} {
                       
                    }
                    @media #{$queryPc} {
                    } 
                    ul {
                        li {
                            list-style: disc;
                           @media #{$querySp} {

                            }
                            @media #{$queryPc} {
                            } 
                        }
                    }
                }
            }
            .wrap-txt--li--cnt {
                @media #{$querySp} {
                    margin-left: 10px;
                }
                @media #{$queryPc} {
                    margin-left: 15px;
                }
                li {
                    position: relative;
                    counter-increment: cnt;
                    @media #{$querySp} {
                        padding-left: 20px;
                       
                    }
                    @media #{$queryPc} {
                        padding-left: 24px;
                    } 
                    &:before {
                        position: absolute;
                        left: 0;
                        display: marker;
                        content: "(" counter(cnt) ") ";
                        @media #{$querySp} {
                        }
                        @media #{$queryPc} {
                        }
                    }
                }
            }
            }
            h4 {
                    &.h4 {
                        font-weight: 600;
                    }
                }
        }
    }
}

.indent_wide{
    padding-left: 1.6em;
    text-indent: -1.6em;
}