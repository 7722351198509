@charset "UTF-8";
/* ----------------------------------------------------------------
_newsのコンポーネット
-----------------------------------------------------------------*/
.c-newsItemWrap {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

.c-newsItemCont {
	position: relative;
	display: flex;
	border-top: 1px solid #F0F0F0;
	@media #{$querySp} {
		flex-wrap: wrap;
		padding: 24px 0;
	}
	@media #{$queryPc} {
		padding: 56px 0;
	}
	&:after {
		position: absolute;
		content: "";
		top: -1px;
		left: 0;
		height: 1px;
		background-color: #000;
		@media #{$querySp} {
			width: 10px;
		}
		@media #{$queryPc} {
			width: 20px;
		}
	}
}

.c-newsItemBox__date {
	position: relative;
	@media #{$querySp} {
		padding-top: 4px;
		width: 20%;
	}
	@media #{$queryPc} {
		padding-top: 8px;
		width: 200px;
	}
}

.c-newsItemBox__txt {
	@media #{$querySp} {
		width: 80%;
		padding-left: 10px;
	}
	@media #{$queryPc} {
		flex: 1;
	}
}

.c-newsItemBox__more {
	position: relative;
	align-self: center;
	text-align: right;
	@media #{$querySp} {
		width: 100%;
		margin-top: 10px;
		padding-right: 10px;
	}
	@media #{$queryPc} {
		width: 190px;
		padding-right: 20px;
	}
	&:after {
		position: absolute;
		content: "";
		top: 0;
		bottom: 0;
		margin: auto 0;
		right: 1px;
		width: 1px;
		height: 7px;
		background-color: #000;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
}

.c-newsItem__date {
	font-family: $fontEnRoboto;
	text-align: center;
	line-height: 1;
	@media #{$querySp} {
		font-size: 1.1rem;
	}
	@media #{$queryPc} {
		font-size: 1.3rem;
	}
}

.c-newsItem__category {
	position: relative;
	font-family: $fontEnRoboto;
	text-align: center;
	line-height: 1;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		margin-top: 16px;
		font-size: 1.3rem;
	}
	&:after {
		position: absolute;
		left: 0;
		right: 0;
		margin: 0 auto;
		content: "";
		height: 1px;
		background-color: #000;
		@media #{$querySp} {
			width: 10px;
			bottom: -4px;
		}
		@media #{$queryPc} {
			width: 20px;
			bottom: -8px;
		}
	}
}

.c-newsItem__DateDay {
	font-family: $fontEnRoboto__normal;
	font-weight: 500;
	@media #{$querySp} {
		font-size: 2.3rem;
	}
	@media #{$queryPc} {
		font-size: 4.6rem;
	}
}

.c-newsItem__headline {
	font-weight: 600;
	@media #{$querySp} {
		margin-bottom: 8px;
		font-size: 1.4rem;
	}
	@media #{$queryPc} {
		margin-bottom: 16px;
		font-size: 2.0rem;
	}
	a {
		color: #000;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			@include trans($duration: .2s,$delay: 0s)
		}
		&:hover {
			@media #{$querySp} {
			}
			@media #{$queryPc} {
				color: $clrLink;
			}
		}
	}
}

.c-newsItem__txt {
	color: rgba(0,0,0,.5);
	@media #{$querySp} {
		line-height: 1.6;
	}
	@media #{$queryPc} {
		line-height: 2.0;
	}
}

.c-newsItem__more {
	font-family: $fontEnRoboto;
	color: #000;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		@include trans($duration: .2s,$delay: 0s)
	}
	&:after {
		display: inline-block;
		content: "";
		vertical-align: middle;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 3px 0 3px 8px;
		border-color: transparent transparent transparent #000;
		@media #{$querySp} {
			margin: -2px 0 0 10px;
		}
		@media #{$queryPc} {
			margin: -2px 0 0 20px;
			@include trans($duration: .2s,$delay: 0s)
		}
	}
	&:hover {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			color: $clrAccent;
		}
		&:after {
			@media #{$querySp} {
			}
			@media #{$queryPc} {
				border-color: transparent transparent transparent $clrAccent;
			}
		}
	}
}

/* トップページ専用style
-----------------------------------------------------------------*/
.c-newsItemWrap--top {
	@media #{$querySp} {
		margin-bottom: 25px;
	}
	@media #{$queryPc} {
		margin-bottom: 50px;
	}
	.c-newsItemCont {
		border-top: 1px solid rgba(255,255,255,.15);
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
		&:after {
			background-color: $clrAccent;
			@media #{$querySp} {
			}
			@media #{$queryPc} {
			}
		}
		&:last-child {
			border-bottom: 1px solid rgba(255,255,255,.15);
			@media #{$querySp} {
			}
			@media #{$queryPc} {
			}
		}
	}
	.c-newsItemBox__date {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}

	.c-newsItemBox__txt {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
	.c-newsItemBox__more {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			width: 200px;
		}
		&:after {
			background-color: #fff;
			@media #{$querySp} {
			}
			@media #{$queryPc} {
			}
		}
	}
	.c-newsItem__date {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
	.c-newsItem__DateDay {
		font-weight: 400;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
	.c-newsItem__headline {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
		a {
			color: #fff;
			@media #{$querySp} {
			}
			@media #{$queryPc} {
				@include trans($duration: .2s,$delay: 0s)
			}
			&:hover {
				@media #{$querySp} {
				}
				@media #{$queryPc} {
					color: $clrLink;
				}
			}
		}
	}
	.c-newsItem__txt {
		color: #fff;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
	.c-newsItem__more {
		color: #fff;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			@include trans($duration: .2s,$delay: 0s)
		}
		&:after {
			border-color: transparent transparent transparent #fff;
			@media #{$querySp} {
			}
			@media #{$queryPc} {
				@include trans($duration: .2s,$delay: 0s)
			}
		}
		&:hover {
			@media #{$querySp} {
			}
			@media #{$queryPc} {
				color: $clrAccent;
			}
			&:after {
				@media #{$querySp} {
				}
				@media #{$queryPc} {
					border-color: transparent transparent transparent $clrAccent;
				}
			}
		}
	}
}