@charset "UTF-8";
/* ----------------------------------------------------------------
拠点・グループ会社　国内拠点のみのスタイル
-----------------------------------------------------------------*/
.hub-jp--jpgroup {
    @media #{$querySp} {
        margin-bottom: 40px;
	}
	@media #{$queryPc} {
        margin-bottom: 100px;
	}
    #transform {
        clear: both;
    }
    .link-list {
            
            @media #{$querySp} {
                margin-bottom: 30px;
            }
            @media #{$queryPc} {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                justify-content: space-between;
                margin-bottom: 40px;
            }
            li {
                
                @media #{$querySp} {
                    width: 50%;
                    float: left;
                    margin-bottom: 10px;
                    }
                @media #{$queryPc} {
                    border-right: solid 1px #000; 
                    line-height: 1;
                }
                &:last-child {
                    @media #{$querySp} {
                    }
                    @media #{$queryPc} {
                            border: none;
                    }
                }
                &:nth-child(2n) {
                    @media #{$querySp} {
                        float: right;
                    }
                    @media #{$queryPc} {
                    }
                }
                a {
                    color: #000;
                    font-weight: 600;
                    @media #{$querySp} {
                        font-size: 1.4rem;
                        text-align: center;
                        display: block;
                        padding: 10px 0;
                    }
                    @media #{$queryPc} {
                        font-size: 1.6rem;
                        padding: 0 52px;
                    }
                    span {
                        padding: 0 10px;
                    }
                    &:hover {
                        span {
                            border-bottom: solid 1px #5d639e;
                            @media #{$querySp} {
                            }
                            @media #{$queryPc} {
                            }
                        }
                    }
                }
                
            }
            &.clearfix::after {
               content: "";
               display: block;
               clear: both;
            }
        }
    div {
        @media #{$querySp} {
            margin-bottom: 30px;
        }
        @media #{$queryPc} {
            margin-bottom: 50px;
        }
        h4 {
            font-weight: 600;
            background: #efefef;
            text-align: center;
            @media #{$querySp} {
                font-size: 1.8rem;
                line-height: 2.4;
                margin-bottom: 20px;
            }
            @media #{$queryPc} {
                font-size: 2rem;
                line-height: 3;
                margin-bottom: 30px;
            }
        }
        table {
            &.table {
                width: 100%;
                tr {
                    border-bottom: solid 1px #000;
                    th {
                        /*font-weight: 600;*/
                        @media #{$querySp} {
                            display: block;
                            width: 100%;
                            padding: 20px 0;
                        }
                        @media #{$queryPc} {
                            width: 40%;
                            padding: 25px 0;
                            font-size: 1.6rem;
                        }
                        span {
                            font-weight: 600;
                            @media #{$querySp} {
                            }
                            @media #{$queryPc} {
                                font-size: 1.6rem;
                            }
                        }
                    }
                    td {
                        /*font-weight: 600;*/
                        @media #{$querySp} {
                            display: block;
                            width: 100%;
                            padding: 0 0 20px;
                        }
                        @media #{$queryPc} {
                            width: 60%;
                            padding: 25px 0;
                            font-size: 1.6rem;
                        }
                        a {
                            color: #000;
                            &:hover {
                                opacity: 0.5;
                            }
                        }
                    }
                }
            }
        }
    }
}
.hub-jp--glogroup {
.btn {
            width: 100%;
	@media #{$querySp} {
        margin-bottom: 40px;
	}
	@media #{$queryPc} {
		display: flex;
		justify-content: space-between;
        margin: 0 0 30px !important;
	}
            li {
                
                 @media #{$querySp} {
                     width: 100%;
                     margin-bottom: 10px;
	    }
	        @media #{$queryPc} {
                width: 29%;
                margin: 0 10px;
            
	    }
            
            a {
            position: relative;
            font-size: 1.6rem;
            font-weight: 600;
            color: #000;
            
            margin: 0 auto;
            display: block;
            border: solid 1px #000;
            text-align: center;
            line-height: 2.6!important;
           
            &:after {
                display: none !important;
}
            
        }
                }
            
        }
}