@charset "UTF-8";
/*----------------------------------------------------------------
ページャーのコンポーネント
-----------------------------------------------------------------*/
.c-pager {
}

.c-pagerBody {
}

.c-pager__item {
}

.c-pager__item .prev {
}

.c-pager__item .next {
}

.c-pager__item .page-numbers {
}

.c-pager__item .current {
}

.c-pager__nav {
}

/*----------------------------------------------------------------
ページャーのコンポーネント
-----------------------------------------------------------------*/
.c-pagerBody {
	display: flex;
	justify-content: center;
	align-items: center;
	@media #{$querySp} {
		margin-top: 60px;
	}
	@media #{$queryPc} {
		margin-top: 120px;
	}
}

.c-pager__item {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
	&:first-child {
		position: relative;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
		&:before {
			position: absolute;
			top: 0;
			bottom: 0;
			content: "";
			width: 1px;
			height: 7px;
			background-color: #000;
			@media #{$querySp} {
				left: -10px;
				margin: 6px auto 0;
			}
			@media #{$queryPc} {
				left: -20px;
				margin: 10px auto 0;
			}
		}
	}
	&:last-child {
		position: relative;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
		&:before {
			position: absolute;
			top: 0;
			bottom: 0;
			content: "";
			width: 1px;
			height: 7px;
			background-color: #000;
			@media #{$querySp} {
				right: -10px;
				margin: 6px auto 0;
			}
			@media #{$queryPc} {
				right: -20px;
				margin: 10px auto 0;
			}
		}
	}
}

.c-pager__item .first {
	font-family: $fontEnRoboto;
	position: relative;
	display: block;
	color: #000;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		@include trans($duration: .2s,$delay: 0s)
	}
	&:before {
		display: inline-block;
		content: "";
		vertical-align: middle;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 3px 8px 3px 0;
		border-color: transparent #000 transparent transparent;
		@media #{$querySp} {
			margin: 0 10px 1px 0;
		}
		@media #{$queryPc} {
			margin: 0 20px 0 0;
			@include trans($duration: .2s,$delay: 0s)
		}
	}
	&:hover {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			color: $clrAccent;
		}
		&:before {
			@media #{$querySp} {
			}
			@media #{$queryPc} {
				border-color: transparent $clrAccent transparent transparent;
			}
		}
	}
}

.c-pager__item .back, .c-pager__item .next {
	display: block;
	text-align: center;
	color: rgba(0,0,0,.5);
	border: 1px solid #ddd;
	@media #{$querySp} {
		width: 80px;
		padding: 6px 0;
		font-size: 1.1rem;
	}
	@media #{$queryPc} {
		width: 200px;
		padding: 12px;
		@include trans($duration: .2s,$delay: 0s)
	}
	&:hover {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			border: 1px solid #000;
			background-color: #000;
			color: #fff;
		}
	}
}

.c-pager__item .back {
	@media #{$querySp} {
		margin-left: 10px;
	}
	@media #{$queryPc} {
		margin-left: 30px;
	}
}

.c-pager__item .next {
	@media #{$querySp} {
		margin-right: 10px;
	}
	@media #{$queryPc} {
		margin-right: 30px;
	}
}

.c-pager__item .page-numbers {
	font-weight: 600;
	@media #{$querySp} {
		margin: 0 5px;
		font-size: 1.1rem;
	}
	@media #{$queryPc} {
		margin: 0 30px;
		font-size: 1.6rem;
		letter-spacing: 0.1em;
	}
}

.c-pager__item .last {
	font-family: $fontEnRoboto;
	position: relative;
	display: block;
	color: #000;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		@include trans($duration: .2s,$delay: 0s)
	}
	&:after {
		display: inline-block;
		content: "";
		vertical-align: middle;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 3px 0 3px 8px;
		border-color: transparent transparent transparent #000;
		@media #{$querySp} {
			margin: 0 0 1px 10px;
		}
		@media #{$queryPc} {
			margin: 0 0 0 20px;
			@include trans($duration: .2s,$delay: 0s)
		}
	}
	&:hover {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			color: $clrAccent;
		}
		&:after {
			@media #{$querySp} {
			}
			@media #{$queryPc} {
				border-color: transparent transparent transparent $clrAccent;
			}
		}
	}
}

