@charset "UTF-8";
/* ----------------------------------------------------------------
お問い合わせのみのスタイル
-----------------------------------------------------------------*/
/* お問い合わせ
-----------------------------------------------------------------*/
.inquiry-flex {
    margin: 60px auto;
    @media #{$querySp} {
        width: 100%;
	}
	@media #{$queryPc} {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
	}
    div {
	@media #{$querySp} {
        width: 95%;
        margin: 30px auto 50px;
	}
	@media #{$queryPc} {
        width: 50%;
        margin: 30px 0;
	} 
    }
}

.tobtn {
    @media #{$querySp} {
        width: 95%;
        margin: 30px auto 50px;
	}
	@media #{$queryPc} {
        width: 50%;
        margin: 60px auto 100px;
	} 
}

.flow {
    @media #{$querySp} {
        width: 95%;
        margin: 30px auto 50px;
	}
	@media #{$queryPc} {
        width: 60%;
        margin: 40px auto 60px;
	} 
    ul {
        width: 100%;
        text-align: center;
        li {
            display: inline-block;
            position: relative;
            background: #ccc;
            text-align: center;
            line-height: 3;
            @media #{$querySp} {
                font-size: 1.2rem;
                width: 29%;
                margin: 0 3% 0 0;
            }
            @media #{$queryPc} {
                width: 30%;
                margin: 0 4% 0 0;
            }
            &.activ {
                color: #fff;
                background: #5d639e;
            }
            &:last-child {
                margin: 0;
            }
            &:after {
    position: absolute;
    top: 50%;
    width: 0.5em;
    height: 0.5em;
    transform: translateY(-50%) rotate(45deg);
    border-right: 2px solid #000;
    border-top: 2px solid #000;
    content: "";
                @media #{$querySp} {
                right: -0.7em;
            }
            @media #{$queryPc} {
                right: -1.2em;
            }
}
            
            &:last-child:after {
                display: none;
            }
        }
    }
}

/* お問い合わせ入力画面・確認画面
-----------------------------------------------------------------*/
.form-contents {
    @media #{$querySp} {
        width: 95%;
        margin: 30px auto;
    }
    @media #{$queryPc} {
        margin: 70px auto;
    }
    table {
        margin: 0 auto 40px;
        tr {
            border-bottom: solid 1px #ccc;
            &:last-child {
                border: none;
            }
            th {
                font-size: 1.6rem;
                vertical-align: middle;
                
                span {
                    font-size: 1.2rem;
                    font-weight: 600;
                    padding-left: 10px;
                }
                @media #{$querySp} {
                    width: 100%;
                    display: block;
                    padding: 10px 0;
                }
                @media #{$queryPc} {
                    width: 30%;
                    padding: 30px 20px;
                }
                &.vertical {
                    vertical-align: top;
                }
            }
            td {
                vertical-align: middle;
                font-size: 1.6rem;
                @media #{$querySp} {
                    width: 100%;
                    display: block;
                    padding: 0 0 10px;
                }
                @media #{$queryPc} {
                    width: 70%;
                    padding: 20px 0;
                }
            }
        }
        input {
            border: solid 1px #999;
            padding: 10px;
            width: 100%;
            &.zipcode {
                @media #{$querySp} {
                    width: 100%;
                    display: block;
                }
                @media #{$queryPc} {
                    width: 40%;
                }
            }
            &.number {
                @media #{$querySp} {
                    width: 100%;
                    display: block;
                }
                @media #{$queryPc} {
                    width: 40%;
                    display: block;
                }
            }
        }
        textarea {
            border: solid 1px #999;
            padding: 10px;
            width: 100%;
                @media #{$querySp} {
                    height: auto;
                }
                @media #{$queryPc} {
                    height: 300px;
                }
        }
        .select-wrap {
            position: relative;
        &:before {
    z-index: 1;
    position: absolute;
    right: 15px;
    top: 3px;
    content: "▼";
    line-height: 43px;
    color: #000;
    pointer-events: none;
            
}
            select {
            position: relative;
            border: solid 1px #999;
            padding: 10px;
            width: 100%;
            
        }
        }
    }
    
}

.section {
    h3 {
        margin-bottom: 10px !important;
    }
    ul {
        
        margin-bottom: 30px;
        @media #{$querySp} {
            margin-left: 20px;
        }
        @media #{$queryPc} {
            margin-left: 60px;
        }
        li {
            list-style: disc;
        }
    }
}











.p-inquiryPageLinkWrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @media #{$querySp} {
        margin-bottom: 20px;
    }
    @media #{$queryPc} {
        margin-bottom: 40px;
    }
}

.p-inquiryPageLinkItem {
    @media #{$querySp} {
        width: 50%;
        margin-bottom: 10px;
    }
    @media #{$queryPc} {
    }
    &:nth-child(odd) {
        @media #{$querySp} {
            padding-right: 5px;
        }
        @media #{$queryPc} {
        }
    }
    &:nth-child(even) {
        @media #{$querySp} {
            padding-left: 5px;
        }
        @media #{$queryPc} {
        }
    }
}

.p-inquiryPageLinkItem__link {
    display: block;
    color: #000;
    @media #{$querySp} {
        padding: 10px;
        background: url("../images/ico_arrow_b_blue.svg") no-repeat right 10px center rgba(79,88,156,.1);
        background-size: 10px auto;
    }
    @media #{$queryPc} {
        width: 154px;
        padding: 16px 20px;
        background: url("../images/ico_arrow_b_blue.svg") no-repeat right 20px center rgba(79,88,156,.1);
        background-size: 10px auto;
        @include trans($duration: .2s,$delay: 0s);
    }
    &:hover {
        @media #{$querySp} {
        }
        @media #{$queryPc} {
            background: url("../images/ico_arrow_b_wh.svg") no-repeat right 20px center rgba(79,88,156,1);
            background-size: 10px auto;
            color: #fff;
        }
    }
}

.p-inquiryPlistArea {
    border-top: 1px solid #000;
    @media #{$querySp} {
        padding: 20px 0;
    }
    @media #{$queryPc} {
        padding: 40px 0;
    }
    &:last-child {
        border-bottom: 1px solid #000;
        @media #{$querySp} {
            margin-bottom: 40px;
        }
        @media #{$queryPc} {
            margin-bottom: 80px;
        }
    }
}

.p-inquiryPlist__head {
    font-weight: 600;
    line-height: 1;
    @media #{$querySp} {
        margin-bottom: 20px;
        font-size: 2.2rem;
    }
    @media #{$queryPc} {
        margin-bottom: 40px;
        font-size: 3.0rem;
    }
}

.p-inquiryPlist__item {
    @media #{$querySp} {
    }
    @media #{$queryPc} {
    }
    input {
        display: none;
        @media #{$querySp} {
        }
        @media #{$queryPc} {
        }
    }
    label {
        display: inline-block;
        position: relative;
        cursor: pointer;
        border-radius: 2px;
        text-align: center;
        line-height: 1;
        @media #{$querySp} {
            padding: 10px 20px;
        }
        @media #{$queryPc} {
            padding: 16px 0 16px 30px;
        }
        &:before {
            position: absolute;
            content: "";
            top: 50%;
            left: 0;
            margin-top: -10px;
            border-radius: 50%;
            border: 1px solid #999;
            @media #{$querySp} {
                width: 16px;
                height: 16px;
            }
            @media #{$queryPc} {
                width: 20px;
                height: 20px;
            }
        }
    }
    input[type="radio"]:checked + :before {
        border: 1px solid #4F589C;
        @media #{$querySp} {
        }
        @media #{$queryPc} {
        }
    }
    input[type="radio"]:checked + label:after {
        position: absolute;
        content: "";
        top: 50%;
        border-radius: 50%;
        background: #4F589C;
        @media #{$querySp} {
            left: 4px;
            margin-top: -6px;
            width: 8px;
            height: 8px;
        }
        @media #{$queryPc} {
            left: 5px;
            margin-top: -5px;
            width: 10px;
            height: 10px;
        }
    }
}













































