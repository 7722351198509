@charset "UTF-8";
/* ----------------------------------------------------------------
CSRのみのスタイル
-----------------------------------------------------------------*/
/* CSRデータ
-----------------------------------------------------------------*/
.c-container--csr {
    @media #{$querySp} {
    }
    @media #{$queryPc} {
    }
    h3.def {
        font-weight: 600;
        @media #{$querySp} {
            font-size: 1.5em;
            margin-bottom: 30px;
        }
        @media #{$queryPc} {
            font-size: 1.5em;
            margin-bottom: 50px;
        }
    }
    .section--csr--report {
        @media #{$querySp} {
        }
        @media #{$queryPc} {
        }
        .wrap-img {
            @media #{$querySp} {
            }
            @media #{$queryPc} {
                width: 50%;
            }
            p {
                margin-bottom: 10px;
            }
            &.wrap-img--main {
                @media #{$querySp} {
                    width: 60%;
                    margin: auto;
            }
            }
        }
        .p-pdListDlWrap {
            @media #{$querySp} {
                margin-bottom: 20px;
            }
            @media #{$queryPc} {
                margin-bottom: 20px;
            }
        }
        .wrap-pdflist {
            @media #{$querySp} {
            }
            @media #{$queryPc} {
                width: 50%;
            }
            .pdf {
                li {
                    @media #{$querySp} {
                        margin-bottom: 5px;
                    }
                    @media #{$queryPc} {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
    .wrap-backnumber {
        .wrap-backnumber_pdf {
                display: -ms-flexbox;
                display: flex;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
            @media #{$querySp} {
                
            }
            @media #{$queryPc} {
                width: 100%;
            }
            a {
                display: block;
                @media #{$querySp} {
                    width: 48%;
                    margin: 0 1% 20px;
                }
                @media #{$queryPc} {
                    width: 18%;
                    margin-bottom: 20px;
                }
                .wrap-img {
                    img {
                        border: 1px solid #d3d3d3 !important;
                    }
                    margin-bottom: 5px;
                }
                img {
                    width: 100%;
                    height: auto;
                }
                p {
                    img {
                        width: 18px;
                        height: auto;
                    }
                }
                &:hover {
                    p {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    .cf {
            min-height: 1px;
        }
}

