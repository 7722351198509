@charset "UTF-8";
/* ----------------------------------------------------------------
footerのスタイル
-----------------------------------------------------------------*/
footer {
	width: 100%;
	margin-top: auto;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

.f-wrap {
	width: 100%;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

.f-menuWrap {
	@media #{$querySp} {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		padding-bottom: 53px;
		border-top: 1px solid #000;
	}
	@media #{$queryPc} {
		display: flex;
		justify-content: space-between;
		width: 1000px;
		margin: 0 auto;
		padding: 0 10px;
	}
}

.f-menuCol {
	@media #{$querySp} {
		width: 50%;
		border-bottom: 1px solid #000;
	}
	@media #{$queryPc} {
		width: 130px;
	}
	&:nth-child(odd) {
		@media #{$querySp} {
			border-right: 1px solid #000;
		}
		@media #{$queryPc} {
		}
	}
}

.f-menuBox {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		padding: 16px 0;
		line-height: 1.5;
	}
	&--last {
		@media #{$querySp} {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 50%;
			border-right: 1px solid #000;
		}
		@media #{$queryPc} {
		}
	}
	dt {
		font-weight: 600;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			margin-bottom: 16px;
		}
		a {
			color: #000;
			@media #{$querySp} {
				display: block;
				padding: 16px 32px 16px 40px;
				background: url("../images/ico_arrow_r_bk.svg") no-repeat right 14px center;
				-webkit-background-size: 6px auto;
				     -o-background-size: 6px auto;
				        background-size: 6px auto;
				font-size: 1.4rem;
			}
			@media #{$queryPc} {
				font-size: 1.6rem;
				@include trans($duration: .1s,$delay: 0s);
			}
			&:hover {
				@media #{$querySp} {
				}
				@media #{$queryPc} {
					@include opacity(.5);
				}
			}
		}
	}
	dd {
		@media #{$querySp} {
			display: none;
		}
		@media #{$queryPc} {
			margin-bottom: 12px;
		}
		a {
			@media #{$querySp} {
			}
			@media #{$queryPc} {
				font-size: 1.2rem;
				color: #000;
				@include trans($duration: .1s,$delay: 0s);
			}
			&:hover {
				@media #{$querySp} {
				}
				@media #{$queryPc} {
					@include opacity(.5);
				}
			}
		}
	}
}


/* コピーライト
______________________________________________*/
.f-copyWrap {
	width: 100%;
	background-color: $clrKey;
	@media #{$querySp} {
	}
	@media #{$queryPc} {

	}
}

.f-copyInner {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		width: 100%;
		min-width: 1000px;
		margin: 0 auto;
		padding: 24px 32px;
		display: flex;
		justify-content: flex-end;
	}
}

.f-logo {
	text-align: center;
	@media #{$querySp} {
		padding-top: 24px;
	}
	@media #{$queryPc} {
		margin-right: auto;
	}
	a {
		display: inline-block;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			@include trans($duration: .2s,$delay: 0s);
		}
		img {
			@media #{$querySp} {
				width: 131px;
				height: auto;
			}
			@media #{$queryPc} {
				width: 145px;
				height: auto;
			}
		}
		&:hover {
			@media #{$querySp} {
			}
			@media #{$queryPc} {
				@include opacity(.5);
			}
		}
	}
}

.f-copyMenu {
	@media #{$querySp} {
		padding-top: 24px;
		font-size: 1.4rem;
		font-weight: 600;
	}
	@media #{$queryPc} {
		display: inline-flex;
		margin-right: 30px;
	}
}

.f-copyMenuItem {
	@media #{$querySp} {
		margin-bottom: 24px;
		text-align: center;
	}
	@media #{$queryPc} {
		position: relative;
		margin-right: 24px;
	}
	&:after {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			content: "|";
			position: absolute;
			top: 0;
			bottom: 0;
			right: -12px;
			margin: auto 0;
			color: #fff;
			pointer-events:none;
		}
	}
	&:last-child {
		@media #{$querySp} {
			margin-bottom: 0;
		}
		@media #{$queryPc} {
			margin-right: 0;
		}
		&:after {
			@media #{$querySp} {
			}
			@media #{$queryPc} {
				display: none;
			}
		}
	}
}

.f-copyMenuItem__link {
	color: #fff;
	@media #{$querySp} {
		padding: 24px 0;
	}
	@media #{$queryPc} {
		font-size: 1.2rem;
		@include trans($duration: .1s,$delay: 0s);
	}
	&:hover {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			@include opacity(.5);
		}
	}
}

.f-copy {
	text-align: center;
	color: #fff;
	@media #{$querySp} {
		padding: 24px 0;
	}
	@media #{$queryPc} {
		font-size: 1.2rem;
		line-height: 2.1;
	}
}
















