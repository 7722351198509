@charset "UTF-8";
/* ----------------------------------------------------------------
下層ページ共通のみのスタイル
-----------------------------------------------------------------*/
/* ヘッダー固定調整
-----------------------------------------------------------------*/
main.pages {
    @media #{$querySp} {
        padding-top: 70px;
	}
	@media #{$queryPc} {
	}
}

/* KV背景無しの場合の見出し
-----------------------------------------------------------------*/
.c-container {
    .kv {
        @media #{$querySp} {
        margin-bottom: 20px;
	}
	@media #{$queryPc} {
        margin-bottom: 40px;
	    }
        h2 {
            font-weight: 600;
        line-height: 2;
            text-align: center;
	@media #{$querySp} {
        font-size: 2.06rem;
	}
	@media #{$queryPc} {
        font-size: 3.0rem;

	    }
        }
    }
}
/* 見出しパターン
-----------------------------------------------------------------*/
.h-Headline {
    text-align: center;
    line-height: 1;
    @media #{$querySp} {
        margin-bottom: 32px;
	}
	@media #{$queryPc} {
        margin-bottom: 56px;
    }
    .en {
        font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif; 
		display: inline-block;
		letter-spacing: .2em;
		@media #{$querySp} {
			margin-bottom: 8px;
			font-size: 3.0rem;
		}
		@media #{$queryPc} {
			margin-bottom: 10px;
			font-size: 4rem;
		}
	}
	.jp {
		display: inline-block;
		letter-spacing: .15em;
		@media #{$querySp} {
			font-size: 1.0rem;
		}
		@media #{$queryPc} {
			font-size: 1.2rem;
		}
	}
    .nml {
		font-weight: 600;
		@media #{$querySp} {
			font-size: 3rem;
		}
		@media #{$queryPc} {
			font-size: 4rem;
            letter-spacing: .15em;
		}
	}
}
.h-footer {
    font-weight: 600;
    text-align: center;
    @media #{$querySp} {
        font-size: 1.6rem;
        margin-bottom: 47px;
    }
    @media #{$queryPc} {
        font-size: 2.4rem;
        margin-bottom: 50px;
    }
}
h3.left-ruled-line {
            font-size: 2rem;
            font-weight: 600;
            position: relative;
            display: inline-block;
            margin-bottom: 30px;
            padding: 0 0 0 40px;
        @media #{$querySp} {
            font-size: 1.8rem;
            margin-bottom: 20px;
	    }
	    @media #{$queryPc} {
            
	    }
            &:before {
  content: '';
  position: absolute;
  top: 42%;
  display: inline-block;
  width: 27px;
  height: 4px;
  background: #5d639e;
}

&:before {
  left:0;
}
        }
h3.bgcolorgray {
    background: #efefef;
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    line-height: 3;
}
/* ボタン装飾
-----------------------------------------------------------------*/
p.btn a {
            position: relative;
            font-size: 1.6rem;
            font-weight: 600;
            color: #000;
            width: 70%;
            margin: 0 auto;
            display: block;
            border: solid 1px #000;
            text-align: center;
            line-height: 4;
            @media #{$querySp} {
                width: 100%;
	    }
	        @media #{$queryPc} {
                @include trans($duration: .2s,$delay: 0s);
	    }
            &:after {
    position: absolute;
    top: 50%;
    right: 2em;
    width: 0.5em;
    height: 0.5em;
    transform: translateY(-50%) rotate(45deg);
    border-right: 2px solid #000;
    border-top: 2px solid #000;
    content: "";
}
            &:hover {
            /*opacity: 0.6;*/
            background-color: $clrKey;
            color: #fff;
            &:after {
                border-right: 2px solid #fff;
                border-top: 2px solid #fff;
            }
            }
        }

.move{
    position: relative;
            font-size: 1.8rem;
            font-weight: 600;
            color: #000;
            width: 30%;
            margin: 0 auto;
            display: block;
            border: solid 1px #000;
            text-align: center;
            line-height: 4;
            @media #{$querySp} {
                width: 100%;
	    }
	        @media #{$queryPc} {
            
	    }
            &:after {
    position: absolute;
    top: 50%;
    right: 2em;
    width: 0.5em;
    height: 0.5em;
    transform: translateY(-50%) rotate(45deg);
    border-right: 2px solid #000;
    border-top: 2px solid #000;
    content: "";
}
            &:hover {
            background-color: $clrKey;
                color: #fff;
                &:after {
                    border-right: 2px solid #fff;
                    border-top: 2px solid #fff;
        }
        }
    
}
.confirm {
    text-align: center;
li{
    display: inline-block;
    position: relative;
            font-size: 1.8rem;
            font-weight: 600;
            color: #000;
            width: 30%;
            
            border: solid 1px #000;
            text-align: center;
            line-height: 4;
            @media #{$querySp} {
                width: 100%;
	    }
	        @media #{$queryPc} {
            margin: 0 20px;
	    }
    &:first-child {
        @media #{$querySp} {
                width: 80%;
            margin: 0 auto 20px;
	    }
	        @media #{$queryPc} {
           /* width: 25%;*/
	    }
        
    }
            &:after {
    position: absolute;
    top: 50%;
    right: 1em;
    width: 0.5em;
    height: 0.5em;
    transform: translateY(-50%) rotate(45deg);
    border-right: 2px solid #000;
    border-top: 2px solid #000;
    content: "";
}
    &:first-child:before {
    position: absolute;
    top: 50%;
    left: 1em;
    width: 0.5em;
    height: 0.5em;
    transform: translateY(-50%) rotate(-45deg);
    border-left: 2px solid #000;
    border-top: 2px solid #000;
    content: "";
}
    &:first-child:after {
    display: none;
}
            &:hover {
            background-color: $clrKey;
                color: #fff;
                &:after {
                    border-right: 2px solid #fff;
                    border-top: 2px solid #fff;
        }
                &:before {
                    border-left: 2px solid #fff;
                    border-top: 2px solid #fff;
        }
        }
}
}

.news-wrap {
    @media #{$querySp} {
        width: 95%;
        margin: 0 auto 40px;
    }
    h3{
        &.left-ruled-line {
            @media #{$querySp} {
            width: 100%;
        }
        &:before {
        @media #{$querySp} {
            top: 42%;
        }
            }
        
    }
        }
.news-btn {
    a {
        color: #000;
        position: relative;
        font-weight: 600;
        color: #000;
        border: solid 1px #000;
        text-align: center;
        line-height: 2.8;
            @media #{$querySp} {
                font-size: 1.2rem;
                display: block;
                width: 50%;
                position: absolute;
                right: 0;
                margin: 10px 0 0;
	    }
	        @media #{$queryPc} {
                font-size: 1.4rem;
                display: inline-block;
                width: 150px;
                margin-left: 30px;
	    }
        &:after {
    position: absolute;
    top: 50%;
    right: 1em;
    width: 0.4em;
    height: 0.4em;
    transform: translateY(-50%) rotate(45deg);
    border-right: 1px solid #000;
    border-top: 1px solid #000;
    content: "";
}
        &:hover {
            background-color: $clrKey;
                color: #fff;
            &:after {
                border-right: 1px solid #fff;
    border-top: 1px solid #fff;
            }
        }
    }
   
}
}
/* フォーム関連
-----------------------------------------------------------------*/
@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);
.checkbox {
        text-align: center;
            @media #{$querySp} {
                margin: 50px 0;
            }
            @media #{$queryPc} {
                margin: 50px 0;
            }
        label {font-size: 1.6rem;}
    input[type=checkbox] { display:none; } /* to hide the checkbox itself */
input[type=checkbox] + label:before {
  font-family: FontAwesome;
  display: inline-block;
    vertical-align: middle;
}

input[type=checkbox] + label:before { content: "\f096";color: #000;font-size: 24px;font-weight:900; } /* unchecked icon */
input[type=checkbox] + label:before { letter-spacing: 10px; } /* space between checkbox and label */
    input[type=checkbox]:checked + label:before { content: "\f046"; } /* checked icon */
input[type=checkbox]:checked + label:before { letter-spacing: 7px; } /* allow space for check mark */
    }

.error-message {
  position: relative;
  display: inline-block;
  margin: 10px 0 5px;
  padding: 5px 20px;
  color: #fff;
  /*font-size: 1.6rem;*/
  background: #ff6666;
    &:before {
    content: "";
    position: absolute;
    top: -15px;
    left: 10%;
    margin-left: -10px;
    border: 10px solid transparent;
    border-bottom: 10px solid #ff6666;
}
    p {
  margin: 0;
  padding: 0;
}
}


/* 横並び
-----------------------------------------------------------------*/
.wrap {
	width: 100%;
	@media #{$querySp} {
        margin-bottom: 40px;
	}
	@media #{$queryPc} {
		display: flex;
		justify-content: space-between;
        margin: 0 0 50px;
	}
}
/* テキスト位置
-----------------------------------------------------------------*/
.c-txt {
	text-align: center;
}
.r-txt {
	text-align: right;
}
.l-txt {
	text-align: left;
}

/* テキスト色
-----------------------------------------------------------------*/
.red {
    color: #f00;
}
/* 画像リンクマウスオーバー
-----------------------------------------------------------------*/
a:hover {
    img {
        opacity: 0.7;
    }
}

/* コンテンツ幅
-----------------------------------------------------------------*/
.c-inner--l {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		max-width: 1280px;
		min-width: $containerWrap;
	}
}



/* テーブルレイアウト
-----------------------------------------------------------------*/
.table_layout {
    @media #{$querySp} {
        margin: 0 auto 70px;
    }
    @media #{$queryPc} {
        margin-bottom: 140px;
    }
    tr {
        border-bottom: solid 1px #000000;
        &:last-child {
            @media #{$querySp} {
            }
            @media #{$queryPc} {
                border-bottom: none;
            }
        }
        th {
            /*font-size: 1.6rem;*/
            font-weight: 600;
            vertical-align: top;
            @media #{$querySp} {
                display: block;
                width: 100%;
                padding: 25px 10px 20px;
            }
            @media #{$queryPc} {
                padding: 40px 10px 20px 60px;
            }
            }
        td {
            vertical-align: top;
            @media #{$querySp} {
                /*font-size: 1.4rem;*/
                display: block;
                width: 100%;
                padding: 0 10px 25px;
            }
            @media #{$queryPc} {
                /*font-size: 1.6rem;*/
                /*font-weight: 600;*/
                padding: 40px 0 20px;
            }
            p {
                margin-bottom: 40px;
            }
        }
    }
}


.c-txt {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}





















