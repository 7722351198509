@charset "UTF-8";
/* ----------------------------------------------------------------
テキストのコンポーネット
-----------------------------------------------------------------*/
.c-text {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

.c-text__s {
	@media #{$querySp} {
		font-size: 1.1rem;
	}
	@media #{$queryPc} {
		font-size: 1.4rem;
	}
}

.c-text__l {
	@media #{$querySp} {
		font-size: 1.4rem;
	}
	@media #{$queryPc} {
		font-size: 1.8rem;
	}
}

.c-textLink {
	display: inline-block;
	color: #ff5611;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		@include trans($duration: .1s,$delay: 0s);
	}
	&:hover {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			color: $clrKey;
		}
	}
	&--pdf {
		position: relative;
		@media #{$querySp} {
			margin-right: 4px;
			padding-right: 20px;
		}
		@media #{$queryPc} {
			margin-right: 8px;
			padding-right: 24px;
		}
		&:before {
			content: "";
			display: inline-block;
			position: absolute;
			right: 0 !important;
			@media #{$querySp} {
				bottom: 1px;
				width: 16px;
			    height: 20px;
			    background: url("../images/ico_pdf.png") no-repeat center top;
			    background-size: 16px auto;
			}
			@media #{$queryPc} {
				bottom: 2px;
			    width: 18px;
			    height: 22px;
			    background: url("../images/ico_pdf.png") no-repeat center top;
			    background-size: 18px auto;
			    @include trans($duration: .2s,$delay: 0s);
			}
		}
	}
	&--blank {
		position: relative;
		@media #{$querySp} {
			margin-right: 4px;
			padding-right: 20px;
		}
		@media #{$queryPc} {
			margin-right: 8px;
			padding-right: 24px;
		}
		&:before {
			content: "";
			display: inline-block;
			position: absolute;
			right: 0 !important;
			@media #{$querySp} {
				bottom: 3px;
				width: 14px;
			    height: 14px;
			    background: url("../images/ico_blank.svg") no-repeat center top;
			    background-size: 14px auto;
			}
			@media #{$queryPc} {
				bottom: 2px;
			    width: 18px;
			    height: 22px;
			    background: url("../images/ico_blank.svg") no-repeat center top;
			    background-size: 18px auto;
			    @include trans($duration: .2s,$delay: 0s);
			}
		}
	}
}
