@charset "UTF-8";
/*----------------------------------------------------------------
見出しのコンポーネント
-----------------------------------------------------------------*/
.c-headline01,
.c-headline02,
.c-headline03,
.c-headline04,
.c-headline05,
.c-headline06 {
	small {
		display: block;
		font-size: $fsSmall + rem;
	}
}

.c-headline01 {
	font-size: $fsL1 + rem;
	&:first-child {margin-top: 0;}
	&:last-child {margin-bottom: 0;}
}

.c-headline02 {
	font-size: $fsL2 + rem;
	&:first-child {margin-top: 0;}
	&:last-child {margin-top: 0;}
}

.c-headline03 {
	font-size: $fsL3 + rem;
	&:first-child {margin-top: 0;}
	&:last-child {margin-top: 0;}
}

.c-headline04 {
	font-size: $fsL4 + rem;
	&:first-child {margin-top: 0;}
	&:last-child {margin-top: 0;}
}

.c-headline05 {
	font-size: $fsL5 + rem;
	&:first-child {margin-top: 0;}
	&:last-child {margin-top: 0;}
}

.c-headline06 {
	font-size: $fsL6 + rem;
	&:first-child {margin-top: 0;}
	&:last-child {margin-top: 0;}
}

/*----------------------------------------------------------------
見出しのコンポーネント
-----------------------------------------------------------------*/
.c-headline01,
.c-headline02,
.c-headline03,
.c-headline04,
.c-headline05,
.c-headline06 {
	color: $clrBlck;
	small {
	}
}

.c-headline01 {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

.c-headline02 {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

.c-headline03 {
	font-weight: 600;
	color: #000;
	@media #{$querySp} {
		margin-bottom: 24px;
		font-size: 2.8rem;
	}
	@media #{$queryPc} {
		margin-bottom: 56px;
		font-size: 3.4rem;
	}
}

.c-headline04 {
	font-weight: 600;
	color: #000;
	@media #{$querySp} {
		margin-bottom: 16px;
		font-size: 1.8rem;
	}
	@media #{$queryPc} {
		margin-bottom: 32px;
		font-size: 2.4rem;
	}
	&--lLine {
		position: relative;
		@media #{$querySp} {
			padding: 20px;
		}
		@media #{$queryPc} {
			padding-left: 40px;
		}
		&:before {
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			margin: auto 0;
			background-color: #5d639e;
			@media #{$querySp} {
				width: 13px;
				height: 2px;
			}
			@media #{$queryPc} {
				width: 27px;
				height: 4px;
			}
		}
	}
	&--bgGray {
		text-align: center;
		background-color: #efefef;
		@media #{$querySp} {
			padding: 4px;
		}
		@media #{$queryPc} {
			padding: 8px;
		}
	}
}

.c-headline05 {
	font-weight: 600;
	@media #{$querySp} {
		margin-bottom: 8px;
		font-size: 1.6rem;
	}
	@media #{$queryPc} {
		margin-bottom: 16px;
		font-size: 1.8rem;
	}
}

.c-headline06 {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}






