@charset "UTF-8";
/*----------------------------------------------------------------
レイアウトのコンポーネント
-----------------------------------------------------------------*/
.c-wrap {
	box-sizing: border-box;
	width: 100%;
	@media #{$queryPcTab} {
		min-width: $containerWidthSmall;
	}
}

.c-mainInner {
	@media #{$querySp} {
		padding-top: 70px;
	}
	@media #{$queryPc} {
		padding-top: 120px;
	}
}

.c-container {
	width: 100%;
}

.c-inner {
	width: 100%;
	margin: 0 auto;
	box-sizing: boder-box;
}

/* ----------------------------------------------------------------
layoutのスタイル
-----------------------------------------------------------------*/
.c-wrap {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	overflow: hidden;
}

.c-container {
	@media #{$querySp} {
		padding: 60px 0 80px;
	}
	@media #{$queryPc} {
		padding: 60px 0 160px;
	}
}

.c-inner {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

//modifier
.c-inner--sm {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

.c-inner--md {
	@media #{$querySp} {
		padding: 0 15px;
	}
	@media #{$queryPc} {
		max-width: 1000px;
		min-width: 1000px;
		padding: 0 10px;
	}
}

.c-inner--lg {
	@media #{$querySp} {
		padding: 0 15px;
	}
	@media #{$queryPc} {
		max-width: 1310px;
		min-width: $containerWrap;
		padding: 0 40px;
	}
}

.c-inner--max {
	width: 100%;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		min-width: $containerWrap;
	}
}