@charset "UTF-8";
/* ----------------------------------------------------------------
社長メッセージのみのスタイル
-----------------------------------------------------------------*/
.c-container--greeting {
    @media #{$querySp} {
        width: 95%;
        margin: auto;
	}
	@media #{$queryPc} {
	}
    .photo_greeting {
        background: #efefef;
        text-align: center;
        @media #{$querySp} {
            margin-bottom: 39px;
        }
        @media #{$queryPc} {
            margin-bottom: 78px;
        }
        img {
            @media #{$querySp} {
                width: 100%;
                height: auto;
            }
            @media #{$queryPc} {
            }
        }
    }
    .p-leed {
        font-weight: 600;
        letter-spacing: .11em;
        @media #{$querySp} {
            font-size: 1.6rem;
            margin-bottom: 20px;
            line-height: 24px;
        }
        @media #{$queryPc} {
            font-size: 2rem;
            margin-bottom: 20px;
            line-height: 36px;
        }
    }
    p {
        letter-spacing: .07em;
        @media #{$querySp} {
            /*font-size: 1.4rem;*/
            margin-bottom: 15px;
            line-height: 1.8;
        }
        @media #{$queryPc} {
            /*font-size: 1.6rem;*/
            margin-bottom: 15px;
            line-height: 1.8;
        }
        &.u-aRight {
            @media #{$querySp} {
                margin-bottom: 100px;
            }
            @media #{$queryPc} {
                margin-bottom: 170px;
            }
            span {
                display: inline-block;
                text-align: left;
                line-height: 1.3;
                vertical-align: middle;
                @media #{$querySp} {
                    font-size: 1.2rem;
                    margin-right: 20px;
                }
                @media #{$queryPc} {
                    font-size: 1.8rem;
                    margin-right: 30px;
                }
            }
            img {
                    @media #{$querySp} {
                        width: 50%;
                        height: auto;
                }
                    @media #{$queryPc} {}
                }
        }
    }
}