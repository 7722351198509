@charset "UTF-8";
/* ----------------------------------------------------------------
コーポレート・ガバナンスのみのスタイル
-----------------------------------------------------------------*/
.c-inner--history {
	@media #{$querySp} {
        margin-bottom: 50px;
	}
	@media #{$queryPc} {
        margin-bottom: 100px;
	}
    .lead {
        font-weight: 600;
        @media #{$querySp} {
            margin-bottom: 40px;
        }
        @media #{$queryPc} {
            font-size: 1.6rem;
            margin-bottom: 100px;
        }
    }
    .c-history {
        @media #{$querySp} {
        }
        @media #{$queryPc} {
        }
        .wrap--history {
            @media #{$querySp} {
            }
            @media #{$queryPc} {
                margin-bottom: 70px;
            }
            .wrap--history--order {
                @media #{$querySp} {
                    background: url("/images/company/history/bg.png") repeat-x left bottom;
                    width: 100%;
                    margin-bottom: 20px;
                    padding-bottom: 10px;
                }
                @media #{$queryPc} {
                    width: 100px;
                    background: url("/images/company/history/bg.png") repeat-y right top;
                }
                span {
                    color: #5D639F;
                    font-weight: 600;
                    @media #{$querySp} {
                        /*font-size: 1.6rem;*/
                    }
                    @media #{$queryPc} {
                        font-size: 1.6rem;
                        display: block;
                        text-align: center;
                    }
                    &.brd-x {
                        background: #5D639F;
                        @media #{$querySp} {
                            width: 70px;
                            height: 1px;
                            margin: auto 10px;
                            padding: 0;
                            display: inline-block;
                            vertical-align: middle;
                        }
                        @media #{$queryPc} {
                            width: 1px;
                            height: 70px;
                            margin: 10px auto;
                        }
                    }
                }
            }
            .wrap--history--contents {
                @media #{$querySp} {
                }
                @media #{$queryPc} {
                    width: 610px;
                    margin: 0 auto;
                }
                h3 {
                    color: #5D639F;
                    font-weight: 600;
                    @media #{$querySp} {
                        font-size: 2rem;
                        margin-bottom: 20px;
                    }
                    @media #{$queryPc} {
                        font-size: 2.4rem;
                        margin-bottom: 30px;
                    }
                    span {
                        display: block;
                        @media #{$querySp} {
                        font-size: 1.2rem;
                        }
                        @media #{$queryPc} {
                        font-size: 1.6rem;
                        }
                    }
                }
                .table {
                    width: 100%;
                    @media #{$querySp} {
                    }
                    @media #{$queryPc} {
                    }
                    tr {
                        border-top: solid 1px #999999;
                        @media #{$querySp} {
                        }
                        @media #{$queryPc} {
                        }
                        th {
                            font-weight: 600;
                            font-size: 1.6rem;
                            @media #{$querySp} {
                            }
                            @media #{$queryPc} {
                            }
                        }
                        td {
                            /*font-weight: 600;*/
                            /*font-size: 1.6rem;*/
                            @media #{$querySp} {
                                display: block;
                                width: 100%;
                                padding: 5px 0 15px;
                            }
                            @media #{$queryPc} {
                                padding: 20px 0;
                            }
                            h4 {
                                font-weight: 600;
                                @media #{$querySp} {
                                }
                                @media #{$queryPc} {
                                    font-size: 1.6rem;
                                }
                            }
                            &:nth-of-type(1){
                                @media #{$querySp} {
                                    padding: 15px 0 0;
                                }
                                @media #{$queryPc} {
                                    padding: 20px 35px 20px 5px;
                                    width: 130px;
                                    text-align: right;
                                    font-size: 1.6rem;
                                }
                            }
                        }
                    }
                }
            }
            .wrap--history--photo {
                @media #{$querySp} {
                }
                @media #{$queryPc} {
                    width: 220px;
                }
                ul {
                    @media #{$querySp} {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-pack: justify;
                        -ms-flex-pack: justify;
                        justify-content: space-between;
                        flex-wrap: wrap;
                    }
                    @media #{$queryPc} {
                    }
                    li {
                        @media #{$querySp} {
                            width: 48%;
                            margin: 0 0 15px;
                            flex-basis: auto;
                        }
                        @media #{$queryPc} {
                            margin-bottom: 20px;
                        }
                        &:nth-child(2n) {
                            @media #{$querySp} {
                            }
                        }
                        &:last-child {
                            @media #{$querySp} {
                                margin: 0;
                            }
                        }
                        span {
                            display: block;
                            /*font-weight: 600;*/
                            text-align: center;
                            margin: 5px 0 0;
                            @media #{$querySp} {
                            }
                            @media #{$queryPc} {
                                font-size: 1.6rem;
                            }
                        }
                        img {
                            width: 100%;
                            height: auto;
                            @media #{$querySp} {
                            }
                            @media #{$queryPc} {
                            }
                        }
                    }
                }
            }
            &.wrap--history--nophoto {
                .wrap--history--contents {
                    @media #{$querySp} {
                    }
                    @media #{$queryPc} {
                        width: 855px;
                        margin: 0 0 0 25px;
                    }
                }
            }
        }
    }
    &.clearfix::after {
               content: "";
               display: block;
               clear: both;
            }
}
.c-inner--lg {
    clear: both;
}