@charset "UTF-8";
/* ----------------------------------------------------------------
utility
-----------------------------------------------------------------*/
/* clearfix
---------------------------------------------------- */
.u-clearfix:after {
    content: ".";
    display: block;
    clear: both;
    height: 0;
    visibility: hidden;
}

.u-clearfix {
    min-height: 0.1px;
}

* html .u-clearfix {
  height: 1px;
  /*¥*//*/
  height: auto;
  overflow: hidden;
  /**/
}

/* align
______________________________________________*/
.u-aLeft { text-align: left !important; }
.u-aCenter { text-align: center !important; }
.u-aRight { text-align: right !important; }
@media #{$queryPc} {
	.u-aLeft--pc { text-align: left !important; }
	.u-aCenter--pc { text-align: center !important; }
	.u-aRight--pc { text-align: right !important; }
}
@media #{$querySp} {
	.u-aLeft--sp { text-align: left !important; }
	.u-aCenter--sp { text-align: center !important; }
	.u-aRight--sp { text-align: right !important; }
}

/* float
______________________________________________*/
.u-fRight {
	float: right;
}
.u-fLeft {
	float: left;
}

/* hidden
______________________________________________*/
.u-hidden {
	display: none;
}

.u-hidden--sp {
	@media #{$querySp} {
		display: none;
	}
}

.u-hidden--pc {
	@media #{$queryPc} {
		display: none;
	}
}

/* img
______________________________________________*/
.u-imgfull {
	width: 100%;
	height: auto;
}

.u-imgfull--sp {
	@media #{$querySp} {
		width: 100%;
		height: auto;
	}
}

/* opacity
______________________________________________*/
.u-hoverOpacity--pc {
	@media #{$queryPc} {
		@include trans($duration: .2s,$delay: 0s)
	}
	&:hover {
		@media #{$queryPc} {
			@include opacity(0.5)
		}
	}
}

/* indent
______________________________________________*/
.u-indent {
	text-indent: 1em;
}

.u-indent_att {
	text-indent:-1em;
	padding-left:1em;
}

/* font Weight
______________________________________________*/
.u-fwNml {
	font-weight: normal!important;
}
.u-fwBold {
	font-weight: bold!important;
}

/* color
______________________________________________*/
.u-clrWht {
	color: $clrWht !important;
}
.u-clrBlck {
	color: $clrBlck !important;
}
.u-clrGry {
	color: $clrGry !important;
}
.u-clrBase {
	color: $clrBase!important;
}
.u-clrMain {
	color: $clrMain!important;
}
.u-clrAccent {
	color: $clrAccent!important;
}
.u-clrPrimary {
	color: $clrPrimary!important;
}
.u-clrSuccess {
	color: $clrSuccess!important;
}
.u-clrInfo {
	color: $clrInfo!important;
}
.u-clrDanger {
	color: $clrDanger!important;
}
.u-clrWarning {
	color: $clrWarning!important;
}
.u-clrPjc01 {
	color: $clrProject01!important;
}
.u-clrPjc02 {
	color: $clrProject02!important;
}
.u-clrPjc03 {
	color: $clrProject03!important;
}
.u-clrPjc04 {
	color: $clrProject04!important;
}
.u-clrPjc05 {
	color: $clrProject05!important;
}

/* background
______________________________________________*/
.u-bgClrBlck {
	background: $clrBlck!important;
}
.u-bgClrWht {
	background: $clrWht!important;
}
.u-bgClrGry {
	background: $clrGry!important;
}
.u-bgClrBase {
	background: $clrBase!important;
}
.u-bgClrMain {
	background: $clrMain!important;
}
.u-bgClrAccent {
	background: $clrAccent!important;
}
.u-bgClrPrimary {
	background: $clrPrimary!important;
}
.u-bgClrSuccess {
	background: $clrSuccess!important;
}
.u-bgClrInfo {
	background: $clrInfo!important;
}
.u-bgClrDanger {
	background: $clrDanger!important;
}
.u-bgClrWarning {
	background: $clrWarning!important;
}
.u-bgClrPjc01 {
	background: $clrProject01!important;
}
.u-bgClrPjc02 {
	background: $clrProject02!important;
}
.u-bgClrPjc03 {
	background: $clrProject03!important;
}
.u-bgClrPjc04 {
	background: $clrProject04!important;
}
.u-bgClrPjc05 {
	background: $clrProject05!important;
}

/* fontsize
______________________________________________*/
$fontStride: 100;

@while $fontStride > -1 {
	.u-fs#{$fontStride} {
		font-size: #{$fontStride}px !important;
	}
	@media #{$queryPc} {
		.u-fs#{$fontStride}--pc {
			font-size: #{$fontStride}px !important;
		}
	}
	@media #{$querySp} {
		.u-fs#{$fontStride}--sp {
			font-size: #{$fontStride}px !important;
		}
	}
	$fontStride: $fontStride - 1;
}

/* linehight
______________________________________________*/
$lhStride: 100;

@while $lhStride > -1 {
	.u-lh#{$lhStride} {
		line-height: #{$lhStride}px !important;
	}
	@media #{$queryPc} {
		.u-lh#{$lhStride}--pc {
			line-height: #{$lhStride}px !important;
		}
	}
	@media #{$querySp} {
		.u-lh#{$lhStride}--sp {
			line-height: #{$lhStride}px !important;
		}
	}
	$lhStride: $lhStride - 1;
}

/* width
______________________________________________*/
$widthStride: 100;
@while $widthStride > -1 {
	.u-w#{$widthStride} {
		width: $widthStride * 1% !important;
	}
	$widthStride: $widthStride - 1;
}

$widthStridePx: 1000;
@while $widthStridePx > -1 {
	.u-w#{$widthStridePx}Px {
		width: #{$widthStridePx + px} !important;
	}
	@media #{$queryPc} {
		.u-w#{$widthStridePx}Px--pc {
			width: #{$widthStridePx + px} !important;
		}
	}
	@media #{$querySp} {
		.u-w#{$widthStridePx}Px--sp {
			width: #{$widthStridePx + px} !important;
		}
	}
	$widthStridePx: $widthStridePx - 1;
}

$widthStridePer: 100;
@while $widthStridePer > -1 {
	.u-w#{$widthStridePer}Per {
		width: #{$widthStridePer}% !important;
	}
	@media #{$queryPc} {
		.u-w#{$widthStridePer}Per--pc {
			width: #{$widthStridePer}% !important;
		}
	}
	@media #{$querySp} {
		.u-w#{$widthStridePer}Per--sp {
			width: #{$widthStridePer}% !important;
		}
	}
	$widthStridePer: $widthStridePer - 1;
}

/* margin
______________________________________________*/
$marginStride: 100;

@while $marginStride > -1 {
	.u-mt#{$marginStride} {
		margin-top: #{$marginStride}px !important;
	}
	.u-mb#{$marginStride} {
		margin-bottom: #{$marginStride}px !important;
	}
	.u-mr#{$marginStride} {
		margin-right: #{$marginStride}px !important;
	}
	.u-ml#{$marginStride} {
		margin-left: #{$marginStride}px !important;
	}
	.u-m#{$marginStride} {
		margin: #{$marginStride}px !important;
	}
	@media #{$queryPc} {
		.u-mt#{$marginStride}--pc {
			margin-top: #{$marginStride}px !important;
		}
		.u-mb#{$marginStride}--pc {
			margin-bottom: #{$marginStride}px !important;
		}
		.u-mr#{$marginStride}--pc {
			margin-right: #{$marginStride}px !important;
		}
		.u-ml#{$marginStride}--pc {
			margin-left: #{$marginStride}px !important;
		}
		.u-m#{$marginStride}--pc {
			margin: #{$marginStride}px !important;
		}
	}
	@media #{$querySp} {
		.u-mt#{$marginStride}--sp {
			margin-top: #{$marginStride}px !important;
		}
		.u-mb#{$marginStride}--sp {
			margin-bottom: #{$marginStride}px !important;
		}
		.u-mr#{$marginStride}--sp {
			margin-right: #{$marginStride}px !important;
		}
		.u-ml#{$marginStride}--sp {
			margin-left: #{$marginStride}px !important;
		}
		.u-m#{$marginStride}--sp {
			margin: #{$marginStride}px !important;
		}
	}
	$marginStride: $marginStride - 1;
}

/* Padding
______________________________________________*/
$paddingStride: 100;
@while $paddingStride > -1 {
	.u-pt#{$paddingStride} {
		padding-top: #{$paddingStride}px !important;
	}
	.u-pb#{$paddingStride} {
		padding-bottom: #{$paddingStride}px !important;
	}
	.u-pr#{$paddingStride} {
		padding-right: #{$paddingStride}px !important;
	}
	.u-pl#{$paddingStride} {
		padding-left: #{$paddingStride}px !important;
	}
	.u-p#{$paddingStride} {
		padding: #{$paddingStride}px !important;
	}
	@media #{$queryPc} {
		.u-pt#{$paddingStride}--pc {
			padding-top: #{$paddingStride}px !important;
		}
		.u-pb#{$paddingStride}--pc {
			padding-bottom: #{$paddingStride}px !important;
		}
		.u-pr#{$paddingStride}--pc {
			padding-right: #{$paddingStride}px !important;
		}
		.u-pl#{$paddingStride}--pc {
			padding-left: #{$paddingStride}px !important;
		}
		.u-p#{$paddingStride}--pc {
			padding: #{$paddingStride}px !important;
		}
	}
	@media #{$querySp} {
		.u-pt#{$paddingStride}--sp {
			padding-top: #{$paddingStride}px !important;
		}
		.u-pb#{$paddingStride}--sp {
			padding-bottom: #{$paddingStride}px !important;
		}
		.u-pr#{$paddingStride}--sp {
			padding-right: #{$paddingStride}px !important;
		}
		.u-pl#{$paddingStride}--sp {
			padding-left: #{$paddingStride}px !important;
		}
		.u-p#{$paddingStride}--sp {
			padding: #{$paddingStride}px !important;
		}
	}
	$paddingStride: $paddingStride - 1;
}