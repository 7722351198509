@charset "UTF-8";
/* ----------------------------------------------------------------
拠点・グループ会社　国内拠点のみのスタイル
-----------------------------------------------------------------*/
.c-container--jp {
    .c-inner--jp {
        .btn {
            width: 100%;
	@media #{$querySp} {
        margin-bottom: 40px;
	}
	@media #{$queryPc} {
		display: flex;
		justify-content: space-between;
        margin: 0 0 100px;
	}
            li {
                 @media #{$querySp} {
                     width: 100%;
                     margin-bottom: 10px;
	    }
	        @media #{$queryPc} {
                width: 29%;
            
	    }
            
            a {
            position: relative;
            font-size: 1.6rem;
            /*font-weight: 600;*/
            color: #000;
            
            margin: 0 auto;
            display: block;
            border: solid 1px #000;
            text-align: center;
            line-height: 3.3;
           
            &:after {
    position: absolute;
    top: 50%;
    right: 1em;
    width: 0.4em;
    height: 0.4em;
    transform: translateY(-50%) rotate(45deg);
    border-right: 1px solid #000;
    border-top: 1px solid #000;
    content: "";
}
            &:hover {
            background-color: $clrKey;
                color: #fff;
            &:after {
                border-right: 1px solid #fff;
    border-top: 1px solid #fff;
            }
        }
        }
                }
            
        }
        .hub-jp {
            @media #{$querySp} {
                width: 95%;
                margin: auto;
            }
            @media #{$queryPc} {
            }
            .wrap {
                padding: 0 10px;
                @media #{$querySp} {
                    margin: 0 auto 30px;
                }
                @media #{$queryPc} {
                    margin: 0 auto 70px;
                }
                &.wrap--photo {
                    @media #{$querySp} {
                        margin-bottom: 40px;
                    }
                    @media #{$queryPc} {
                    }
                    .hub {
                        @media #{$querySp} {
                            width: 100%;
                        }
                        @media #{$queryPc} {
                            width: 70%;
                        }
                    }
                    .photo {
                        @media #{$querySp} {
                            width: 100%;
                        }
                        @media #{$queryPc} {
                            width: 25%;
                        }
                        img {
                            @media #{$querySp} {
                            width: 100%;
                                height: auto;
                        }
                            @media #{$queryPc} {
                            width: 100%;
                                height: auto;
                        }
                        }
                    }
                }
                .hub {
                    .btn {
                        @media #{$querySp} {
                            margin: 0;
                        }
                        @media #{$queryPc} {
                            margin: 0;
                        }
                        a {
                            @media #{$querySp} {
                                margin: 0 auto;
                                line-height: 3.3;
                                width: 100%;
                        }
                        @media #{$queryPc} {
                            margin: 0;
                            line-height: 2.6;
                            width: 47%;
                        }
            position: relative;
            font-size: 1.6rem;
            /*font-weight: 600;*/
            color: #000;
            
            
            display: block;
            border: solid 1px #000;
            text-align: center;
            
           
            &:after {
    position: absolute;
    top: 50%;
    right: 1em;
    width: 0.4em;
    height: 0.4em;
    transform: translateY(-50%) rotate(45deg);
    border-right: 1px solid #000;
    border-top: 1px solid #000;
    content: "";
}
            &:hover {
            background-color: $clrKey;
                color: #fff;
            &:after {
                border-right: 1px solid #fff;
    border-top: 1px solid #fff;
            }
        }
                            
        }
                    }
                    }
                table {
                    table-layout:auto;
                    @media #{$querySp} {
                        margin-bottom: 20px;
                    }
                    @media #{$queryPc} {
                        margin-bottom: 30px;
                    }
                    th {
                        /*font-weight: 600;*/
                         
                         border:none;
                        @media #{$querySp} {
                            display: block;
                            width: 100%;
                        }
                        @media #{$queryPc} {
                            width: 80px;
                            padding: 0 0 10px 0;
                            font-size: 1.6rem;
                        }
                        &.justify {
                            @media #{$queryPc} {
                            -moz-text-align-last: justify;
                             text-align-last: justify;
                             text-justify:inter-ideograph;
                             padding: 0 15px 10px 0;
                        }
                        }
                    }
                    td {
                        /*font-weight: 600;*/
                        @media #{$querySp} {
                            display: block;
                            width: 100%;
                            padding: 0 0 10px;
                        }
                        @media #{$queryPc} {
                            width: 530px;
                            padding: 10px 0 10px 30px;
                            font-size: 1.6rem;
                        }
                    }
                }
                h4 {
                            /*font-weight: 600;*/
                            border-bottom: solid 1px #000;
                            @media #{$querySp} {
                                font-size: 1.8rem;
                                margin: 20px auto 10px;
                                padding-bottom: 5px;
                            }
                            @media #{$queryPc} {
                                font-size: 2.4rem;
                                margin: 0 auto 10px;
                                padding-bottom: 10px;
                            }
                        }
            }
            .wrap--hub {
                @media #{$querySp} {
                }
                @media #{$queryPc} {
                }
                div {
                    @media #{$querySp} {
                        width: 100%;
                        margin-bottom: 40px;
                    }
                    @media #{$queryPc} {
                        width: 48%;
                    }
                    .btn {
                        a {
                            margin: 0;
                        }
                    }
                    h4 {
                        margin-top: 0;
                    }
                }
            }
            h3 {
                /*font-weight: 600;*/
                text-align: center;
                    @media #{$querySp} {
                        font-size: 2rem;
                        margin-bottom: 10px;
                        }
                    @media #{$queryPc} {
                        font-size: 3rem;
                        margin-bottom: 10px;
                        }
            }
            p {
                &.lead {
                    /*font-weight: 600;*/
                    text-align: center;
                    @media #{$querySp} {
                        /*font-size: 1.5rem;*/
                        margin-bottom: 39px;
                        line-height: 24px;
                    }
                    @media #{$queryPc} {
                        font-size: 1.6rem;
                        margin-bottom: 78px;
                        line-height: 40px;
                    }
                }
            }
            
        }
    }
}
