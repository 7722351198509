@charset "UTF-8";
/* ----------------------------------------------------------------
アクセスのみのスタイル
-----------------------------------------------------------------*/
.main--address-hub {
    .c-mainInner {
        padding-top: 0;
    }
}
.c-container--address-hub {
    .c-inner--address-hub {
            @media #{$querySp} {
                width: 95%;
                margin: auto;
            }
            @media #{$queryPc} {
                width: 700px;
                max-width: 700px;
                min-width: 700px;
                margin: 0 auto;
            }
        h3 {
                    font-weight: 600;
                    @media #{$querySp} {
                        font-size: 2rem;
                        margin: 0 auto 10px;
                        padding-bottom: 5px;
                    }
                    @media #{$queryPc} {
                        font-size: 3rem;
                        margin: 0 auto 10px;
                        padding-bottom: 10px;
                    }
                }
        .hub-jp {
            @media #{$querySp} {
                width: 95%;
                margin: auto;
            }
            @media #{$queryPc} {
            }
            .hub--access {
                @media #{$querySp} {
                }
                @media #{$queryPc} {
                }
                h4 {
                    font-weight: 600;
                    @media #{$querySp} {
                        font-size: 1.8rem;
                        margin: 0 auto 10px;
                    }
                    @media #{$queryPc} {
                        font-size: 2.4rem;
                        margin: 0 auto 10px;
                    }
                }
                p {
                    @media #{$querySp} {
                        font-size: 1.6rem;
                        margin: 0 auto 10px;
                    }
                    @media #{$queryPc} {
                        font-size: 1.6rem;
                        margin: 0 auto 10px;
                    }
                    span {
                        /*font-weight: 600;*/
                        margin-right: 10px;
                    }
                }
            .wrap {
                @media #{$querySp} {
                    margin: 0 auto 10px;
                }
                @media #{$queryPc} {
                    margin: 0 auto 20px;
                }
                &.wrap--photo {
                    @media #{$querySp} {
                        margin-bottom: 20px;
                    }
                    @media #{$queryPc} {
                    }
                    .hub {
                        @media #{$querySp} {
                            width: 100%;
                        }
                        @media #{$queryPc} {
                            width: 65%;
                        }
                    }
                    .photo {
                        @media #{$querySp} {
                            width: 100%;
                            margin-bottom: 10px;
                        }
                        @media #{$queryPc} {
                            width: 30%;
                        }
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
                .hub {
                    }
                table {
                    table-layout:auto;
                    @media #{$querySp} {
                        margin-bottom: 20px;
                    }
                    @media #{$queryPc} {
                        margin-bottom: 20px;
                    }
                    th {
                        font-weight: 600;
                        font-size: 1.6rem;
                         
                         border:none;
                        @media #{$querySp} {
                            display: block;
                            width: 100%;
                        }
                        @media #{$queryPc} {
                            width: 90px;
                            padding: 0 0 10px 0;
                        }
                        &.justify {
                            @media #{$queryPc} {
                            -moz-text-align-last: justify;
                             text-align-last: justify;
                             text-justify:inter-ideograph;
                             padding: 0 15px 10px 0;
                        }
                        }
                    }
                    td {
                        /*font-weight: 600;*/
                        font-size: 1.6rem;
                        @media #{$querySp} {
                            display: block;
                            width: 100%;
                        }
                        @media #{$queryPc} {
                            width: 530px;
                            padding: 5px 0 5px 30px;
                        }
                    }
                }
                
            }
            .wrap--hub {
                @media #{$querySp} {
                }
                @media #{$queryPc} {
                }
                div {
                    @media #{$querySp} {
                        width: 100%;
                        margin-bottom: 40px;
                    }
                    @media #{$queryPc} {
                        width: 48%;
                    }
                    .btn {
                        a {
                            margin: 0;
                        }
                    }
                    h4 {
                        margin-top: 0;
                    }
                }
            }
            h3 {
                font-weight: 600;
                text-align: center;
                    @media #{$querySp} {
                        font-size: 2rem;
                        margin-bottom: 10px;
                        }
                    @media #{$queryPc} {
                        font-size: 3rem;
                        margin-bottom: 10px;
                        }
            }
                .gmap {
                    position: relative;
                    @media #{$querySp} {
                        margin-bottom: 20px;
                        height: 0;
                        overflow: hidden;
                        padding-bottom: 56.25%;
                        position: relative;
                        }
                    @media #{$queryPc} {
                        margin-bottom: 30px;
                        }
                    iframe {
                        @media #{$querySp} {
                            position: absolute;
                            left: 0;
                            top: 0;
                            height: 100%;
                            width: 100%;
                        }
                        @media #{$queryPc} {
                        }
                    }
                }
                .btn {
                        @media #{$querySp} {
                            margin: 0 auto 20px;
                        }
                        @media #{$queryPc} {
                            margin: 0 auto 20px;
                        }
                        a {
                            /*background: #efefef;*/
                            /*border: none;*/
                            @media #{$querySp} {
                                margin: 0 auto;
                                line-height: 3.3;
                                width: 100%;
                        }
                            @media #{$queryPc} {
                            margin: 0 auto;
                            line-height: 2.6;
                            width: 30%;
                            }
                            position: relative;
                            font-size: 1.4rem;
                            font-weight: 600;
                            color: #000;
                            display: block;
                            text-align: center;
                            &:after {
            display: inline-block!important;
    content: ""!important;
    width: 13px!important;
    height: 13px!important;
    margin-left: 20px!important;
    background: url(../images/csr/icon_blank.png) no-repeat center center!important;
    background-size: 13px auto!important;
    position: absolute!important;
    bottom: 5px;
    right: 5px;
    border: none;
    transform: none;
            @media #{$querySp} {
            }
            @media #{$queryPc} {
            }
		}
        &:hover {
            background-color: $clrKey;
                color: #fff;
            &:after {

            background: url("../images/csr/icon_blank_hover.png") no-repeat center center !important;

            @media #{$querySp} {
            }
            @media #{$queryPc} {
            }
		}
            
        }
                            
        }
                    }
                
        }
        }
    }
}
.contentinfo--address-hub {
    @media #{$querySp} {
    }
    @media #{$queryPc} {
    }
    .f-logo {
        @media #{$querySp} {
            margin: 0 !important;
            padding-top: 20px;
        }
        @media #{$queryPc} {
            margin: 0 0 20px !important;
            padding-top: 20px;
        }
    }
    .f-copy {
        @media #{$querySp} {
            padding:10px 0 20px !important;
        }
        @media #{$queryPc} {
            padding-bottom: 20px;
        }
    }
}