@charset "UTF-8";
/* ----------------------------------------------------------------
研究開発のみのスタイル
-----------------------------------------------------------------*/
/* 部門長メッセージ
-----------------------------------------------------------------*/
.c-container--research {
    @media #{$querySp} {
    }
    @media #{$queryPc} {
    }
    h4.h4 {
        font-weight: 600;
        @media #{$querySp} {
            font-size: 1.8rem;
            margin-bottom: 10px;
        }
        @media #{$queryPc} {
            font-size: 2rem;
            margin-bottom: 20px;
        }
    }
    .name {
        @media #{$querySp} {
            margin-top: 10px;
            margin-bottom: 10px;
        }
        @media #{$queryPc} {
            margin-top: 20px;
        }
        span {
            @media #{$querySp} {
                font-size: 2rem;
            }
            @media #{$queryPc} {
                font-size: 3rem;
            }
        }
    }
    .p-laed {
        font-weight: 600;
        @media #{$querySp} {
                font-size: 2rem;
            margin-bottom: 40px;
            }
            @media #{$queryPc} {
                font-size: 2.2rem;
                margin-bottom: 30px;
            }
    }
    .p-comTopBottomListItem {
	@media #{$querySp} {
		margin-bottom: 20px;
	}
	@media #{$queryPc} {
		display: table;
		width: 30%;
		height: 80px;
		margin-bottom: 20px;
	}
	&:nth-last-child(-n+4) {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			margin-bottom: 0;
		}
	}
	&:last-child {
		@media #{$querySp} {
			margin-bottom: 0;
		}
		@media #{$queryPc} {
		}
	}
        
}
    .p-comTopBottomListItem {
    }
    .p-comTopBottom__link {
	color: #000;
        position: relative;
        font-weight: 600;
        color: #000;
        border: solid 1px #000;
        text-align: center;
        
            @media #{$querySp} {
                font-size: 1.4rem;
                display: block;
                width: 100%;
                right: 0;
                margin: 10px auto 20px;
                padding: 20px 0;
	    }
	        @media #{$queryPc} {
                font-size: 1.6rem;
                display: inline-block;
                width: 100%;
                margin-left: 30px;
                line-height: 5;
	    }
        &:after {
    position: absolute;
    top: 50%;
    right: 1em;
    width: 0.4em;
    height: 0.4em;
    transform: translateY(-50%) rotate(45deg);
    border-right: 1px solid #000;
    border-top: 1px solid #000;
    content: "";
}
        &:hover {
            background-color: $clrKey;
                color: #fff;
            &:after {
                border-right: 1px solid #fff;
    border-top: 1px solid #fff;
            }
        }
}
    .c-inner--lg {
        @media #{$querySp} {
			margin-top: 50px;
		}
		@media #{$queryPc} {
            margin-top: 150px;
		}
    }
}

