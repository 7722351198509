@charset "UTF-8";
/* ----------------------------------------------------------------
研究テーマ紹介のみのスタイル
-----------------------------------------------------------------*/
/* 研究テーマ紹介
-----------------------------------------------------------------*/
.c-container--research {
    @media #{$querySp} {
    }
    @media #{$queryPc} {
    }
    .section--recent_study {
        @media #{$querySp} {
            margin-bottom: 50px;
        }
        @media #{$queryPc} {
            margin-bottom: 100px;
        }
        .p--recent_study {
            @media #{$querySp} {
                margin-bottom: 20px;
            }
            @media #{$queryPc} {
                width: 48%;
            }
        }
        .img--recent_study {
            @media #{$querySp} {
                margin-bottom: 20px;
            }
            @media #{$queryPc} {
                width: 48%;
            }
            span {
                display: block;
                @media #{$querySp} {
                    padding-top: 5px;
                }
                @media #{$queryPc} {
                    padding-top: 10px;
                }
            }
        }
        .scroll-table {
            @media #{$querySp} {    
            }
            @media #{$queryPc} {
                margin-top: 20px;
            }
            th {
                border: solid 1px #ccc;
                width: 16.666%;
            }
            td {
                border-top: solid 1px #ccc;
                border-right: solid 1px #ccc;
                border-bottom: solid 1px #ccc !important;
                border-left: solid 1px #ccc;
                sub, sup {
                    position: relative;
                    font-size: 1.2rem;
                    line-height: 0;
                    vertical-align: baseline;
                }
            }
        }
    }
    .section--recent_study {
        @media #{$querySp} {
        }
        @media #{$queryPc} {
        }
        .wrap--recent_study {
            .p--recent_study {
                @media #{$querySp} {
                }
                @media #{$queryPc} {
                }
            }
            .img--recent_study {
                @media #{$querySp} {
                }
                @media #{$queryPc} {
                }
            }
            .img--recent_study--col3 {
                @media #{$querySp} {
                }
                @media #{$queryPc} {
                    width: 33%;
                }
            }
        }
    }
    .wrap--recent_study--list {
        @media #{$querySp} {
        }
        @media #{$queryPc} {
        }
        .recent_study--list {
           @media #{$querySp} {
               margin-bottom: 20px;
            }
            @media #{$queryPc} {
                width: 48%;
            } 
        }
    }
    .p-pdListDlWrap {
        @media #{$querySp} {
               margin-bottom: 30px;
            }
    }
    .p-pdListDlItem__link span:before {
            content: "";
			display: inline-block;
			position: absolute;
		    top: 0;
			bottom: 0;
			left: 0;
			margin: auto 0;
			@media #{$querySp} {
				width: 19px;
			    height: 25px;
			    background: url("../images/icon_doc.png") no-repeat center top;
			    background-size: 19px auto;
			}
			@media #{$queryPc} {
			    width: 19px;
			    height: 25px;
			    background: url("../images/icon_doc.png") no-repeat center top;
			    background-size: 19px auto;
			    @include trans($duration: .2s,$delay: 0s);
			}
    }
    .btnlist-item--wrap {
	@media #{$querySp} {
        margin-bottom: 30px;
	}
	@media #{$queryPc} {
		display: flex;
		flex-wrap: wrap;
        margin-bottom: 50px;
	}
}
    .btnlist-item {
	@media #{$querySp} {
		margin-bottom: 20px;
	}
	@media #{$queryPc} {
		min-width: 24%;
		margin: 0 1.3% 1.3% 0;
	}
    &:nth-child(4n) {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
            margin: 0 0 10px 0;
		}
	}
	&:last-child {
		@media #{$querySp} {
			margin-bottom: 0;
		}
		@media #{$queryPc} {
		}
	}
}
    .btnlist-item__link {
        display: block;
        text-align: center;
        font-weight: 600;
        color: #000;
        background: #fff;border: solid 1px #000;
	@media #{$querySp} {
		padding: 16px 0;
	}
	@media #{$queryPc} {
		padding: 22px 0;
        line-height: 1.3;
		@include trans($duration: .2s,$delay: 0s);
	}
	&:before {
		display: none !important;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
	span {
		position: relative;
		@media #{$querySp} {
			padding-left: 32px;
		}
		@media #{$queryPc} {
			padding-left: 32px;
		}
	}
	&:hover {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			color: #fff;
			background: $clrKey;
		}
		
	}
}
    .p-pdItemContactItem {
        text-align: center;
	@media #{$querySp} {
        }
        @media #{$queryPc} {
            margin: 0 auto;
            
        }
}
    
}

