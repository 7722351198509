@charset "UTF-8";
/* ----------------------------------------------------------------
 ir/finance
-----------------------------------------------------------------*/
.c-container--ir {
    .c-inner--md {
        padding: 0;
        @media #{$querySp} {
            width: 95%;
            margin: 0 auto 40px;
        }
    }
    .c-inner--md.c-inner--980{
        padding: 0 10px;
    }
}

.wrap--ir {
    &.wrap--ir_finance {
        .wrap-finance_box {
            @media #{$querySp} {
                margin: 30px 0;
            }
            @media #{$queryPc} {
                margin: 60px 0;
            }
            .b-content {
                @media #{$querySp} {
                    margin: 30px 0;
                }
                h6.midashi {
                    @media #{$querySp} {
                        margin: 10px 0;
                    }
                    @media #{$queryPc} {
                        margin: 20px 0;
                    }
                }
                table {
                    &.tbl_type_01 {
                        width: 100%;
                        th {
                            background: #efefef;
                            border: 1px solid #000;
                            box-sizing: border-box;
                            text-align: center;
                            @media #{$querySp} {
                                padding: 6px 0;
                            }
                            @media #{$queryPc} {
                                padding: 12px 0;
                                width:240px;
                            }
                        }
                        td {
                            border: 1px solid #000;
                            box-sizing: border-box;
                            text-align: center;
                            @media #{$querySp} {
                                padding: 6px 0;
                            }
                            @media #{$queryPc} {
                                padding: 12px 0;
                            }
                        }
                    }
                }
                ul.wrap-link_excel {
                    li {
                        @media #{$querySp} {
                            margin-bottom: 10px;
                        }
                        @media #{$queryPc} {
                            margin-bottom: 20px;
                        }
                        a {
                            position: relative;
                            color: #000;
                            @media #{$querySp} {
                                width: 100%;
                                display:inline;
                            }
                            @media #{$queryPc} {
                                display: inline-block;
                            }
                            &:after{
                                width: 18px;
                                height: 18px;
                                content: "";
                                display: inline-block;
                                background: url(../images/ir/finance/excel_icon.svg) no-repeat center top;
                                background-size: 18px auto;
                                position: absolute;
                                right: -25px;
                                @media #{$querySp} {
                                    top: 0%;
                                }
                                @media #{$queryPc} {
                                    top: 14%;
                                }
                            }
                        }
                    }
                }
            }
            &.col_1 {
                width: 100%;
            }
            &.col_2 {
                width: 100%;
                @media #{$querySp} {
                    > div {
                        width: 100%;
                    }
                }
                @media #{$queryPc} {
                    > div {
                        float: left;
                        width: 48%;
                        margin: 0 2% 0 0;
                        &:nth-child(2n)  {
                            margin: 0 0 0 2%;
                        }
                    }
                }
            }
        }
    }
    &.col_2 {
        justify-content: initial;
        .wrap-menu {
            @media #{$querySp} {
                margin-bottom: 30px;
            }
            @media #{$queryPc} {
                float: left;
                margin: 0 2% 0 0;
            }
        }
        :last-child.wrap-menu {
            margin: 0 0 0 0;
        }
    }
}
