@charset "UTF-8";
/* ----------------------------------------------------------------
動画ギャラリーのみのスタイル
-----------------------------------------------------------------*/
.p-MovCol2Wrap {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		display: flex;
	}
}

.p-movCol2__left {
	@media #{$querySp} {
		margin-bottom: 8px;
	}
	@media #{$queryPc} {
	}
	img {
		@media #{$querySp} {
			width: 100%;
			height: auto;
		}
		@media #{$queryPc} {
		}
	}
}

.p-movCol2__right {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		padding-left: 40px;
		flex: 1;
	}
}

.p-MovCol3Wrap {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		display: flex;
		justify-content: space-between;
	}
}

.p-movTkyMovieWrap {
	width: 100%;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

.p-movTkyMovieArea {
	border-top: 1px solid #000;
	display: flex;
	align-items: center;
	@media #{$querySp} {
		padding: 10px 0;
	}
	@media #{$queryPc} {
		padding: 12px 0;
	}
}

.p-movTkyMovie__head {
	flex: 1;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

.p-movTkyMovie__time {
	@media #{$querySp} {
		padding: 0 8px;
		font-size: 1.1rem;
	}
	@media #{$queryPc} {
		padding: 0 16px;
		font-size: 1.4rem;
	}
}

.p-movTkyMovie__play {
	@media #{$querySp} {
		width: 88px;
	}
	@media #{$queryPc} {
		width: 118px;
	}
}

.p-movTkyMovie__Link {
	display: block;
	text-align: center;
	background-color: #000;
	padding: 2px 0;
	color: #fff;
	@media #{$querySp} {
		font-size: 1.1rem;
	}
	@media #{$queryPc} {
		font-size: 1.4rem;
		@include trans($duration: .2s,$delay: 0s);
	}
	&:hover {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			@include opacity(.5);
		}
	}
	span {
		@media #{$querySp} {
			padding-right: 20px;
			background: url("../images/ico_play_w.png") no-repeat right center;
			background-size: 12px auto;
		}
		@media #{$queryPc} {
			padding-right: 22px;
			background: url("../images/ico_play_w.png") no-repeat right center;
			background-size: 14px auto;
		}
	}
}

.p-movCol3__box {
	@media #{$querySp} {
		margin-bottom: 15px;
	}
	@media #{$queryPc} {
	}
	img {
		@media #{$querySp} {
			width: 100%;
			height: auto;
		}
		@media #{$queryPc} {
		}
	}
}

.p-xxxxxxxx {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

.p-xxxxxxxx {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

.p-xxxxxxxx {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}





















