@charset "UTF-8";
/* ----------------------------------------------------------------
研究開発のみのスタイル
-----------------------------------------------------------------*/
/* 部門長メッセージ
-----------------------------------------------------------------*/
.c-container--research {
    @media #{$querySp} {
    }
    @media #{$queryPc} {
    }
    .p-reschTopListWrap {
	width: 100%;
        @media #{$querySp} {
            margin-top: 30px;
        }
        @media #{$queryPc} {
            margin-top: 50px;
            flex-wrap: wrap;
        }
        .p-reschTopListItem {
            @media #{$querySp} {
            }
            @media #{$queryPc} {
                margin-bottom: 50px;
            }
            .p-reschTopListItem__headLg {
                font-weight: 600;
                @media #{$querySp} {
                }
                @media #{$queryPc} {
                }
            }
            p {
                    line-height: 1.5;
                text-align: center;
                margin: 10px 0 0;
                    @media #{$querySp} {
                    }
                    @media #{$queryPc} {
                    }
                }
        }
    }
}

