@charset "UTF-8";
/* ----------------------------------------------------------------
CSRのみのスタイル
-----------------------------------------------------------------*/
/* トクヤマグループのCSR
-----------------------------------------------------------------*/
.c-container--csr {
    @media #{$querySp} {
    }
    @media #{$queryPc} {
    }
    h4.h4 {
        font-weight: 600;
        @media #{$querySp} {
            font-size: 1.6rem;
            margin-bottom: 10px;
        }
        @media #{$queryPc} {
            font-size: 1.8rem;
            margin-bottom: 20px;
        }
    }
    .name {
        @media #{$querySp} {
            margin-top: 10px;
        }
        @media #{$queryPc} {
            margin-top: 20px;
        }
        span {
            @media #{$querySp} {
                font-size: 1.8rem;
                padding-left: 10px;
            }
            @media #{$queryPc} {
                font-size: 2rem;
                padding-left: 20px;
            }
        }
    }
    .wrap--activities {
        div:first-child {
            @media #{$queryPc} {
                width: 60%;
            }
        }
        div:last-child {
            @media #{$queryPc} {
                width: 35%;
                padding: 50px 0 0;
            }
            img {
                width: 100%;
                height: auto;
            }
        }
        .table--activities {
            tr:nth-child(1) {
                border-top: solid 1px #000;
                background: #fff !important;
                td {
                    font-weight: normal !important;
                    border-bottom: solid 1px #000;
                    vertical-align: top !important;
                }
            }
        }
    }
    .img--size50 {
        img {
            @media #{$querySp} {
                width: 50%;
            }
        }
    }
    
}

