@charset "UTF-8";
/* ----------------------------------------------------------------
拠点・グループ会社　国内拠点のみのスタイル
-----------------------------------------------------------------*/
.c-container--purchase_policy {
    .c-inner--md--purchase_policy {
        @media #{$querySp} {
            margin-bottom: 30px;
        }
        @media #{$queryPc} {
            margin-bottom: 50px;
        }
        .c-purchase_policy {
            @media #{$querySp} {
                margin-bottom: 30px;
            }
            @media #{$queryPc} {
                margin-bottom: 50px;
            }
            h3 {
                font-weight: 600;
                background: #efefef;
                text-align: center;
                @media #{$querySp} {
                    font-size: 2rem;
                    line-height: 2.2;
                    margin-bottom: 20px;
                }
                @media #{$queryPc} {
                    font-size: 2.4rem;
                    line-height: 2.6;
                    margin-top: 80px;
                    margin-bottom: 50px;
                }
            }
            &.c-purchase_policy--basic {
                @media #{$querySp} {
                }
                @media #{$queryPc} {
                }
                p {
                    /*font-weight: 600;*/
                    @media #{$querySp} {
                        /*font-size: 1.4rem;*/
                        margin-bottom: 20px;
                    }
                    @media #{$queryPc} {
                        font-size: 1.6rem;
                        margin-bottom: 30px;
                    }
                }
                ol {
                    list-style: decimal;
                    padding-left: 20px;
                    @media #{$querySp} {
                        margin-bottom: 20px;
                    }
                    @media #{$queryPc} {
                        margin-bottom: 10px;
                    }
                    li {
                        /*font-weight: 600;*/
                        @media #{$querySp} {
                            /*font-size: 1.6rem;*/
                            margin-bottom: 5px;
                        }
                        @media #{$queryPc} {
                            font-size: 1.6rem;
                            margin-bottom: 0px;
                        }
                        ul {
                            margin: -5px 0 10px;;
                            padding: 0;
                            line-height: 1;
                            li {
                                margin: 0;
                                line-height: 0.6;
                            }
                        }
                    }
                }
            }
            &.c-purchase_policy--csr {
                @media #{$querySp} {
                }
                @media #{$queryPc} {
                }
                .p-csr {
                    font-weight: 600;
                        @media #{$querySp} {
                            font-size: 1.8rem;
                            margin-bottom: 5px;
                        }
                        @media #{$queryPc} {
                            font-size: 2.2rem;
                            margin-bottom: 5px;
                        }
                }
                ul {
                    list-style: disc;
                    margin-bottom: 20px;
                    padding-left: 20px;
                    li {
                        line-height: 1.4;
                        /*font-weight: 600;*/
                        @media #{$querySp} {
                            /*font-size: 1.6rem;*/
                            margin-bottom: 0;
                        }
                        @media #{$queryPc} {
                            font-size: 1.6rem;
                            margin-bottom: 0;
                            
                        }
                    }
                }
                img {
                    @media #{$querySp} {
                        width: 100%;
                        height: auto;
                    }
                    @media #{$queryPc} {
                    }
                }
            }
            &.c-purchase_policy--item {
                @media #{$querySp} {
                }
                @media #{$queryPc} {
                }
                p {
                    /*font-weight: 600;*/
                        @media #{$querySp} {
                            /*font-size: 1.6rem;*/
                            margin-bottom: 20px;
                        }
                        @media #{$queryPc} {
                            font-size: 1.6rem;
                            margin-bottom: 20px;
                            line-height: 1.6;
                        }
                }
            }
            .p-annotation {
                font-size: 1.2rem !important;
            }
        }
    }
    .c-purchase_policy--contact {
        background: #eeeeee;
        @media #{$querySp} {
            margin-bottom: 40px;
            padding: 30px 0;
        }
        @media #{$queryPc} {
            padding: 50px 0;
            margin-bottom: 100px;
        }
        .c-purchase_policy--contact--inner {
            background: #fff;
            @media #{$querySp} {
                width: 95%;
                margin: 0 auto;
                padding: 0 20px 20px;
            }
            @media #{$queryPc} {
                width: 1000px;
                margin: 0 auto;
                padding: 0 50px 40px;
            }
            h3 {
                font-weight: 600;
                text-align: center;
                @media #{$querySp} {
                    font-size: 2rem;
                    margin-bottom: 20px;
                    padding: 20px 0 0;
                }
                @media #{$queryPc} {
                    font-size: 2.4rem;
                    margin-bottom: 30px;
                    padding: 20px 0 0;
                }
            }
            .wrap--purchase_policy {
                border-bottom: solid 1px #000;
                @media #{$querySp} {
                    margin-bottom: 10px;
                    padding: 0;
                }
                @media #{$queryPc} {
                    margin-bottom: 20px;
                    padding: 0 0 20px;
                }
                div {
                    @media #{$querySp} {
                        width: 100%;
                    }
                    @media #{$queryPc} {
                        width: 47%;
                    }
                }
                h4 {
                    color: #fff;
                    background: #000;
                    font-weight: 600;
                    @media #{$querySp} {
                        font-size: 1.6rem;
                        margin-bottom: 15px;
                        padding: 5px 20px;
                    }
                    @media #{$queryPc} {
                        font-size: 1.8rem;
                        margin-bottom: 20px;
                        padding: 5px 30px;
                    }
                }
                .p-adress {
                    /*font-weight: 600;*/
                    @media #{$querySp} {
                        margin-bottom: 15px;
                    }
                    @media #{$queryPc} {
                        font-size: 1.6rem;
                        padding: 0 0 10px 20px;
                    }
                }
                .p-tel {
                    @media #{$querySp} {
                        margin-bottom: 20px;
                    }
                    @media #{$queryPc} {
                        margin-left: 20px;
                    }
                    a {
                        color: #000;
                        font-weight: 600;
                        @media #{$querySp} {
                            font-size: 2.8rem;
                        }
                        @media #{$queryPc} {
                            font-size: 3.4rem;
                        }
                        &:before {
                            content: "";
                            display: inline-block;
                            @media #{$querySp} {
                                width: 18px;
                                height: 28px;
                                margin-right: 9px;
                                background: url("../images/company/purchase_policy/icon_tel.png") no-repeat center center;
                                background-size: 18px auto;
                            }
                            @media #{$queryPc} {
                                width: 24px;
                                height: 32px;
                                margin-right: 10px;
                                background: url("../images/company/purchase_policy/icon_tel.png") no-repeat center top;
                                background-size: 24px auto;
                            }
                        }
                    }
                    a[href^="tel:"] {
                        @media #{$querySp} {
                        }
                        @media #{$queryPc} {
                            pointer-events: none;
                        }
                    }
                }
            }
            .btn {
                @media #{$querySp} {
                }
                @media #{$queryPc} {
                }
                a {
                    color: #fff;
                    background: #a2a8da;
                    border: none;
                    @media #{$querySp} {
                    }
                    @media #{$queryPc} {
                        width: 35%;
                        line-height:3; 
                    }
                    &:after {
                        @media #{$querySp} {
                            border-right: 1px solid #fff;
                            border-top: 1px solid #fff;
                            right: 1em;
                        }
                        @media #{$queryPc} {
                            border-right: 1px solid #fff;
                            border-top: 1px solid #fff;
                            right: 1em;
                        }
                    }
                    &:hover {
                        opacity: .5;
                    }
                }
            }
            .u-aCenter {
                /*font-weight: 600;*/
                @media #{$querySp} {
                    padding-bottom: 10px;
                }
                @media #{$queryPc} {
                    padding-bottom: 20px;
                }
            }
        }
    }
}
