@charset "UTF-8";
/* ----------------------------------------------------------------
研究開発のみのスタイル
-----------------------------------------------------------------*/
/* 部門長メッセージ
-----------------------------------------------------------------*/
.c-container--research {
    @media #{$querySp} {
    }
    @media #{$queryPc} {
    }
    .c-inner--organization {
        @media #{$querySp} {
        }
        @media #{$queryPc} {
        }
        h3 {
            font-weight: 600;
            text-align: center;
            @media #{$querySp} {
                font-size: 2rem;
                margin-top: 30px;
                margin-bottom: 30px;
            }
            @media #{$queryPc} {
                font-size: 2.4rem;
                margin-top: 50px;
                margin-bottom: 30px;
            }
        }
        .section--organization {
            @media #{$querySp} {
                margin-bottom: 40px;
            }
            @media #{$queryPc} {
                margin-bottom: 80px;
            }
            h4 {
                font-weight: 600;
                background: #efefef;
                text-align: center;
                @media #{$querySp} {
                    font-size: 2rem;
                    line-height: 1.4;
                    margin-bottom: 20px;
                    padding: 10px;
                }
                @media #{$queryPc} {
                    font-size: 2.4rem;
                    line-height: 2.6;
                    margin-bottom: 40px;
                }
            }
            .organization--category {
                @media #{$querySp} {
                    margin-bottom: 30px;
                }
                @media #{$queryPc} {
                    margin-bottom: 50px;
                }
                h5 {
                    font-weight: 600;
                    @media #{$querySp} {
                        font-size: 1.6rem;
                        margin-bottom: 10px;
                    }
                    @media #{$queryPc} {
                        font-size: 1.8rem;
                        margin-bottom: 20px;
                    }
                }
                p {
                    @media #{$querySp} {
                    }
                    @media #{$queryPc} {
                    }
                }
            }
        }
    }
}

