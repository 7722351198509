@charset "UTF-8";
/* ----------------------------------------------------------------
製品情報のみのスタイル
-----------------------------------------------------------------*/
/* 製品情報トップ
-----------------------------------------------------------------*/
.p-pdTopSearchArea {
	@media #{$querySp} {
		margin-bottom: 40px;
		padding: 0 15px;
	}
	@media #{$queryPc} {
		display: flex;
		justify-content: space-between;
		margin-bottom: 40px;
	}
}

.p-pdTopSdsDlWrap {
	@media #{$querySp} {
		margin-bottom: 40px;
	}
	@media #{$queryPc} {
		width: 470px;
		padding: 15px;
		background-color: #efefef;
	}
}

.p-pdTopSdsDl__link {
	display: block;
	width: 100%;
	background: url("../images/ico_arrow_r_bk.svg") no-repeat right 15px center #fff;
	background-size: 6px auto;
	border: 1px solid #000;
	color: #000;
	text-align: center;
	font-weight: 600;
	letter-spacing: .1em;
	@media #{$querySp} {
		padding: 20px;
		font-size: 1.6rem;
	}
	@media #{$queryPc} {
		height: 68px;
		font-size: 1.8rem;
		line-height: 68px;
		@include trans($duration: .2s,$delay: 0s);
	}
	&:hover {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			background: url("../images/ico_arrow_r_wh.svg") no-repeat right 15px center $clrKey;
			background-size: 6px auto;
			color: #fff;
		}
	}
}

.p-pdTopMoreWrap {
	@media #{$querySp} {
		width: 100%;
		margin-bottom: 50px;
	}
	@media #{$queryPc} {
		width: 470px;
	}
}

.p-pdTopMore__link {
	display: block;
	background: url("../images/ico_arrow_r_bk.svg") no-repeat right 15px center #efefef;
	background-size: 6px auto;
	color: #000;
	@media #{$querySp} {
		padding: 16px 35px 16px 16px;
		text-align: center;
	}
	@media #{$queryPc} {
		padding: 23px 36px 23px 30px;
		@include trans($duration: .2s,$delay: 0s);
	}
	&:hover {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			@include opacity(.5);
		}
	}
}

.p-pdTopMore__Head {
	font-weight: 600;
	@media #{$querySp} {
		font-size: 1.4rem;
		margin-bottom: 4px;
	}
	@media #{$queryPc} {
	}
	.left {
		display: inline-block;
		background-color: #000;
		border: 1px solid #000;
		color: #fff;
		@media #{$querySp} {
			padding: 0 2px;
		}
		@media #{$queryPc} {
			padding: 0 4px;
		}
	}
	.right {
		display: inline-block;
		background-color: #fff;
		border: 1px solid #000;
		@media #{$querySp} {
			padding: 0 2px;
		}
		@media #{$queryPc} {
			padding: 0 4px;
		}
	}
}

.p-pdTopMore__txt {
	font-weight: 600;
	@media #{$querySp} {
		font-size: 1.4rem;
	}
	@media #{$queryPc} {
	}
}

.p-pdTopSearchWrap {
	@media #{$querySp} {
		margin-bottom: 40px;
	}
	@media #{$queryPc} {
		margin-bottom: 40px;
		background-color: #efefef;
		display: flex;
		align-items: center;
		padding: 24px;
	}
}

.p-pdTopSearch__head {
	font-weight: 600;
	font-size: 1.8rem;
	@media #{$querySp} {
		text-align: center;
		margin-bottom: 10px;
	}
	@media #{$queryPc} {
		display: inline-block;
		padding-right: 20px;
		flex: none;
	}
}

.p-pdSearch__input {
	@media #{$querySp} {
		padding: 0 15px;
	}
	@media #{$queryPc} {
		width: 100%;
	}
	form {
		display: flex;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
}

.p-pdSearch__container input[type="text"]{
	display: inline-block;
	@media #{$querySp} {
		width: 100%;
		height: 40px;
		padding: 8px 16px;
		border: 1px solid #999;
		background-color: #fff;
		overflow: hidden;
		font-size: 1.4rem;
	}
	@media #{$queryPc} {
		width: 100%;
		height: 50px;
		padding: 8px 16px;
		border: 1px solid #999;
		background-color: #fff;
		overflow: hidden;
		font-size: 1.8rem;
	}
}

.p-pdSearch__container input[type="text"]:focus {
	outline: 0;
	@media #{$querySp} {
		height: 40px;
	}
	@media #{$queryPc} {
  		height: 50px;
	}
}

.p-pdSearch__container button {
	display: inline-block;
	border: 1px solid #999;
	border-left: none;
	@media #{$querySp} {
		width: 15.605%;
		height: 40px;
		background: url("../images/pic_search.png") no-repeat center center #fff;
		background-size: 23px auto;
		outline : none;
		vertical-align: middle;
		cursor: pointer;
	}
	@media #{$queryPc} {
		width: 55px;
		height: 50px;
		background: url("../images/pic_search.png") no-repeat center center #fff;
		background-size: 26px auto;
		outline : none;
		vertical-align: middle;
		cursor: pointer;
		@include trans($duration: .2s,$delay: 0s);
	}
	&:hover {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			@include opacity(.5);
		}
	}
}

.p-pdTopHeadlin {
	font-weight: 600;
	text-align: center;
	@media #{$querySp} {
		margin-bottom: 24px;
		font-size: 1.8rem;
	}
	@media #{$queryPc} {
		margin-bottom: 32px;
		font-size: 2.4rem;
	}
}

.p-pdTopTabWrap {
	display: flex;
	justify-content: center;
	border-bottom: 4px solid #000;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

.p-pdTopTabItem {
	margin-right: 10px;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
	&:last-child {
		margin-right: 0;
	}
}

.p-pdTopTabItem__link {
	position: relative;
	display: block;
	padding: 8px;
	text-align: center;
	color: #000;
	background-color: #ccc;
	@media #{$querySp} {
		font-size: 1.4rem;
		word-break: break-word;
	}
	@media #{$queryPc} {
		width: 219px;
		font-size: 1.6rem;
		@include trans($duration: .2s,$delay: 0s)
	}
	&--active {
		background-color: #000;
		color: #fff;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
		&:before {
			content: "";
			position: absolute;
			left: 0;
			width: 100%;
			background-color: #000;
			@media #{$querySp} {
				top: -5px;
				height: 5px;
			}
			@media #{$queryPc} {
				top: -5px;
				height: 5px;
			}
		}
	}
	&:hover {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			@include opacity(.5);
		}
	}
}

.p-pdTopMainContWrap {
	background-color: #efefef;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

.p-pdTopMainContInner {
	@media #{$querySp} {
		width: 100%;
		padding: 15px 15px 50px;
	}
	@media #{$queryPc} {
		width: 1000px;
		margin: 0 auto;
		padding: 21px 10px 100px;
	}
}

.p-pdTopCheckWrap {
	width: 100%;
	background-color: #fff;
	@media #{$querySp} {
		padding: 15px;
	}
	@media #{$queryPc} {
		margin-bottom: 45px;
		padding: 20px 40px 20px 60px;
	}
}

.p-pdTopCheckList {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
	input[type=checkbox] {
		display: none;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
	.p-pdTopCheckItem {
		box-sizing: border-box;
		position: relative;
		display: inline-block;
		@media #{$querySp} {
			padding: 2px 2px 2px 24px;
		}
		@media #{$queryPc} {
	  		padding: 5px 5px 5px 42px;
		}
	}
	.p-pdTopCheckItem:after {
		display: block;
		content: '';
		position: absolute;
		border: 1px solid #999;
		@media #{$querySp} {
			width: 18px;
			height: 18px;
			top: 50%;
			left: 0;
			margin-top: -8px;
		}
		@media #{$queryPc} {
			width: 21px;
			height: 21px;
			top: 50%;
			left: 13px;
			margin-top: -10px;
		}
	}
	.p-pdTopCheckItem:before {
		display: block;
		content: '';
		position: absolute;
		opacity: 0;
		transform: rotate(45deg);
		@media #{$querySp} {
			width: 6px;
			height: 14px;
			top: 48%;
			left: 6px;
			margin-top: -7px;
			border-right: 3px solid #000;
			border-bottom: 3px solid #000;
		}
		@media #{$queryPc} {
			width: 7px;
			height: 15px;
			top: 47%;
			left: 20px;
			margin-top: -7px;
			border-right: 3px solid #000;
			border-bottom: 3px solid #000;
		}
	}
	input[type=checkbox]:checked + .p-pdTopCheckItem:before {
		opacity: 1;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
	label {
		@media #{$querySp} {
			font-size: 1.4rem;
			margin-right: 20px;
		}
		@media #{$queryPc} {
			font-size: 1.6rem;
			margin-right: 20px;
		}
	}
}

.p-pdTopListWrap {
	width: 100%;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		overflow: hidden;
	}
}

.p-pdTopListInner {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		margin-right: -10px;
	}
}

.p-pdTopListItem {
	@media #{$querySp} {
		margin-bottom: 15px;
	}
	@media #{$queryPc} {
		float: left;
		margin: 0 10px 20px 0;
	}
}

.p-pdTopListItem__link {
	display: flex;
	color: #000;
	@media #{$querySp} {
		width: 100%;
	}
	@media #{$queryPc} {
		width: 320px;
		@include trans($duration: .2s,$delay: 0s);
	}
	&:hover {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			@include opacity(.5);
		}
	}
}

.p-pdTopListItem__photo {
	@media #{$querySp} {
		width: 33.333%;
	}
	@media #{$queryPc} {
		width: 129px;
		height: 90px;
	}
	img {
		width: 100%;
		height: auto;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
}

.p-pdTopListItem__txt {
	position: relative;
	display: flex;
	align-items: center;
	background-color: #fff;
	@media #{$querySp} {
		width: 66.666%;
		padding-left: 15px;
	}
	@media #{$queryPc} {
		width: 191px;
		padding-left: 5px;
	}
	span {
		@media #{$querySp} {
			line-height: 1.3;
		}
		@media #{$queryPc} {
			line-height: 1.4;
		}
	}
	&:after {
		content: "";
		position: absolute;
		bottom: 0;
		right: 0;
		@media #{$querySp} {
			width: 16px;
			height: 16px;
			background: url("../images/ico_arrow_r_wh.svg") no-repeat center center #000;
			background-size: 4px auto;
		}
		@media #{$queryPc} {
			width: 20px;
			height: 20px;
			background: url("../images/ico_arrow_r_wh.svg") no-repeat center center #000;
			background-size: 5px auto;
		}
	}
}

.p-pdTopList__more {
	@media #{$querySp} {
		margin-top: 30px;
	}
	@media #{$queryPc} {
		margin-top: 27px;
	}
	a {
		width: 100%;
		display: block;
		text-align: center;
		background-color: #999;
		color: #fff;
		@media #{$querySp} {
			padding: 8px;
			font-size: 1.4rem;
		}
		@media #{$queryPc} {
			padding: 13px;
			font-size: 1.8rem;
			@include trans($duration: .2s,$delay: 0s);
		}
		span {
			padding-right: 24px;
			background: url("../images/ico_arrow_b_wh.svg") no-repeat right center;
			background-size: 10px 6px;
			@media #{$querySp} {
			}
			@media #{$queryPc} {
			}
		}
		&:hover {
			@media #{$querySp} {
			}
			@media #{$queryPc} {
				@include opacity(.5);
			}
		}
	}
}

/* 製品詳細
-----------------------------------------------------------------*/
.p-pdItemTopCont {
	@media #{$querySp} {
		background-color: #efefef;
		padding: 15px;
	}
	@media #{$queryPc} {
		display: flex;
		flex-direction: row-reverse;
		align-items: center;
	}
}

.p-pdItemTopHeadBox {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		display: table;
		flex: 1;
		height: 272px;
		padding: 30px;
		background-color: #efefef;
	}
}

.p-pdItemTopHeadBox__inner {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		display: table-cell;
  		vertical-align: middle;
	}
}

.p-pdItemTopPhotoBox {
	@media #{$querySp} {
		margin-bottom: 20px;
	}
	@media #{$queryPc} {
	}
	img {
		@media #{$querySp} {
			width: 100%;
			height: auto;
		}
		@media #{$queryPc} {
		}
	}
}

.p-pdItemTop__head {
	font-weight: 600;
	border-bottom: 1px solid #000;
	@media #{$querySp} {
		padding-bottom: 8px;
		font-size: 2.0rem;
	}
	@media #{$queryPc} {
		padding-bottom: 16px;
		font-size: 3.0rem;
		line-height: 1.4;
	}
}

.p-pdItemTopTag {
	display: flex;
	flex-wrap: wrap;
	@media #{$querySp} {
		margin-top: 20px;
	}
	@media #{$queryPc} {
		margin-top: 30px;
	}
}

.p-pdItemTopTagItem {
	margin-right: 8px;
	background-color: #000;
	color: #fff;
	font-size: 1.2rem;
	@media #{$querySp} {
		padding: 4px 16px;
	}
	@media #{$queryPc} {
		padding: 8px 16px;
	}
}

.p-pdItemTable {
	width: 100%;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		border: 1px solid #ccc;
	}
	tr {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
		&:last-child {
			@media #{$querySp} {
			}
			@media #{$queryPc} {
			}
			th {
				@media #{$querySp} {
				}
				@media #{$queryPc} {
					border-bottom: 1px solid #ccc;
				}
			}
		}
	}
	th {
		font-weight: 600;
		background-color: #ccc;
		@media #{$querySp} {
			display: block;
			padding: 8px 16px;
		}
		@media #{$queryPc} {
			width: 152px;
			padding: 12px 16px;
			border-bottom: 1px solid #fff;
			text-align: center;
		}
	}
	td {
		@media #{$querySp} {
			display: block;
			padding: 12px 16px;
		}
		@media #{$queryPc} {
			padding: 12px 16px;
			border: 1px solid #ccc;
		}
	}
}

.c-container--pd {
    @media #{$querySp} {
    }
    @media #{$queryPc} {
    }
    .tblscroller {
            @media #{$querySp} {
                    font-size: 1.4rem;
                margin-top: 20px;
                    margin-bottom: 10px;
                }
                @media #{$queryPc} {
                    display: none;
                }
            img {
                @media #{$querySp} {
                    width: 22px;
                    position: relative;
                    top: -1px;
                }
                @media #{$queryPc} {
                    display: none;
                }
            }
        }
    .scroll-table {
            @media #{$querySp} {    
                overflow: scroll;
                -webkit-overflow-scrolling: touch;
            }
            @media #{$queryPc} {
                margin-top: 50px;
            }
            .table--scroller {
                @media #{$querySp} {
                    width: 1000px;
                    margin-bottom: 10px;
                }
                @media #{$queryPc} {
                    width: 100%;
                }
                tbody {
                    @media #{$querySp} {
                        width: 100%;
                          display:table;
                    }
                    @media #{$queryPc} {
                    }
                    tr {
                th {
                    color: #202020;
                    background: #eeeeee;
                    font-size: 1.6rem;
                    font-weight: normal;
                    text-align: center;
                    vertical-align: middle;
                    padding: 10px 20px;
                    width: 147px;
                    @media #{$querySp} {
                    }
                    @media #{$queryPc} {
                    }
                    p {
                        padding: 10px 15px;
                    }
                    &:first-child {
                        width: 25%;
                    }
                }
                    }
                td {
                        font-size: 1.6rem;
                        text-align: right;
                        vertical-align: top;
                        padding: 15px 20px;
                        border: 1px solid #ccc;
                    @media #{$querySp} {
                    	display: table-cell;
                    }
                    @media #{$queryPc} {
                    }
                    &:nth-child(2) {
                        @media #{$querySp} {
                        }
                        @media #{$queryPc} {
                        }
                    }
                    &:nth-child(3) {
                        @media #{$querySp} {
                        }
                        @media #{$queryPc} {
                        }
                    }
                    &.text-left {
                        text-align: left !important;
                    }
                }
            }
        }
    }
}

.p-pdListDlWrap {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		display: flex;
		flex-wrap: wrap;
	}
}

.p-pdListDlItem {
	@media #{$querySp} {
		margin-bottom: 20px;
	}
	@media #{$queryPc} {
		min-width: 384px;
		margin: 0 56px 32px 0;
	}
	&:last-child {
		@media #{$querySp} {
			margin-bottom: 0;
		}
		@media #{$queryPc} {
		}
	}
}

.p-pdListDlItem__link {
	display: block;
	text-align: center;
	font-weight: 600;
	color: #fff;
	@media #{$querySp} {
		padding: 16px;
		background: url("../images/ico_arrow_r_wh.svg") no-repeat right 12px center #a2a8da;
		background-size: 6px auto;
	}
	@media #{$queryPc} {
		padding: 16px;
		background: url("../images/ico_arrow_r_wh.svg") no-repeat right 24px center #a2a8da;
		background-size: 6px auto;
		@include trans($duration: .2s,$delay: 0s);
	}
	&:before {
		display: none !important;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
	span {
		position: relative;
		@media #{$querySp} {
			padding-left: 32px;
		}
		@media #{$queryPc} {
			padding-left: 32px;
		}
		&:before {
			content: "";
			display: inline-block;
			position: absolute;
		    top: 0;
			bottom: 0;
			left: 0;
			margin: auto 0;
			@media #{$querySp} {
				width: 18px;
			    height: 22px;
			    background: url("../images/ico_pdf_wh.png") no-repeat center top;
			    background-size: 18px auto;
			}
			@media #{$queryPc} {
			    width: 18px;
			    height: 22px;
			    background: url("../images/ico_pdf_wh.png") no-repeat center top;
			    background-size: 18px auto;
			    @include trans($duration: .2s,$delay: 0s);
			}
		}
	}
	&:hover {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			color: #fff;
			background: url("../images/ico_arrow_r_wh.svg") no-repeat right 24px center $clrKey;
			background-size: 6px auto;
		}
		span {
			@media #{$querySp} {
			}
			@media #{$queryPc} {
				padding-left: 32px;
			}
			&:before {
				@media #{$querySp} {
				}
				@media #{$queryPc} {
				    background: url("../images/ico_pdf_wh.png") no-repeat center top;
				    background-size: 18px auto;
				}
			}
		}
	}
}

.p-pdItemGrayArea {
	width: 100%;
	background-color: #efefef;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

.p-pdItemGrayInner {
	@media #{$querySp} {
		padding: 15px;
	}
	@media #{$queryPc} {
		width: 1000px;
		margin: 0 auto;
		padding: 40px 10px 40px;
	}
}

.p-pdItemContWrap {
	background-color: #fff;
	@media #{$querySp} {
		padding: 20px;
		margin-bottom: 15px;
	}
	@media #{$queryPc} {
		margin-bottom: 20px;
		padding: 32px 40px;
	}
}

.p-pdItemMoreWrap {
	background-color: #fff;
	@media #{$querySp} {
		padding: 20px;
	}
	@media #{$queryPc} {
		padding: 32px 40px;
	}
}

.p-pdItemContatWrap {
	border-bottom: 1px solid #000;
	@media #{$querySp} {
		margin-bottom: 10px;
	}
	@media #{$queryPc} {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-bottom: 20px;
	}
}

.p-pdItemContactItem {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		width: 47%;
	}
}

.p-pdItemContactHead {
    background: #000;
    font-weight: 600;
    color: #fff;
	@media #{$querySp} {
    	padding: 5px 20px;
    	font-size: 1.6rem;
	}
	@media #{$queryPc} {
    	padding: 5px 30px;
    	font-size: 1.8rem;
	}
}

.p-pdItemContactInner {
	@media #{$querySp} {
		padding: 15px;
	}
	@media #{$queryPc} {
		padding: 20px;
	}
}

.p-pdItemContactTel {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		margin-left: 20px;
	}
}

.p-pdItemContactTel__item {
	color: #000;
    font-weight: 600;
	@media #{$querySp} {
		font-size: 2.8rem;
	}
	@media #{$queryPc} {
		font-size: 3.4rem;
	}
	&:before {
		content: "";
    	display: inline-block;
		@media #{$querySp} {
			width: 18px;
    		height: 28px;
    		margin-right: 9px;
    		background: url("../images/company/purchase_policy/icon_tel.png") no-repeat center center;
    		background-size: 18px auto;
		}
		@media #{$queryPc} {
			width: 24px;
    		height: 32px;
    		margin-right: 10px;
    		background: url("../images/company/purchase_policy/icon_tel.png") no-repeat center top;
    		background-size: 24px auto;
		}
	}
}

.p-pdItemBtn {
	display: inline-block;
	font-weight: 600;
	color: #fff;
	@media #{$querySp} {
		width: 100%;
		background: url("../images/ico_arrow_r_wh.svg") no-repeat right 16px center #a2a8da;
		background-size: 6px auto;
		padding: 20px;
		font-size: 1.6rem;
	}
	@media #{$queryPc} {
		padding: 10px 30px;
		background: url("../images/ico_arrow_r_wh.svg") no-repeat right 16px center #a2a8da;
		background-size: 6px auto;
		@include trans($duration: .2s,$delay: 0s)
	}
	&:hover {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			@include opacity(.5);
		}
	}
}

.p-pdListHead03 {
	font-weight: 600;
	@media #{$querySp} {
		margin-bottom: 20px;
		font-size: 2.0rem;
	}
	@media #{$queryPc} {
		margin-bottom: 30px;
		font-size: 2.4rem;
	}
}

.p-pdListHead04 {
	font-weight: 600;
	@media #{$querySp} {
		margin: 16px 0;
		font-size: 1.8rem;
	}
	@media #{$queryPc} {
		margin: 24px 0;
		font-size: 2.0rem;
	}
}

.p-pdListHead05 {
	font-weight: 600;
	@media #{$querySp} {
		margin: 8px 0;
		font-size: 1.4rem;
	}
	@media #{$queryPc} {
		margin: 16px 0;
		font-size: 1.6rem;
	}
}

.p-pdTopMoreWrap__Head {
	text-align: center;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
	.p-pdTopMore__link {
		display: inline-block;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
}

.p-pdListBtnBackItem {
	text-align: center;
	@media #{$querySp} {
		margin-top: 40px;
		padding: 0 20px;
	}
	@media #{$queryPc} {
		margin-top: 80px;
	}
}

.p-pdListBtnBackItem__link {
	display: inline-block;
	font-weight: 600;
	color: #000;
	border: 1px solid #000;
	@media #{$querySp} {
		width: 100%;
		padding: 16px;
		font-size: 1.6rem;
		background: url("../images/ico_arrow_r_bk.svg") no-repeat right 16px center;
		background-size: 6px auto;
	}
	@media #{$queryPc} {
		width: 285px;
		padding: 16px;
		font-size: 1.8rem;
		background: url("../images/ico_arrow_r_bk.svg") no-repeat right 16px center;
		background-size: 6px auto;
		@include trans($duration: .2s,$delay: 0s)
	}
	&:hover {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			color: #fff;
			background: url("../images/ico_arrow_r_wh.svg") no-repeat right 16px center $clrKey;
			background-size: 6px auto;
		}
	}
}

.p-pdItem2colWrap {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		display: flex;
	}
}

.p-pdItem2col__left {
	@media #{$querySp} {
		margin-bottom: 10px;
	}
	@media #{$queryPc} {
	}
	&--w50 {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			width: 50%;
		}
	}
}

.p-pdItem2col__right {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
	&--w50 {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			width: 50%;
		}
	}
}















































































