@charset "UTF-8";
/* ----------------------------------------------------------------
研究開発のみのスタイル
-----------------------------------------------------------------*/
/* 部門長メッセージ
-----------------------------------------------------------------*/
.c-container--research {
    @media #{$querySp} {
    }
    @media #{$queryPc} {
    }
    .tblscroller {
            @media #{$querySp} {
                    font-size: 1.4rem;
                margin-top: 20px;
                    margin-bottom: 10px;
                }
                @media #{$queryPc} {
                    display: none;
                }
            img {
                @media #{$querySp} {
                    width: 22px;
                    position: relative;
                    top: -1px;
                }
                @media #{$queryPc} {
                    display: none;
                }
            }
        }
    .scroll-table {
            @media #{$querySp} {    
                overflow: scroll;
                      -webkit-overflow-scrolling: touch;
            }
            @media #{$queryPc} {
                margin-top: 50px;
            }
            
            .table--scroller {
                
                @media #{$querySp} {
                    width: 1000px;
                    margin-bottom: 10px;
                       
                      
                }
                @media #{$queryPc} {
                    width: 100%;
                }
                tbody {
                    @media #{$querySp} {
                        width: 100%;
                          display:table;
                    }
                    @media #{$queryPc} {
                    }
                    
                    tr {
                th {
                    color: #202020;
                    background: #eeeeee;
                    font-size: 1.6rem;
                    font-weight: normal;
                    text-align: center;
                    vertical-align: middle;
                    padding: 10px 20px;
                    width: 147px;
                    @media #{$querySp} {
                    }
                    @media #{$queryPc} {
                    }
                    &:nth-child(4) {
                        @media #{$querySp} {
                        }
                        @media #{$queryPc} {
                            width: 42%;
                        }
                    }
                    p {
                        padding: 10px 15px;
                    }
                    &:first-child {
                        /*width: 25%;*/
                    }
                    
                }
                    }
                td {
                    border-bottom: 1px solid #000;
                        font-size: 1.6rem;
                        text-align: right;
                        vertical-align: top;
                        padding: 15px 20px;
                    @media #{$querySp} {
                    }
                    @media #{$queryPc} {
                        
                    }
                    &:nth-child(2) {
                        @media #{$querySp} {
                        }
                        @media #{$queryPc} {
                        }
                        
                        
                    }
                    &:nth-child(3) {
                        @media #{$querySp} {
                        }
                        @media #{$queryPc} {
                        }
                    }
                    &.text-left {
                        text-align: left !important;
                    }
                }
            }
              
            }
        &.scroll-table--awards {
            th {
                text-align: left !important;
                width: auto !important;
            }
            td {
                text-align: left !important;
            }
        }
        }
}

