@charset "UTF-8";
/* ----------------------------------------------------------------
 ir/stock
-----------------------------------------------------------------*/
.c-container--ir {
    .c-inner--md {
        padding: 0;
        @media #{$querySp} {
            width: 95%;
            margin: 0 auto 40px;
        }
    }
    .c-inner--md.c-inner--980{
        padding: 0 10px;
    }
}

.wrap--ir {
    &.wrap--ir_stock {
        .wrap-stock_box {
            @media #{$querySp} {
                margin: 30px 0;
            }
            @media #{$queryPc} {
                margin: 60px 0;
            }
            &.col_1 {
                width: 100%;
            }
            &.col_2 {
                width: 100%;
                @media #{$querySp} {
                    > div {
                        width: 100%;
                    }
                }
                @media #{$queryPc} {
                    > div {
                        float: left;
                        width: 48%;
                        margin: 0 2% 0 0;
                        &:nth-child(2n)  {
                            margin: 0 0 0 2%;
                        }
                    }
                }
            }
        }
        .anothersite-link {
            @media #{$querySp} {
                width:100%;
            }
            @media #{$queryPc} {
                width:35%;
            }
        }
        table {
            @media #{$querySp} {
                width: 100%;
                border-bottom: 1px solid #cecece;
            }
            @media #{$queryPc} {
                border-bottom: 1px solid #cecece;
            }
            a {
                color: #ff5611;
                &:hover {
                    text-decoration: underline;
                }
            }
            &.tbl_type_01 {
                th {
                    color: #202020;
                    border-top: 1px solid #cecece;
                    @media #{$querySp} {
                        padding: 7px 10px 7px 10px;
                        /*width: 100%;*/
                    }
                    @media #{$queryPc} {
                        padding: 15px 40px 15px 40px;
                        width: 280px;
                    }
                    &.back_01 {
                        background-color: #eeeeee;
                    }
                }
                td {
                    color: #4A4A4A;
                    vertical-align: top;
                    border-top: 1px solid #cecece;
                    @media #{$querySp} {
                        padding: 7px 20px 7px 20px;
                        /*width: 100%;*/
                    }
                    @media #{$queryPc} {
                        padding: 15px 40px 15px 40px;
                    }
                }
            }
            &.tbl_type_02 {
                box-sizing: border-box;
                th {
                    border: 1px solid #cecece;
                    color: #202020;
                    background-color: #eeeeee;
                    text-align: center;
                    @media #{$querySp} {
                        padding: 7px 20px 7px 20px;
                    }
                    @media #{$queryPc} {
                        padding: 15px 40px 15px 40px;
                    }
                }
                td {
                    border: 1px solid #cecece;
                    color: #4A4A4A;
                    text-align: center;
                    &:first-child {
                        text-align: left;
                    }
                    @media #{$querySp} {
                        padding: 12.5px 20px 12.5px 20px;
                    }
                    @media #{$queryPc} {
                        padding: 25px 40px 25px 40px;
                    }
                    a {
                        position: relative;
                        color: #ff5611;
                        &:hover{
                            text-decoration: underline;
                        }
                        &.link {
                            @media #{$querySp} {
                                padding-right: 7px;
                                background: url(../images/ico_blank.svg) no-repeat right center;
                                background-size: 7px auto;
                            }
                            @media #{$queryPc} {
                                padding-right: 14px;
                                font-size: 1.8rem;
                                background: url(../images/ico_blank.svg) no-repeat right center;
                                background-size: 14px auto;
                            }
                        }
                    }
                }
            }
        }
        .wrap-tbl_txt {
            font-size: 0.8em;
            &.type_01 {
                padding: 0 0 0 40px;
                position: relative;
                span {
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
            @media #{$querySp} {
                margin: 10px auto;
            }
            @media #{$queryPc} {
                margin: 20px auto;
            }

        }
        .txt_date {
            text-align: right;
            @media #{$querySp} {
                margin: 10px auto;
            }
            @media #{$queryPc} {
                margin: 20px auto;
            }
        }
        ul.wrap-link_pdf {
            li {
                @media #{$querySp} {
                    margin-bottom: 10px;
                }
                @media #{$queryPc} {
                    margin-bottom: 20px;
                }
                a {
                    position: relative;
                    color: #000;
                    padding: 0 0 0 24px;
                    @media #{$querySp} {
                        width: 100%;
                        display:inline;
                    }
                    @media #{$queryPc} {
                        display: inline-block;
                    }
                    &:after{
                        width: 18px;
                        height: 22px;
                        content: "";
                        display: inline-block;
                        background: url("../images/ico_pdf.png") no-repeat center top;
                        background-size: 18px auto;
                        position: absolute;
                        left: 0px;
                        @media #{$querySp} {
                            top: 0%;
                        }
                        @media #{$queryPc} {
                            top: 14%;
                        }
                    }
                }
            }
        }
        .wrap-txt {
            &.type_01 {
                display: inline-block;
                border: 1px solid #000;
                span {
                    display: block;
                }
                a {
                    color: #ff5611;
                    &:hover {
                        text-decoration: underline;
                    }
                }
                @media #{$querySp} {
                    padding: 6px 9px;
                }
                @media #{$queryPc} {
                    padding: 12px 18px;
                }
            }
        }
        .wrap-date_pdf {
            p {
                a {
                    position: relative;
                    color: #ff5611;
                    padding: 0 0 0 24px;
                    &:hover {
                        text-decoration: underline;
                    }
                    @media #{$querySp} {
                        margin-left: 10px;
                    }
                    @media #{$queryPc} {
                        margin-left: 20px;
                    }
                    &:after {
                        position: absolute;
                        display: inline-block;
                        content: "";
                        background: url("../images/ico_pdf.png") no-repeat center top;
                        @media #{$querySp} {
                            left: 0;
                            top: 0;
                            width: 18px;
                            height: 16px;
                            padding: 0 0 4px 0;
                            background-size: 12px auto;
                        }
                        @media #{$queryPc} {
                            left: 0;
                            top: -2px;
                            width: 18px;
                            height: 22px;
                            padding: 0 0 8px 0;
                            background-size: 18px auto;
                        }
                    }
                }
            }
        }
        .wrap-btn {
            &.type_01{
                a {
                    background-color: #ADB0CE;
                    width:25%;
                    min-width: inherit;
                }
            }
        }
    }
    &.col_2 {
        justify-content: initial;
        .wrap-menu {
            @media #{$querySp} {
                margin-bottom: 30px;
            }
            @media #{$queryPc} {
                float: left;
                margin: 0 2% 0 0;
            }
        }
        :last-child.wrap-menu {
            margin: 0 0 0 0;
        }
    }
}
