@charset "UTF-8";
/* ----------------------------------------------------------------
すぐそこに！トクヤマの製品紹介のみのスタイル
-----------------------------------------------------------------*/
.p-pdsiteKvWrap {
	width: 100%;
	background: url("../images/feature/bg_pdsiteKv.jpg") no-repeat center center;
	background-size: cover;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

.p-pdsiteKvInner {
	@media #{$querySp} {
		padding: 200px 15px 100px;
	}
	@media #{$queryPc} {
		width: 1000px;
		margin: 0 auto;
		padding: 200px 0 140px;
	}
}

.p-pdsiteKvhead {
	line-height: 1;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

.p-pdsiteKvhead__bk {
	display: inline-block;
	background-color: #000;
	color: #fff;
	@media #{$querySp} {
		margin-bottom: 4px;
		font-size: 3.2rem;
	}
	@media #{$queryPc} {
		margin-bottom: 8px;
		font-size: 4.8rem;
	}
}

.p-pdsiteKvhead__wh {
	display: inline-block;
	background-color: #fff;
	color: #000;
	@media #{$querySp} {
		margin-bottom: 8px;
		font-size: 3.2rem;
	}
	@media #{$queryPc} {
		margin-bottom: 16px;
		font-size: 4.8rem;
	}
}

.p-pdsiteKv__txt {
	color: #fff;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.8);
	font-weight: 600;
	@media #{$querySp} {
		font-size: 1.4rem;
	}
	@media #{$queryPc} {
	}
}

.p-pdsiteCanWrap {
	width: 100%;
	background: url("../images/feature/bg_can.jpg") no-repeat center center;
	background-size: cover;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

.p-pdsiteCanInner {
	@media #{$querySp} {
		padding: 60px 15px 30px;
	}
	@media #{$queryPc} {
		width: 1000px;
		margin: 0 auto;
		padding: 120px 0 60px;
	}
}

.p-pdsiteTab {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		width: 180px;
	}
}

.p-pdTopMainContInner--pdsite {
	width: 100%;
	@media #{$querySp} {
		padding: 15px 0 0;
	}
	@media #{$queryPc} {
		padding: 21px 0px 0;
	}
}

.p-pdsiteItemWrap {
	width: 100%;
	background-color: #fff;
	@media #{$querySp} {
		padding: 15px;
	}
	@media #{$queryPc} {
		padding: 88px 0 0;
	}
}

.p-pdsiteItemInner {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		max-width: 1000px;
		margin: 0 auto;
		padding: 0 10px;
	}
}

.p-pdsite2col__left {
	border: 1px solid #000;
	@media #{$querySp} {
		padding: 20px 10px;
	}
	@media #{$queryPc} {
		width: 470px;
		margin-right: 40px;
		padding: 32px 12px;
	}
}

.p-pdsite2col__right {
	border: 1px solid #000;
	@media #{$querySp} {
		padding: 20px 10px;
	}
	@media #{$queryPc} {
		width: 470px;
		padding: 32px 12px;
	}
}

.p-pdsiteHistory {
	display: flex;
	@media #{$querySp} {
		margin-bottom: 4px;
	}
	@media #{$queryPc} {
		margin-bottom: 8px;
	}
	dt {
		width: 50px;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
	dd {
		flex: 1;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
}

.p-pdsitePLinkWrap {
	display: flex;
	@media #{$querySp} {
		flex-wrap: wrap;
		padding: 0 15px;
	}
	@media #{$queryPc} {
		width: 980px;
		margin: 0 auto;
	}
}

.p-pdsitePLinkItem {
	@media #{$querySp} {
		width: 50%;
		margin-bottom: 15px;
	}
	@media #{$queryPc} {
	}
	&:nth-child(even) {
		@media #{$querySp} {
			padding-left: 8px;
		}
		@media #{$queryPc} {
		}
	}
	&:nth-child(odd) {
		@media #{$querySp} {
			padding-right: 8px;
		}
		@media #{$queryPc} {
		}
	}
}

.p-pdsitePLinkItem__link {
	display: block;
	background-color: #fff;
	text-align: center;
	color: #000;
	@media #{$querySp} {
		padding: 15px;
		word-break: break-word;
	}
	@media #{$queryPc} {
		padding: 20px 19px 0 20px;
		@include trans($duration: .2s,$delay: 0s);
	}
	&:hover {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			@include opacity(.8);
		}
	}
	img {
		@media #{$querySp} {
			width: 100%;
			height: auto;
			margin-bottom: 4px;
		}
		@media #{$queryPc} {
			width: auto;
			height: 110px;
			margin-bottom: 8px;
		}
	}
}

.p-pdsite__hr {
	width: 100%;
	height: 1px;
	background-color: #ddd;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

.p-xxxxxxxx {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

.p-xxxxxxxx {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}
.p-xxxxxxxx {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}





















