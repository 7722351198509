@charset "UTF-8";
/* ----------------------------------------------------------------
CSRのみのスタイル
-----------------------------------------------------------------*/
/* CSRデータ
-----------------------------------------------------------------*/
.c-container--csr {
    @media #{$querySp} {
    }
    @media #{$queryPc} {
    }
    .c-inner--md {
        @media #{$querySp} {
            width: 95%;
            margin: 0 auto 50px;
        }
        @media #{$queryPc} {
            margin-bottom: 150px;
        }
        .section--csr {
            @media #{$querySp} {
                margin-bottom: 30px;
            }
            @media #{$queryPc} {
                margin-bottom: 50px;
            }
            .pdf {
                li {
                    position: relative;
                    a {
                        color: #de5b11;
                        @media #{$querySp} {
                            font-size: 1.2rem;
                            padding: 5px 0 5px 30px;
                        }
                        @media #{$queryPc} {
                            font-size: 1.6rem;
                            padding: 5px 0 5px 40px;
                        }
                        &:before {
                            @media #{$querySp} {
                                position: absolute;
                                left: 5px;
                                display: inline-block;
                                content: "";
                                width: 14px;
                                height: 18px;
                                background: url("../images/ico_pdf.png") no-repeat center center;
                                background-size: 14px auto;
                            }
                            @media #{$queryPc} {
                                position: absolute;
                                left: 10px;
                                display: inline-block;
                                content: "";
                                width: 18px;
                                height: 22px;
                                background: url("../images/ico_pdf.png") no-repeat center center;
                                background-size: 18px auto;
                            }
                        }
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }

}

