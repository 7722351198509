@charset "UTF-8";
/* ----------------------------------------------------------------
NEWS詳細のみのスタイル
-----------------------------------------------------------------*/
.p-newsDtailWrap {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

.p-newsDtailInner {
	border-bottom: 1px solid #000;
	@media #{$querySp} {
		padding: 16px 0;
	}
	@media #{$queryPc} {
		padding: 24px 80px;
	}
	p {
		@media #{$querySp} {
			margin: 16px 0;
			font-size: 1.3rem;
		}
		@media #{$queryPc} {
			margin: 24px 0;
			font-size: 1.6rem;
		}
	}
}

.p-newsDtail__headline01 {
	background-color: #eee;
	color: #000;
	@media #{$querySp} {
		padding: 6px 15px;
		font-size: 1.8rem;
	}
	@media #{$queryPc} {
		padding: 12px 80px;
		font-size: 2.4rem;
	}
}

.p-newsDtail__headline02 {
	@media #{$querySp} {
		margin: 4px 0;
		font-size: 1.3rem;
	}
	@media #{$queryPc} {
		margin: 8px 0;
		font-size: 1.6rem;
	}
}

.p-newsDtailBtnBack {
	text-align: center;
	@media #{$querySp} {
		margin-top: 40px;
	}
	@media #{$queryPc} {
		margin-top: 80px;
	}
}

.p-newsDtailBtnBack__link {
	display: inline-block;
	color: #000;
	border: 1px solid #000;
	letter-spacing: .1em;
	background: url("../images/ico_arrow_r_bk.svg") no-repeat right 14px center;
	background-size: 6px auto;
	@media #{$querySp} {
		width: 60%;
		padding: 8px;
		font-size: 1.6rem;
	}
	@media #{$queryPc} {
		width: 285px;
		padding: 16px;
		font-size: 1.8rem;
		@include trans($duration: .2s,$delay: 0s);
	}
	&:hover {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			background: url("../images/ico_arrow_r_wh.svg") no-repeat right 14px center $clrKey;
			background-size: 6px auto;
			color: #fff;
		}
	}
}











































