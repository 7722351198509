@charset "UTF-8";
/* ----------------------------------------------------------------
余白のコンポーネット
-----------------------------------------------------------------*/
.c-spacer--ss {
	@media #{$querySp} {
		/*margin-top: 16px !important;*/
		margin-bottom: 16px !important;
	}
	@media #{$queryPc} {
		/*margin-top: 24px !important;*/
		margin-bottom: 24px !important;
	}
}

.c-spacer--s {
	@media #{$querySp} {
		/*margin-top: 20px !important;*/
		margin-bottom: 20px;
	}
	@media #{$queryPc} {
		/*margin-top: 48px !important;*/
		margin-bottom: 48px !important;
	}
}

.c-spacer--m {
	@media #{$querySp} {
		/*margin-top: 40px !important;*/
		margin-bottom: 40px;
	}
	@media #{$queryPc} {
		/*margin-top: 64px !important;*/
		margin-bottom: 64px !important;
	}
}

.c-spacer--l {
	@media #{$querySp} {
		/*margin-top: 40px !important;*/
		margin-bottom: 40px !important;
	}
	@media #{$queryPc} {
		/*margin-top: 72px !important;*/
		margin-bottom: 72px !important;
	}
}

.c-spacer--ll {
	@media #{$querySp} {
		/*margin-top: 40px !important;*/
		margin-bottom: 64px !important;
	}
	@media #{$queryPc} {
		/*margin-top: 72px !important;*/
		margin-bottom: 88px !important;
	}
}