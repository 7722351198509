@charset "UTF-8";
/*----------------------------------------------------------------
リストのコンポーネント
・ドットリスト
・番号リスト
・アルファベットリスト
-----------------------------------------------------------------*/

//ドットリスト
.c-list > li {
	padding-left: 1em;
	position: relative;
	z-index: 1;
	&:before {
		content: "";
		width: .5em;
		height: .5em;
		border-radius: 100%;
		position: absolute;
		z-index: 1;
		top: .5em;
		left: 0;
		font-weight: bold;
		background: #{$clrAccent};
	}
}

//番号リスト
.c-listOreder {
	counter-reset: number 0;
}

.c-listOreder > li {
	padding-left: 1em;
	position: relative;
	z-index: 1;
	&:nth-child(n+10) {
		padding-left: 1.5em;
	}
	&:before {
		content: counter(number) ".";
		counter-increment: number 1;
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
	}
}

//アルファベット
.c-listAlphabet {
	counter-reset: alphabet;
}

.c-listAlphabet > li {
	padding-left: 2em;
	position: relative;
	z-index: 1;
	&:before {
		content: "(" counter(alphabet, lower-latin) "):";
		counter-increment: alphabet;
		top: 0;
		left: 0;
		position: absolute;
		z-index: 1;
	}
}

/*----------------------------------------------------------------
リストのコンポーネント
・ドットリスト
・番号リスト
・アルファベットリスト
-----------------------------------------------------------------*/
.c-list,
.c-listOreder,
.c-listCheck {
	@media #{$querySp} {
		margin: 16px 0;
		line-height: 1.3;
	}
	@media #{$queryPc} {
		margin: 24px 0;
		line-height: 1.5;
	}
}

.c-list > li,
.c-listOreder > li,
.c-listCheck > li {
	padding-left: 16px;
	line-height: 1.5;
	margin-bottom: 12px;
}

//ドットリスト
.c-list > li {
	position: relative;
	&:before {
		display: block;
		content: '';
		position: absolute;
		top: 0.5em;
		left: 1px;
		width: 4px;
		height: 4px;
		background-color: $clrBlck;
		border-radius: 100%;
	}
}

//番号リスト
.c-listOreder {
	counter-reset: li;
}

.c-listOreder > li {
	&:nth-child(n+10) {
		padding-left: 1.8em;
	}
	&:before {
		content:counter(li) ". ";
		counter-increment: li;
		font-weight: 600;
		color: $clrBlck;
	}
}

//チェックリスト
.c-listCheck > li {
	position: relative;
	&:before {
		display: block;
		content: '';
		position: absolute;
		top: 0.5em;
		left: 0;
		width: 9px;
		height: 5px;
		border-left: 2px solid $clrBlck;
		border-bottom: 2px solid $clrBlck;
		-webkit-transform: rotate(-45deg);
		transform: rotate(-45deg);
	}
}

//アルファベット
.c-listAlphabet {
}

.c-listAlphabet > li {
	&:before {
	}
}