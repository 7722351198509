@charset "UTF-8";
/* ----------------------------------------------------------------
TOPページのみのスタイル
-----------------------------------------------------------------*/
/* 共通パーツ
-----------------------------------------------------------------*/
.p-homeNewsPdWrap {
	@media #{$querySp} {
		position: relative;
		width: 100%;
		background: url("../images/bg_homePd.png") no-repeat center top 130px;
	}
	@media #{$queryPc} {
	}
}

.p-homePdWrap {
	position: relative;
	width: 100%;
	background-color: #f0f0f0;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
	&:before {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 1400px 400px 0;
			border-color: transparent #fff transparent transparent;
			z-index: 0;
		}
	}
	&:after {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 400px 0 0px 1400px;
			border-color: transparent transparent transparent #fff;
			z-index: 0;
		}
	}
}

.p-homeContWrap {
	width: 100%;
	position: relative;
	z-index: 1;
	@media #{$querySp} {
		padding-bottom: 80px;
	}
	@media #{$queryPc} {
		padding-bottom: 160px;
	}
	&--news {
		@media #{$querySp} {
			padding-top: 50px;
		}
		@media #{$queryPc} {
			padding: 56px 0 32px;
		}
	}
	&--pd {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			padding-top: 90px;
		}
	}
	&--about {
		@media #{$querySp} {
			padding-top: 64px;
		}
		@media #{$queryPc} {
		}
	}
}

.p-homeContInner {
	@media #{$querySp} {
		padding: 0 15px;
	}
	@media #{$queryPc} {
		width: 100%;
		max-width: 1310px;
		min-width: $containerWidthSmall;
		margin: 0 auto;
		padding: 0 40px;
	}
}

.p-homeHeadline {
	text-align: center;
	line-height: 1;
	@media #{$querySp} {
		margin-bottom: 32px;
	}
	@media #{$queryPc} {
		margin-bottom: 56px;
	}
	.en {
		display: inline-block;
		letter-spacing: .25em;
		@media #{$querySp} {
			margin-bottom: 8px;
			font-size: 3.0rem;
		}
		@media #{$queryPc} {
			margin-bottom: 12px;
			font-size: 3.6rem;
		}
	}
	.jp {
		display: inline-block;
		letter-spacing: .15em;
		@media #{$querySp} {
			font-size: 1.2rem;
		}
		@media #{$queryPc} {
			font-size: 1.6rem;
		}
	}
	&--news {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			margin-bottom: 0;
		}
	}
	&--pd {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
		.en {
			@media #{$querySp} {
			}
			@media #{$queryPc} {
			}
			&:before {
				content: "";
				display: inline-block;
				@media #{$querySp} {
					width: 26px;
					height: 26px;
					background: url("../images/pic_pd.svg") no-repeat center center;
					background-size: 26px auto;
					margin: 0 12px 0;
				}
				@media #{$queryPc} {
					width: 44px;
					height: 43px;
					background: url("../images/pic_pd.svg") no-repeat center center;
					background-size: 44px auto;
					margin: 0 18px 0;
				}
			}
		}
	}
	&--about {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
		.en {
			@media #{$querySp} {
			}
			@media #{$queryPc} {
			}
			&:before {
				content: "";
				display: inline-block;
				@media #{$querySp} {
					width: 25px;
					height: 29px;
					background: url("../images/pic_about.png") no-repeat center center;
					background-size: 25px auto;
					margin: 0 12px -2px;
				}
				@media #{$queryPc} {
					width: 36px;
					height: 42px;
					background: url("../images/pic_about.png") no-repeat center center;
					background-size: 36px auto;
					margin: 0 18px -2px;
				}
			}
		}
	}
	&--cont {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
		.en {
			@media #{$querySp} {
			}
			@media #{$queryPc} {
			}
			&:before {
				content: "";
				display: inline-block;
				@media #{$querySp} {
					width: 30px;
					height: 28px;
					background: url("../images/pic_cont.png") no-repeat center center;
					background-size: 30px auto;
					margin: 0 12px -2px;
				}
				@media #{$queryPc} {
					width: 44px;
					height: 40px;
					background: url("../images/pic_cont.png") no-repeat center center;
					background-size: 44px auto;
					margin: 0 18px -2px;
				}
			}
		}
	}
}

/* KV
-----------------------------------------------------------------*/
.p-homeKvWrap {
	position: relative;
	@media #{$querySp} {
		padding-top: 70px;
	}
	@media #{$queryPc} {
		padding: 120px 20px 0;
	}
}

.p-homeKv {
	position: relative;
	width: 100%;
	@media #{$querySp} {
		margin-bottom: 25px;
		padding-top: 100%;
		background: url("../images/kv_sp.jpg") no-repeat center top;
		background-size: cover;
	}
	@media #{$queryPc} {
		padding-top: 46.204%;
		background: url("../images/kv_pc.jpg") no-repeat center top;
		background-size: cover;
	}
}

.p-homeKvBtnWrap {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		position: absolute;
		bottom: 80px;
		right: 80px;
	}
}

.p-homeKvBtn {
	position: relative;
	@media #{$querySp} {
		width: 60%;
		margin: 0 auto;
	}
	@media #{$queryPc} {
		width: 162px;
		height: 162px;
		background-color: $clrKey;
		border-radius: 50%;
	}
}

.p-homeKvBtnItem {
	color: #fff;
	@media #{$querySp} {
		display: block;
		padding: 10px;
		background-color: $clrKey;
		border: 2px solid #fff;
		text-align: center;
		line-height: 1;
	}
	@media #{$queryPc} {
		display: inline-block;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		width: 154px;
		height: 154px;
		background-color: $clrKey;
		border-radius: 50%;
		border: 2px solid #fff;
		text-align: center;
		@include trans($duration: .2s,$delay: 0s);
	}
	.en {
		display: inline-block;
		font-family: 'Questrial', sans-serif;
		font-weight: 600;
		@media #{$querySp} {
			margin-bottom: 5px;
			font-size: 1.2rem;
			letter-spacing: .1em;
		}
		@media #{$queryPc} {
			position: absolute;
			top: 33%;
			left: 0;
			right: 0;
			margin: 0 auto;
			transform: translateY(-33%);
			font-size: 1.2rem;
			letter-spacing: .1em;
		}
	}
	&:before {
		@media #{$querySp} {
			content: "";
			width: 8px;
			height: 14px;
			background: url("../images/ico_arrow_r_wh.svg") no-repeat center center;
			background-size: 8px auto;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 17px;
			margin: auto 0;
		}
		@media #{$queryPc} {
			content: "";
			width: 8px;
			height: 14px;
			background: url("../images/ico_arrow_r_wh.svg") no-repeat center center;
			background-size: 8px auto;
			position: absolute;
			bottom: 20%;
			left: 46%;
		}
	}
	&:after {
		@media #{$querySp} {
			content: "";
			width: 6px;
			height: 10px;
			background: url("../images/ico_arrow_r_wh.svg") no-repeat center center;
			background-size: 6px auto;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 10px;
			margin: auto 0;
		}
		@media #{$queryPc} {
			content: "";
			width: 6px;
			height: 10px;
			background: url("../images/ico_arrow_r_wh.svg") no-repeat center center;
			background-size: 6px auto;
			position: absolute;
			bottom: 21%;
			left: 52%;
		}
	}
	.jp {
		font-weight: 600;
		@media #{$querySp} {
			font-size: 1.7rem;
			letter-spacing: .2em;
		}
		@media #{$queryPc} {
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			margin: 0 auto;
			transform: translateY(-50%);
			font-size: 1.8rem;
			letter-spacing: .1em;
		}
	}
	&:hover {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			@include opacity(.5);
		}
	}
}

/* NEWS
-----------------------------------------------------------------*/
.p-homeNewsContWrap {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

.p-homeNewsHead {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		padding-left: 2.5%;
	}
}

.p-homeNewsArea {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		flex: 1;
		padding: 0 2% 0 7%;
	}
}

.p-homeNewsList {
	display: flex;
	@media #{$querySp} {
		flex-wrap: wrap;
		margin-bottom: 16px;
		padding-bottom: 16px;
		border-bottom: 1px solid #000;
	}
	@media #{$queryPc} {
		align-items: center;
		margin-bottom: 16px;
	}
	&:last-child {
		@media #{$querySp} {
			border-bottom: none;
		}
		@media #{$queryPc} {
		}
	}
}

.p-homeNews__date {
	@media #{$querySp} {
		width: 29.536%;
	}
	@media #{$queryPc} {
		font-size: 1.5rem;
		margin-right: 5%;
	}
}

.p-homeNews__tag {
	@media #{$querySp} {
		width: 70.463%;
	}
	@media #{$queryPc} {
	}
	span {
		display: block;
		text-align: center;
		color: #fff;
		line-height: 1;
		@media #{$querySp} {
			width: 82px;
			padding: 3px;
		}
		@media #{$queryPc} {
			width: 99px;
			padding: 6px;
			font-size: 1.3rem;
		}
	}
	.release {
		background-color: #c19ac1;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
	.sds {
		background-color: #dc9898;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
	.ir {
		background-color: #81b5c6;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
	.csr {
		background-color: #7fbe98;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
	.exhibition {
		background-color: #c6bd59;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
	.other {
		background-color: #999;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
}

.p-homeNews__txt {
	@media #{$querySp} {
		width: 100%;
		padding-top: 10px;
		font-size: 1.3rem;
	}
	@media #{$queryPc} {
		flex: 1;
		padding-left: 2.3%;
	}
	a {
		position: relative;
		padding-right: 24px;
		color: #000;
		@media #{$querySp} {
			background: url("../images/ico_arrow_r_bk.svg") no-repeat right center;
			background-size: 6px auto;
		}
		@media #{$queryPc} {
			background: url("../images/ico_arrow_r_bk.svg") no-repeat right center;
			background-size: 8px auto;
			font-size: 1.5rem;
			@include trans($duration: .1s,$delay: 0s);
		}
		/*&:after {
			content: "";
			display: inline-block;
			@media #{$querySp} {
				margin-left: 8px;
				width: 6px;
				height: 6px;
				border: 0;
				border-top: solid 1px #000;
  				border-right: solid 1px #000;
  				transform: rotate(45deg);
			}
			@media #{$queryPc} {
				margin-left: 16px;
				width: 8px;
				height: 8px;
				border: 0;
				border-top: solid 2px #000;
  				border-right: solid 2px #000;
  				transform: rotate(45deg);
			}
		}*/
		&:hover {
			@media #{$querySp} {
			}
			@media #{$queryPc} {
				color: $clrKey;
				text-decoration: underline;
			}
		}
	}
}

.p-homeNewsBtn {
	@media #{$querySp} {
		text-align: right;
	}
	@media #{$queryPc} {
		align-self: flex-start;
	}
}

.p-homeNewsBtn__link {
	display: inline-block;
	background-color: $clrKey;
	color: #fff;
	font-weight: 600;
	@media #{$querySp} {
		padding: 2px 10px;
	}
	@media #{$queryPc} {
		padding: 5px 24px;
		@include trans($duration: .2s,$delay: 0s);
	}
	span {
		padding-right: 16px;
		background: url("../images/ico_arrow_r_wh.svg") no-repeat right center;
		background-size: 4px auto;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
	&:hover {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			@include opacity(.5);
		}
	}
}

/* PRODUCTS
-----------------------------------------------------------------*/
.p-homePdListWrap {
	position: relative;
	@media #{$querySp} {
		margin-bottom: 32px;
	}
	@media #{$queryPc} {
		display: flex;
		margin-bottom: 56px;
	}
	&--rev {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			flex-direction: row-reverse;
		}
	}
}

.p-homePdListPhoto {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		width: 48.671%;
	}
	img {
		width: 100%;
		height: auto;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
}

.p-homePdListTxt {
	background-color: #fff;
	@media #{$querySp} {
		padding: 15px 10px 15px 15px;
	}
	@media #{$queryPc} {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 0;
		width: 53.593%;
		padding: 30px 72px;
	}
	&--rev {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			left: 0;
		}
	}
}

.p-homePdListTxt__box {
	@media #{$querySp} {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	@media #{$queryPc} {
	}
}

.p-homePdListTxt__head {
	line-height: 1;
	vertical-align: sub;
	@media #{$querySp} {
		font-size: 1.7rem;
		letter-spacing: .1em;
	}
	@media #{$queryPc} {
		margin-bottom: 16px;
		padding-bottom: 20px;
		border-bottom: 1px solid #000;
		font-size: 2.6rem;
		letter-spacing: .15em;
	}
	&--chemi {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
		&:before {
			content: "";
			display: inline-block;
			@media #{$querySp} {
				width: 18px;
				height: 28px;
				margin-right: 9px;
				background: url("../images/pic_chemi.png") no-repeat center top;
				background-size: 18px auto;
			}
			@media #{$queryPc} {
				width: 31px;
				height: 45px;
				margin-right: 12px;
				background: url("../images/pic_chemi.png") no-repeat center top;
				background-size: 31px auto;
			}
		}
	}
	&--ele {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
		&:before {
			content: "";
			display: inline-block;
			@media #{$querySp} {
				width: 27px;
				height: 27px;
				margin-right: 9px;
				background: url("../images/pic_ele.png") no-repeat center top;
				background-size: 27px auto;
			}
			@media #{$queryPc} {
				width: 50px;
				height: 50px;
				margin-right: 12px;
				background: url("../images/pic_ele.png") no-repeat center top;
				background-size: 50px auto;
			}
		}
	}
	&--buil {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
		&:before {
			content: "";
			display: inline-block;
			@media #{$querySp} {
				width: 22px;
				height: 26px;
				margin-right: 9px;
				background: url("../images/pic_buil.png") no-repeat center top;
				background-size: 22px auto;
			}
			@media #{$queryPc} {
				width: 40px;
				height: 47px;
				margin-right: 12px;
				background: url("../images/pic_buil.png") no-repeat center top;
				background-size: 40px auto;
			}
		}
	}
	&--health {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
		&:before {
			content: "";
			display: inline-block;
			@media #{$querySp} {
				width: 27px;
				height: 25px;
				margin-right: 9px;
				background: url("../images/pic_health.png") no-repeat center top;
				background-size: 27px auto;
			}
			@media #{$queryPc} {
				width: 50px;
				height: 46px;
				margin-right: 12px;
				background: url("../images/pic_health.png") no-repeat center top;
				background-size: 50px auto;
			}
		}
	}
	&--env {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
		&:before {
			content: "";
			display: inline-block;
			@media #{$querySp} {
				width: 21px;
				height: 26px;
				margin-right: 9px;
				background: url("../images/pic_env.png") no-repeat center top;
				background-size: 21px auto;
			}
			@media #{$queryPc} {
				width: 38px;
				height: 48px;
				margin-right: 12px;
				background: url("../images/pic_env.png") no-repeat center top;
				background-size: 38px auto;
			}
		}
	}
}

.p-homePdListTxt__more {
	color: #000;
	@media #{$querySp} {
		font-size: 1.1rem;
	}
	@media #{$queryPc} {
		@include trans($duration: .1s,$delay: 0s);
	}
	&:after {
		content: "";
		display: inline-block;
		width: 4px;
		padding-left: 16px;
		background: url("../images/ico_arrow_r_bk.svg") no-repeat center top;
		background-size: 4px auto;
		@media #{$querySp} {
			height: 7px;
		}
		@media #{$queryPc} {
			height: 8px;
		}
	}
	&:hover {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			text-decoration: underline;
			color: $clrKey;
		}
	}
}

.p-homePdSearchWrap {
	@media #{$querySp} {
		padding-top: 30px;
	}
	@media #{$queryPc} {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.p-homePdSearch__head {
	font-weight: 600;
	@media #{$querySp} {
		margin-bottom: 20px;
		text-align: center;
		font-size: 1.8rem;
	}
	@media #{$queryPc} {
		padding-right: 28px;
		font-size: 2.2rem;
	}
}

.p-homePdSearch__input {
	@media #{$querySp} {
		padding: 0 15px;
	}
	@media #{$queryPc} {
	}
	form {
		@media #{$querySp} {
			display: flex;
			justify-content: space-between;
		}
		@media #{$queryPc} {
		}
	}
}

.search_container input[type="text"]{
	@media #{$querySp} {
		width: 82.324%;
		height: 40px;
		padding: 8px 16px;
		border: 1px solid #999;
		background-color: #fff;
		overflow: hidden;
		font-size: 1.4rem;
	}
	@media #{$queryPc} {
		width: 365px;
		height: 48px;
		padding: 8px 16px;
		border: 1px solid #999;
		background-color: #fff;
		overflow: hidden;
		font-size: 1.8rem;
	}
}

.search_container input[type="text"]:focus {
	outline: 0;
	@media #{$querySp} {
		height: 40px;
	}
	@media #{$queryPc} {
  		height: 48px;
	}
}

.search_container input[type="submit"]{
	@media #{$querySp} {
		display: inline-block;
		width: 15.605%;
		height: 40px;
		background: url("../images/pic_search.png") no-repeat center center #fff;
		background-size: 23px auto;
		border: 1px solid #999;
		outline : none;
		vertical-align: middle;
		cursor: pointer;
	}
	@media #{$queryPc} {
		display: inline-block;
		width: 48px;
		height: 48px;
		background: url("../images/pic_search.png") no-repeat center center;
		background-size: 26px auto;
		outline : none;
		vertical-align: middle;
		cursor: pointer;
		@include trans($duration: .2s,$delay: 0s);
	}
	&:hover {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			@include opacity(.5);
		}
	}
}


/* ABOUT
-----------------------------------------------------------------*/
.p-homeAboutTopBox {
	@media #{$querySp} {
		margin-bottom: 50px;
	}
	@media #{$queryPc} {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 45px;
	}
}

.p-homeAboutTopBox__l {
	@media #{$querySp} {
		margin-bottom: 28px;
	}
	@media #{$queryPc} {
		width: 55.781%;
	}
	img {
		width: 100%;
		height: auto;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
}

.p-homeAboutTopBox__r {
	@media #{$querySp} {
		padding: 0 15px;
	}
	@media #{$queryPc} {
		width: 44.14%;
		padding-left: 4.14%;
	}
}

.p-homeAbout__lead {
	font-weight: 600;
	line-height: 1.4;
	letter-spacing: .18em;
	@media #{$querySp} {
		margin-bottom: 28px;
		font-size: 1.8rem;
	}
	@media #{$queryPc} {
		margin-bottom: 32px;
		font-size: 2.2rem;
	}
}

.p-homeAbout__txt {
	letter-spacing: .2em;
	@media #{$querySp} {
		margin-bottom: 32px;
		line-height: 1.7;
	}
	@media #{$queryPc} {
		margin-bottom: 48px;
	}
}

.p-homeBtnWrap {
	@media #{$querySp} {
		text-align: center;
	}
	@media #{$queryPc} {
	}
}

.p-homeBtnItem {
	display: inline-block;
	border: 1px solid #000;
	background: url("../images/ico_arrow_r_bk.svg") no-repeat right 16px center;
	background-size: 6px auto;
	text-align: center;
	font-size: 1.8rem;
	font-weight: 600;
	letter-spacing: .2em;
	color: #000;
	@media #{$querySp} {
		margin: 0 auto;
		padding: 10px 32px;
	}
	@media #{$queryPc} {
		width: 285px;
		padding: 16px;
		@include trans($duration: .2s,$delay: 0s);
	}
	&:hover {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			background: url("../images/ico_arrow_r_wh.svg") no-repeat right 16px center $clrKey;
			background-size: 6px auto;
			color: #fff;
		}
	}
}

.p-homeAboutListWrap {
	display: flex;
	@media #{$querySp} {
		flex-wrap: wrap;
	}
	@media #{$queryPc} {
		justify-content: space-between;
	}
}

.p-homeAboutListBox {
	@media #{$querySp} {
		width: 50%;
		margin-bottom: 24px;
	}
	@media #{$queryPc} {
		width: 24.062%;
		text-align: center;
	}
	&:nth-child(odd) {
		@media #{$querySp} {
			padding-right: 10px;
		}
		@media #{$queryPc} {
		}
	}
	&:nth-child(even) {
		@media #{$querySp} {
			padding-left: 10px;
		}
		@media #{$queryPc} {
		}
	}
	&:nth-last-child(-n+2) {
		@media #{$querySp} {
			margin-bottom: 0;
		}
		@media #{$queryPc} {
		}
	}
}

.p-homeAboutList__link {
	display: block;
	color: #000;
	@media #{$querySp} {
		padding-bottom: 14px;
		background-color: #f0f0f0;
	}
	@media #{$queryPc} {
		@include trans($duration: .2s,$delay: 0s);
	}
	&:hover {
		@include opacity(.5);
	}
}

.p-homeAboutList__photo {
	@media #{$querySp} {
		width: 100%;
		margin-bottom: 14px;
		padding-top: 54.925%;
	}
	@media #{$queryPc} {
		width: 100%;
		margin-bottom: 20px;
		padding-top: 80.582%;
	}
	&--mes {
		background: url("../images/photo_homeAbout_message.jpg") no-repeat top center;
		background-size: cover;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
	&--com {
		@media #{$querySp} {
			background: url("../images/photo_homeAbout_company.jpg") no-repeat center center;
			background-size: cover;
		}
		@media #{$queryPc} {
			background: url("../images/photo_homeAbout_company.jpg") no-repeat top center;
			background-size: cover;
		}
	}
	&--vis {
		background: url("../images/photo_homeAbout_vision.jpg") no-repeat top center;
		background-size: cover;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
	&--bus {
		background: url("../images/photo_homeAbout_business.jpg") no-repeat top center;
		background-size: cover;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
}

.p-homeAboutList__head {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		display: inline-block;
		line-height: 1;
	}
	.en {
		letter-spacing: .1em;
		@media #{$querySp} {
			display: block;
			font-size: 2.0rem;
			text-align: center;
		}
		@media #{$queryPc} {
			display: inline-block;
			margin-right: 4px;
			font-size: 2.2rem;
		}
		&:after {
			@media #{$querySp} {
				display: inline-block;
				content: "";
				width: 6px;
				height: 12px;
				margin-left: 10px;
				background: url("../images/ico_arrow_r_bk.svg") no-repeat top center;
				background-size: 6px auto;
			}
			@media #{$queryPc} {
			}
		}
	}
	.jp {
		@media #{$querySp} {
			display: block;
			text-align: center;
		}
		@media #{$queryPc} {
			position: relative;
			font-size: 1.2rem;
		}
		&:after {
			@media #{$querySp} {
			}
			@media #{$queryPc} {
				display: inline-block;
				content: "";
				width: 4px;
				height: 8px;
				margin-left: 10px;
				background: url("../images/ico_arrow_r_bk.svg") no-repeat center center;
				background-size: 4px auto;
			}
		}
	}
}

/* CONTENTS
-----------------------------------------------------------------*/
.p-homeContSlide__link {
	display: block;
	color: #000;
	text-align: center;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		@include trans($duration: .2s,$delay: 0s);
	}
	&:hover {
		@include opacity(.5);
	}
}

.p-homeContSlide__txt {
	display: inline-block;
	@media #{$querySp} {
		margin-top: 12px;
		font-size: 1.4rem;
	}
	@media #{$queryPc} {
		margin-top: 8px;
		font-size: 1.8rem;
	}
}

/* スライダーカスタマイズ
-----------------------------------------------------------------*/
.p-swiperWrap--top {
	@media #{$querySp} {
		position: relative;
	}
	@media #{$queryPc} {
	}
}

.p-swiperWrap--pd {
	position: relative;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

.swiper-container--top {
	@media #{$querySp} {
		width: 69.066% !important;
		margin: 0 auto;
	}
	@media #{$queryPc} {
	}
}

.swiper-container--pd {
	margin: 0 auto;
	@media #{$querySp} {
		width: 69.066% !important;
	}
	@media #{$queryPc} {
		width: 90%;
	}
}

.swiper-wrapper--top {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		display: flex;
		justify-content: space-between;
	}
}

.swiper-slide--top {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		width: 23.359%;
	}
}

.swiper-wrapper--pd {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

.swiper-slide--pd {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

.p-homeContSlide__photo {
	display: block;
	width: 100%;
	@media #{$querySp} {
		padding-top: 67.181%;
	}
	@media #{$queryPc} {
		padding-top: 69.23%;
	}
	&--slide01 {
		background: url("../images/photo_homeCont_01.jpg") no-repeat center center;
		background-size: cover;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
	&--slide02 {
		background: url("../images/photo_homeCont_02.jpg") no-repeat center center;
		background-size: cover;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
	&--slide02_en {
		background: url("../eng/images/person_1.jpg") no-repeat center center;
		background-size: cover;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
	&--slide03 {
		background: url("../images/photo_homeCont_03.jpg") no-repeat center center;
		background-size: cover;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
	&--slide04 {
		background: url("../images/photo_homeCont_04.jpg") no-repeat center center;
		background-size: cover;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
}

.swiper-button-prev,
.swiper-button-next {
	width: 33px;
	height: 33px;
	border: 1px solid #000;
	border-radius: 50%;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

.p-swiperWrap--top {
	.swiper-button-prev,
	.swiper-button-next {
		width: 33px;
		height: 33px;
		border: 1px solid #000;
		border-radius: 50%;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			display: none;
		}
	}
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
	left: 0;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
	right: 0;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
	content: "";
	display: block;
	width: 6px;
	height: 10px;
	background: url("../images/ico_arrow_r_bk.svg") no-repeat center top;
	background-size: 6px 10px;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
	content: "";
	display: block;
	width: 6px;
	height: 10px;
	background: url("../images/ico_arrow_l_bk.svg") no-repeat center top;
	background-size: 6px 10px;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

.swiper-button-disabled {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		display: none;
	}
}











.p-xxxxxxxx {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}





















