@charset "UTF-8";
/*----------------------------------------------------------------
ボタンのコンポーネント
-----------------------------------------------------------------*/
.c-btn {
	display: inline-block;
	border: 1px solid #000;
	text-align: center;
	font-weight: 600;
	color: #000;
	@media #{$querySp} {
		padding: 16px 24px 16px 16px;
		background: url("../images/ico_arrow_r_bk.svg") no-repeat right 8px center;
		background-size: 6px auto;
		line-height: 1.2;
	}
	@media #{$queryPc} {
		padding: 16px 32px 16px 16px;
		background: url("../images/ico_arrow_r_bk.svg") no-repeat right 16px center;
		background-size: 6px auto;
		line-height: 1.4;
		@include trans($duration: .2s,$delay: 0s);
	}
	&:before {
		display: none !important;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
	&:hover {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			color: #fff;
			background: url("../images/ico_arrow_r_wh.svg") no-repeat right 16px center $clrKey;
			background-size: 6px auto;
		}
		span {
			@media #{$querySp} {
			}
			@media #{$queryPc} {
				padding-left: 32px;
			}
			&:before {
				@media #{$querySp} {
				}
				@media #{$queryPc} {
				    background: url("../images/ico_pdf_wh.png") no-repeat center top;
				    background-size: 18px auto;
				}
			}
		}
	}
	.pdf {
		position: relative;
		@media #{$querySp} {
			padding-left: 32px;
		}
		@media #{$queryPc} {
			padding-left: 32px;
		}
		&:before {
			content: "";
			display: inline-block;
			position: absolute;
		    bottom: -2px;
			left: 0;
			@media #{$querySp} {
				width: 18px;
			    height: 22px;
			    background: url("../images/ico_pdf.png") no-repeat center top;
			    background-size: 18px auto;
			}
			@media #{$queryPc} {
			    width: 18px;
			    height: 22px;
			    background: url("../images/ico_pdf.png") no-repeat center top;
			    background-size: 18px auto;
			    @include trans($duration: .2s,$delay: 0s);
			}
		}
	}
	&--m {
		@media #{$querySp} {
			min-width: 40%;
		}
		@media #{$queryPc} {
			min-width: 280px;
		}
	}
	&--full {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			width: 100%;
		}
	}
}

