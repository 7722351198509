@charset "UTF-8";
/* ----------------------------------------------------------------
CSRのみのスタイル
-----------------------------------------------------------------*/
/* CSRデータ
-----------------------------------------------------------------*/
.c-container--csr {
    @media #{$querySp} {
    }
    @media #{$queryPc} {
    }
    .img--col {
       @media #{$querySp} {
        }
        @media #{$queryPc} {
        } 
        div {
           @media #{$querySp} {
            }
            @media #{$queryPc} {
                width: 48%;
            } 
            img {
                @media #{$querySp} {
                    width: 100%;
                    height: auto;
                }
                @media #{$queryPc} {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}

