@charset "UTF-8";
/* ----------------------------------------------------------------
定義リストのコンポーネント
-----------------------------------------------------------------*/
.c-df01 {
	position: relative;
	@include borderRadius(5px);
	@media #{$querySp} {
		border: 1px solid $clrMain;
		margin-bottom: 20px;
		&:last-child {
			.c-df01__rightItem:before {
				display: none;
			}
		}
	}
	@media #{$queryPc} {
		width: 100%;
		display: table;
		table-layout: fixed;
		border: 2px solid $clrMain;
		margin-bottom: 40px;
		&:last-child {
			margin-bottom: 0;
			.c-df01__leftItem:before {
				display: none;
			}
		}
	}
}

.c-df01__leftItem, .c-df01__rightItem {
	@media #{$queryPc} {
		display: table-cell;
		vertical-align: middle;
	}
}

.c-df01__leftItem {
	position: relative;
	background-color: $clrMain;
	color: $clrWht;
	text-align: center;
	font-weight: 600;
	&:after {
		content: "";
		position: absolute;
	}
	@media #{$querySp} {
		padding: 16px;
		font-size: 1.4rem;
		&:after {
			bottom: -18px;
			right: 0;
			left: 0;
			margin: 0 auto;
			width: 10px;
			height: 18px;
			border-left: 5px solid transparent;
  			border-right: 5px solid transparent;
  			border-top: 5px solid $clrMain;
		}
	}
	@media #{$queryPc} {
		width: 207px;
		font-size: 1.8rem;
		&:after {
			top: 0;
			bottom: 0;
			right: -10px;
			margin: auto 0;
			width: 10px;
			height: 18px;
			border-top: 9px solid transparent;
  			border-bottom: 9px solid transparent;
  			border-left: 10px solid $clrMain;
		}
		&:before {
			content: "";
			position: absolute;
			bottom: -44px;
			width: 4px;
			height: 44px;
			background: url("../images/bg_df01.png") no-repeat;
		}
	}
}

.c-df01__rightItem {
	@media #{$querySp} {
		position: relative;
		padding: 20px 20px;
		&:before {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			margin: 0 auto;
			bottom: -22px;
			width: 2px;
			height: 22px;
			background: url("../images/bg_df01.png") no-repeat;
			-webkit-background-size: 2px auto;
			     -o-background-size: 2px auto;
			        background-size: 2px auto;
		}
	}
	@media #{$queryPc} {
		padding: 52px 40px;
	}
}

.c-df01__head {
	display: block;
	font-weight: 600;
	@media #{$querySp} {
		font-size: 1.4rem;
	}
	@media #{$queryPc} {
		margin-bottom: 8px;
		font-size: 1.8rem;
	}
}

.c-df01__leftItem__num {
	display: block;
	font-weight: normal;
	line-height: 1;
	@media #{$querySp} {
		margin-bottom: 2px;
		font-size: 2.4rem;
	}
	@media #{$queryPc} {
		margin-bottom: 4px;
		font-size: 4.8rem;
	}
}

.c-df01__yesno {
	display: inline-block;
	font-weight: 600;
	color: $clrMain;
	@media #{$querySp} {
		min-width: 32px;
		font-size: 1.2rem;
	}
	@media #{$queryPc} {
		min-width: 46px;
		font-size: 1.8rem;
	}
}

.c-df02 {
	width: 100%;
	display: table;
	table-layout: fixed;
	border-top: 1px solid $clrGry;
	&:last-child {
		border-bottom: 1px solid $clrGry;
	}
	@media #{$querySp} {
		padding: 20px 0;
	}
	@media #{$queryPc} {
		padding: 40px 0;
	}
}

.c-df02__leftItem, .c-df02__rightItem {
	display: table-cell;
	vertical-align: middle;
}

.c-df02__leftItem {
	position: relative;
	color: $clrWht;
	text-align: center;
	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;
		background-color: $clrMain;
		z-index: -1;
	}
	@media #{$querySp} {
		width: 44px;
		font-size: 1.6rem;
		&:before {
			width: 44px;
			height: 44px;
		}
	}
	@media #{$queryPc} {
		width: 85px;
		font-size: 3.2rem;
		&:before {
			width: 85px;
			height: 85px;
		}
	}
}

.c-df02__rightItem {
	@media #{$querySp} {
		padding-left: 10px;
	}
	@media #{$queryPc} {
		padding-left: 20px;
	}
}

.c-df02__rightItem__headline {
	display: block;
	font-weight: 600;
	color: $clrBlck;
	line-height: 1.4;
	@media #{$querySp} {
		margin-bottom: 5px;
		font-size: 1.6rem;
	}
	@media #{$queryPc} {
		margin-bottom: 11px;
		font-size: 2.0rem;
	}
}

.c-df03__itemIco {
	text-align: center;
	@media #{$querySp} {
		margin-bottom: 8px;
		font-size: 1.6rem;
	}
	@media #{$queryPc} {
		margin-bottom: 15px;
		font-size: 1.8rem;
	}
	span {
		display: block;
		vertical-align: bottom;
		@media #{$querySp} {
			margin-bottom: 14px;
			margin-top: 14px;
		}
		@media #{$queryPc} {
			margin-bottom: 20px;
			margin-top: 20px;
		}
	}
	img {
		vertical-align: bottom;
		&:not(.js-image-switch){
			@media #{$queryPc} {
				width: 100%;
			}
		}
		&.c-df03__itemIco_img{
			@media #{$querySp} {
				width: 50px;
				height: auto;
			}
		}
		@media #{$querySp} {
			width: 100%;
			height: auto;
		}
	}
	svg {
		font-size: 60px;
	}
}

.c-df03__itemIco--full {
	img {
		@media #{$querySp} {
			width: 100%;
		}
	}
}

.c-df03__itemText {
	color: #555;
}

.c-df03__itemMore {
	text-align: center;
	color: $clrLink;
	@media #{$queryPc} {
		@include opacity(0);
	}
}

.c-df03__linkBox--ico {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		margin-bottom: 40px;
	}
}

.c-df03__linkBox--ico {
	position: relative;
}

.c-df03__linkBox--ico {
	.c-df03__itemMore {
		font-weight: 600;
		color: $clrLink;
		@media #{$queryPc} {
			display: inline-block;
			position: absolute;
			bottom: -70px;
			left: 0;
			right: 0;
			margin: 0 auto;
		}
	}
}

.c-df03__linkBox--ico :hover {
	@media #{$queryPc} {
		.c-df03__itemMore {
			@include opacity(1);
			@include trans($duration: .3s,$delay: 0s);
			bottom: -50px;
		}
	}
}

.c-df04__itemPhoto {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		margin-bottom: 5px;
	}
	img {
		@media #{$querySp} {
			width: 100%;
			height: auto;
		}
		@media #{$queryPc} {
			width: 100px;
			height: auto;
		}
	}
}

.c-df04__itemTitle {
	font-weight: 600;
	text-align: center;
	line-height: 1.4;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		font-size: 1.3rem;
	}
}

.c-grid__linkBox:hover .c-df04__itemTitle {
	@include trans($duration: .1s,$delay: 0s);
	color: #000;
}
