@charset "UTF-8";
/* ----------------------------------------------------------------
generalのスタイル
-----------------------------------------------------------------*/
/* 下層KV
-----------------------------------------------------------------*/
.g-heroWrap {
	width: 100%;
	position: relative;
	@media #{$querySp} {
		padding-bottom: 74px;
	}
	@media #{$queryPc} {
		padding: 0 0 33px 33px;
	}
}

.g-heroInner {
	@media #{$querySp} {
		width: 100%;
		padding-top: 31.133%;
	}
	@media #{$queryPc} {
		width: 100%;
		height: 250px;
	}
	&--recruit {
		background: url("../images/hero_recruit.jpg") no-repeat center center;
		background-size: cover;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
	&--research {
		background: url("../images/hero_research.jpg") no-repeat center center;
		background-size: cover;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
	&--news {
		background: url("../images/hero_news.jpg") no-repeat center center;
		background-size: cover;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
	&--company {
		background: url("../images/hero_company.jpg") no-repeat center center;
		background-size: cover;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
    &--ir {
        background: url("../images/ir/index/kv.jpg") no-repeat center center;
		background-size: cover;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
    &--csr {
        background: url("../images/csr/index/kv.jpg") no-repeat center center;
		background-size: cover;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
    &--misc {
        background: url("../images/misc/hero_misc.jpg") no-repeat center center;
		background-size: cover;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
}

.g-heroTitleWrap {
	position: absolute;
	bottom: 0;
	left: 0;
	background-color: #000;
	@media #{$querySp} {
		width: 100%;
		padding: 20px 0 22px 0;
	}
	@media #{$queryPc} {
		min-width: 413px;
		padding: 30px 60px 35px 0;
	}
}

.g-heroTitle {
	color: #fff;
	font-weight: 600;
	border-bottom: 1px solid #fff;
	@media #{$querySp} {
		display: inline-block;
		min-width: 60.266%;
		padding-left: 30px;
	}
	@media #{$queryPc} {
		padding-left: 71px;
	}
}

.g-heroTitle__jp {
	letter-spacing: .2em;
	@media #{$querySp} {
		font-size: 2.1rem;
	}
	@media #{$queryPc} {
		display: inline-block;
		margin-right: 8px;
		font-size: 3.0rem;
	}
}

.g-heroTitle__en {
	@media #{$querySp} {
		display: none;
	}
	@media #{$queryPc} {
		font-size: 1.8rem;
	}
}