@charset "UTF-8";
/* ----------------------------------------------------------------
CSRのみのスタイル
-----------------------------------------------------------------*/
/* CSR
-----------------------------------------------------------------*/
.c-container--csr {
    .c-inner--md {
        padding: 0;
    }
    .csr_btn {
        background: #ADB0CE;
        position: relative;
        @media #{$querySp} {
            width: 100%;
            margin: 30px auto 30px;
        }
        @media #{$queryPc} {
            width: 45%;
            margin: 10px 0 50px;
            right: 0;
        }
        
        a {
            color: #fff;
            font-weight: 600;
            text-align: center;
            display: block;
            line-height: 4;
            position: relative;
            @media #{$querySp} {
            font-size: 1.6rem;
        }
        @media #{$queryPc} {
            font-size: 1.8rem;
        }
            &:after {
    position: absolute;
    top: 50%;
    
    width: 0.4em;
    height: 0.4em;
    transform: translateY(-50%) rotate(45deg);
    border-right: 1px solid #fff;
    border-top: 1px solid #fff;
    content: "";
                @media #{$querySp} {
            right: 1em;
        }
        @media #{$queryPc} {
            
            right: 4.5em;
        }
}
        &:hover {
            background-color: $clrKey;
                color: #fff;
            &:after {
                border-right: 1px solid #fff;
    border-top: 1px solid #fff;
            }
        }
        }
    }
    .c-inner--l {
    .wrap {
        @media #{$querySp} {
            margin-bottom: 0;
            }
        @media #{$queryPc} {
            margin-bottom: 20px;
        }
        .wrap-menu {
            border: solid 1px #000;
            padding: 0;
            @media #{$querySp} {
                margin-bottom: 20px;
                
            }
            @media #{$queryPc} {
                width: 32%;

            }
            &.wrap-menu--m {
                padding: 0;
                @media #{$querySp} {
                margin-bottom: 20px;
                
            }
                @media #{$queryPc} {
                width: 66%;
            }
                span {
                    @media #{$querySp} {
                        font-size: 1.8rem;
                    }
                    @media #{$queryPc} {
                        font-size: 2.4rem;
                    }
                }
                a {
                    font-weight: 600;
                    display: block;
                    @media #{$querySp} {
                        padding: 10px 20px;
                
            }
                @media #{$queryPc} {
                    padding: 15.5px 40px;
            }
                    &:hover {
            background-color: $clrKey;
                color: #fff;
                        opacity: 1;
            &:after {
                
            }
        }
                }
            }
            
            a {
                color: #000;
                display: block;
                @media #{$querySp} {
                padding: 20px;
            }
            @media #{$queryPc} {
                padding: 33px 40px;
                height: 100%;
            }
                &:after {
                    display: none;
                }
                span {
                    position: relative;
                    font-weight: bold;
                    letter-spacing: -0.06em;
                    @media #{$querySp} {
                        font-size: 1.6rem;
                    }
                    @media #{$queryPc} {
                        font-size: 2rem;
                    }
                    &:after {
                        display: inline-block;
                        content: "";
                        width: 4px;
                        height: 8px;
                        margin-left: 20px;
                        background: url("../images/ico_arrow_r_bk.svg") no-repeat center center;
                        background-size: 4px auto;
                        position: absolute;
                        top: 35%;
                    @media #{$querySp} {
                    }
                    @media #{$queryPc} {
                        
                    }
		}
                    
                }
                &:hover {
                        background-color: $clrKey;
                        color: #fff;
                        opacity: 1;
                    
                    }
               
            }
            
        }
        .wrap-menu {
            &:hover {
                a {
                    span {
                        &:after {
                        display: inline-block ;
                        content: "";
                        width: 4px;
                        height: 8px;
                        margin-left: 20px;
                        background: url("../images/ico_arrow_r_bk_hover.svg") no-repeat center center;
                        background-size: 4px auto;
                        position: absolute;
                        top: 35%;
                    @media #{$querySp} {
                    }
                    @media #{$queryPc} {
                        
                    }
		}
                    }
                }
            }
        }
    }
}
    .c-inner--l {
        .wrap {
            @media #{$querySp} {
            }
            @media #{$queryPc} {
                -webkit-box-pack: inherit;
                -ms-flex-pack: inherit;
                justify-content: inherit;
            }
            .wrap-menu {
                @media #{$queryPc} {
                    margin-right: 30px;
                }
                &:last-child {
                    @media #{$queryPc} {
                        margin-right: 0;
                    }
                }
            }
            &.wrap--col2 {
                .wrap-menu {
                    @media #{$queryPc} {
                        width: 31.8%;
                    }
                }
            }
        }
    }
    .news-wrap {
        .blank-link{
            @media #{$querySp} {
                margin-top: 0;
            }
        }
    }

}
.wrap--csr {
    div {
        &.p-pdSearch__input {
        @media #{$querySp} {
            padding: 0;
        }
        @media #{$queryPc} {
            width: 50%;
            margin: 20px 0 0 ;
        }
        }
    }
}
.wrap-menu--bnr {
    text-align: center;
    a {
        position: relative;
        @media #{$querySp} {
            padding: 10px 0 !important;
        }
        @media #{$queryPc} {
            padding: 25px 0 !important;
        }
        &:after {
            display: inline-block !important;
            content: "" !important;
            width: 16px !important;
            height: 16px !important;
            margin-left: 20px !important;
            background: url("../images/csr/icon_blank.png") no-repeat center center !important;
            background-size: 16px auto !important;
            position: absolute !important;
            bottom: 10px;
            right: 10px;
            @media #{$querySp} {
            }
            @media #{$queryPc} {
            }
		}
        &:hover {
            &:after {

            background: url("../images/csr/icon_blank_hover.png") no-repeat center center !important;

            @media #{$querySp} {
            }
            @media #{$queryPc} {
            }
		}
        }
    }
}