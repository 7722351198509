@charset "UTF-8";
/* ----------------------------------------------------------------
headerのスタイル
-----------------------------------------------------------------*/
body.overflow-hidden {
	/* when primary navigation is visible, the content in the background won't scroll */
	overflow: hidden;
}

.h-menuWrap {
	background-color: #fff;
	@media #{$querySp} {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 70px;
		z-index: 200;
	}
	@media #{$queryPc} {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 200;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		height: 120px;
		min-width: $containerWidthSmall;
		@include trans($duration: .3s,$delay: 0s)
	}
}

.h-headWrap {
	@media #{$querySp} {
		position: absolute;
		top: 0;
		left: 0;
		background: transparent;
		height: 70px;
		width: 100%;
		z-index: 200;
		/* Force Hardware Acceleration in WebKit */
		transform: translate3d(0, 0, 0);
		backface-visibility: hidden;
		@include trans($duration: .3s,$delay: 0s)
	}
	@media #{$queryPc} {
		display: flex;
		align-items: center;
	}
}

.h-logo {
	@media #{$querySp} {
		display: block;
		position: absolute;
		top: 50%;
		bottom: auto;
		transform: translateY(-50%);
		left: 15px;
		@include trans($duration: .3s,$delay: 0s)
	}
	@media #{$queryPc} {
		padding-left: 33px;
	}
	a {
		display: inline-block;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			@include trans($duration: .2s,$delay: 0s)
		}
		&:hover {
			@media #{$querySp} {
			}
			@media #{$queryPc} {
				@include opacity(.5);
			}
		}
	}
}

.h-logo img {
	display: block;
	@media #{$querySp} {
		width: 124px;
		height: auto;
	}
	@media #{$queryPc} {
		width: 198px;
		height: auto;
	}
}

.h-btnEn {
	z-index: 300;
	display: block;
	position: absolute;
	background-color: $clrKey;
	text-align: center;
	color: #fff;
	@media #{$querySp} {
		top: 50%;
		right: 66px;
		transform: translateY(-50%);
		width: 48px;
		height: 27px;
		font-size: 1.2rem;
		line-height: 27px;
	}
	@media #{$queryPc} {
		top: 0;
		right: 0;
		width: 81px;
		height: 42px;
		line-height: 42px;
		@include trans($duration: .2s,$delay: 0s);
	}
	&:hover {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			@include opacity(.5);
		}
	}
}

/*----- クラス追加 -----*/
.h-menuWrap.is-fixed {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		position: fixed;
		top: -120px;
		height: 120px;
		transition: transform 0.3s;
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
	}
	.h-headWrap {
		@media #{$querySp} {
			/* when the user scrolls down, we hide the header right above the viewport */
			position: fixed;
			top: -70px;
			height: 70px;
			background-color: #fff;
			transition: transform 0.3s;
			box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
		}
		@media #{$queryPc} {
		}
		.h-logo {
			@media #{$querySp} {
			}
			@media #{$queryPc} {
			}
			img {
				@media #{$queryPc} {
				}
			}
		}
		.h-btnNav__link {
			@media #{$querySp} {
			}
			@media #{$queryPc} {
			}
		}
	}
}

.h-menuWrap.is-visible {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		transform: translate3d(0, 100%, 0);
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
	}
	.h-headWrap {
		@media #{$querySp} {
			/* if the user changes the scrolling direction, we show the header */
			transform: translate3d(0, 99%, 0);
			box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
		}
		@media #{$queryPc} {
		}
	}
}

.h-menuWrap.menu-is-open {
	.h-headWrap {
		@media #{$querySp} {
			height: 70px;
			/* add a background color to the header when the navigation is open */
			background-color: #f0f0f0;
		}
		@media #{$queryPc} {
		}
		.h-logo {
			@media #{$querySp} {
			}
			@media #{$queryPc} {
			}
		}
		.h-btnNav__link {
			@media #{$querySp} {
			}
			@media #{$queryPc} {
			}
		}
	}
}

/*----- ハンバーガーボタン -----*/
.h-btnNav__link {
	@media #{$querySp} {
		position: absolute;
		top: 50%;
		right: 7px;
		bottom: auto;
		width: 41px;
		height: 41px;
		background-color: transparent;
		line-height: 30px;
		transform: translateY(-50%);
		@include trans($duration: .3s,$delay: 0s)
	}
	@media #{$queryPc} {
		display: none;
	}
}

.h-btnNav__link .cd-menu-icon {
	@media #{$querySp} {
		/* this span is the central line of the menu icon */
		display: inline-block;
		position: absolute;
		left: 50%;
		top: 50%;
		bottom: auto;
		right: auto;
		transform: translateX(-50%) translateY(-50%);
		width: 25px;
		height: 3px;
		background-color: rgba(255, 255, 255, 0);
		transition: background-color 0.3s;
		/* these are the upper and lower lines in the menu icon */
	}
	@media #{$queryPc} {
	}
}

.h-btnNav__link .cd-menu-icon::before, .h-btnNav__link .cd-menu-icon:after {
	@media #{$querySp} {
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		background-color: #000;
		right: 0;
		transition: transform .3s, top .3s, background-color 0s;
	}
	@media #{$queryPc} {
	}
}

.h-btnNav__link .cd-menu-icon::before {
	@media #{$querySp} {
		top: -7px;
	}
	@media #{$queryPc} {
	}
}

.h-btnNav__link .cd-menu-icon::after {
	@media #{$querySp} {
		top: 7px;
	}
	@media #{$queryPc} {
	}
}

.h-btnNav__link .cd-menu-icon.is-clicked {
	@media #{$querySp} {
		background-color: rgba(255, 255, 255, 0);
	}
	@media #{$queryPc} {
	}
}

.h-btnNav__link .cd-menu-icon.is-clicked::before, .h-btnNav__link .cd-menu-icon.is-clicked::after {
	@media #{$querySp} {
		background-color: #000;
	}
	@media #{$queryPc} {
	}
}

.h-btnNav__link .cd-menu-icon.is-clicked::before {
	@media #{$querySp} {
		top: 0;
		transform: rotate(135deg);
	}
	@media #{$queryPc} {
	}
}

.h-btnNav__link .cd-menu-icon.is-clicked::after {
	@media #{$querySp} {
		top: 0;
		transform: rotate(225deg);
	}
	@media #{$queryPc} {
	}
}

/*----- ナビゲーション -----*/
.h-menu {
	@media #{$querySp} {
		/* by default it's hidden - on top of the viewport */
		position: fixed;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		background-color: rgba(0,0,0,1);
		z-index: 100;
		text-align: center;
		padding: 70px 0 0;
		backface-visibility: hidden;
		overflow: auto;
		/* this fixes the buggy scrolling on webkit browsers - mobile devices only - when overflow property is applied */
		transform: translateY(-100%);
		transition-property: transform;
		transition-duration: 0.4s;
	}
	@media #{$queryPc} {
	}
}

.h-menuArea {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		display: flex;
		padding-top: 20px;
	}
}

.no-touch .h-menu a:hover {
	@media #{$querySp} {
		background-color: #e36767;
	}
	@media #{$queryPc} {
	}
}

.h-menu.is-visible {
	@media #{$querySp} {
		transform: translateY(0);
	}
	@media #{$queryPc} {
	}
}

.h-menuItem {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
	&:hover {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
		.h-menuItem__link {
			@media #{$querySp} {
			}
			@media #{$queryPc} {
			}
			&:after {
				@media #{$querySp} {
				}
				@media #{$queryPc} {
					opacity: 1;
				}
			}
		}
	}
}

.h-menuItem__link {
	@media #{$querySp} {
		display: block;
		padding: 16px 15px;
		border-bottom: 1px solid #fff;
		transition: background 0.2s;
		color: #fff;
		font-size: 1.4rem;
	}
	@media #{$queryPc} {
		position: relative;
		display: inline-block;
		padding: 10px 20px;
		font-size: 1.6rem;
		color: #000;
		@include trans($duration: .2s,$delay: 0s);
	}
	&:hover {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			@include opacity(.5);
		}
	}
	&:after {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			margin: 0 auto;
			content: "";
			width: 64px;
			height: 5px;
			background-color: #4F589C;
			opacity: 0;
		}
	}
}

.h-menuArea > .h-menuItem:hover .h-menuChildWrap {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		max-height: 9999px;
		opacity: 1;
	}
}
.h-menuArea > .h-menuItem > a {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		@include trans($duration: .2s,$delay: 0s);
	}
}

.h-menuChildWrap {
	@media #{$querySp} {
		display: none;
	}
	@media #{$queryPc} {
		max-height: 0;
		opacity: 0;
		@include trans($duration: .2s,$delay: 0s);
		overflow: hidden;
		position: absolute;
		left: 0;
		width: 100%;
		border-top: 1px solid #707070;
		background-color: #fff;
	}
}

.h-menuChildInner {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		display: flex;
		align-items: center;
		padding: 40px 60px;
	}
}

.h-menuChildHead {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		padding-right: 60px;
		font-size: 2.4rem;
		font-weight: 600;
	}
}

.h-menuChild {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		flex: 1;
		display: flex;
		flex-wrap: wrap;
	}
}

.h-menuChildItem {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		margin-right: 40px;
	}
}

.h-menuChildItem__link {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		font-size: 1.4rem;
		color: #000;
		padding-right: 16px;
		background: url("../images/ico_arrow_r_bk.svg") no-repeat right center;
		background-size: 6px auto;
		@include trans($duration: .1s,$delay: 0s);
	}
	&:hover {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			color: #4F589C;
			text-decoration: underline;
			background: url("../images/ico_arrow_r_blue.svg") no-repeat right center;
			background-size: 6px auto;
		}
	}
}

/*----- ユーティリティ -----*/
.h-utilityWrap {
	@media #{$querySp} {
		padding: 15px 15px 0;
	}
	@media #{$queryPc} {
		position: absolute;
		top: 0;
		right: 0;
		display: flex;
		padding: 16px 97px 0 0;
	}
}

.h-utilityItem {
	@media #{$querySp} {
		margin-bottom: 15px;
	}
	@media #{$queryPc} {
		padding-left: 30px;
	}
	&:nth-child(1) {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
		span {
			@media #{$querySp} {
			}
			@media #{$queryPc} {
			}
			&:before {
				display: inline-block;
				vertical-align: text-bottom;
				content: "";
				width: 18px;
				height: 18px;
				background: url("../images/ico_search.svg") no-repeat left center;
				background-size: 18px auto;
				margin-right: 6px;
				image-rendering: pixelated;
				@media #{$querySp} {
				}
				@media #{$queryPc} {
				}
			}
		}
	}
	&:nth-child(2) {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
		span {
			@media #{$querySp} {
			}
			@media #{$queryPc} {
			}
			&:before {
				display: inline-block;
				vertical-align: text-bottom;
				content: "";
				width: 20px;
				height: 18px;
				background: url("../images/ico_utility_access.svg") no-repeat left center;
				background-size: 20px auto;
				margin-right: 6px;
				image-rendering: pixelated;
				@media #{$querySp} {
				}
				@media #{$queryPc} {
				}
			}
		}
	}
	&:nth-child(3) {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
		span {
			@media #{$querySp} {
			}
			@media #{$queryPc} {
			}
			&:before {
				display: inline-block;
				vertical-align: text-bottom;
				content: "";
				width: 19px;
				height: 15px;
				background: url("../images/ico_utility_contact.svg") no-repeat left center;
				margin-right: 6px;
				@media #{$querySp} {
				}
				@media #{$queryPc} {
				}
			}
		}
	}
	&:nth-child(4) {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
		span {
			@media #{$querySp} {
			}
			@media #{$queryPc} {
			}
			&:before {
				display: inline-block;
				vertical-align: text-bottom;
				content: "";
				width: 18px;
				height: 18px;
				background: url("../images/ico_utility_doc.svg") no-repeat left center;
				margin-right: 6px;
				@media #{$querySp} {
				}
				@media #{$queryPc} {
				}
			}
		}
	}
}

.h-utilityItem__link {
	color: #000;
	font-weight: 600;
	@media #{$querySp} {
		display: block;
		padding: 12px 15px;
		background-color: #fff;
		font-size: 1.3rem;
	}
	@media #{$queryPc} {
		padding-bottom: 4px;
		border-bottom: 1px solid #000;
		font-size: 1.2rem;
		@include trans($duration: .1s,$delay: 0s);
	}
	&:hover {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			@include opacity(.5);
		}
	}
}










































