@charset "UTF-8";
/* ----------------------------------------------------------------
変数の設定項目
-----------------------------------------------------------------*/

/**
 * 1:パスの設定
 * 2:font-familyの設定
 * 3:font-size・line-heightの設定
 * 4:colorの設定
 * 5:マージンの設定
 * 6:componentパーツ「.c-grid」の余白の設定
 * 7:componentパーツ「.c-container, .c-inner」の幅設定
 * 8:ブレイクポイントの設定
 * 9:フォームの設定
 * 適用範囲等の詳しい説明は下記URLからご確認ください。
 * http://
 */

/* 1:パスの設定
-----------------------------------------------------------------*/
$src: "../";



/* 2:font-familyの設定
-----------------------------------------------------------------*/
/* サイト全体のfont-familyの設定 */
$fontFamily: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Noto Sans JP", "Hiragino Sans", "Noto Sans CJK JP", "Original Yu Gothic", "Yu Gothic", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Sans Emoji";

/* GoogleFontの設定 */
/*$fontEnRoboto: 'Roboto Condensed', sans-serif;*/
$fontEnRoboto: 'Roboto', sans-serif;
$fontEnRoboto__normal: 'Roboto', sans-serif;



/* 3:font-size・line-heightの設定
-----------------------------------------------------------------*/
 /* フォントサイズの設定【単位:rem】 */
$fsBase: 1.6; //base
$fsBaseSp: 1.3; //sp base
$fsL1: 3.2; //見出しLv1
$fsL2: 2.2; //見出しLv2
$fsL3: 1.8; //見出しLv3
$fsL5: 1.4; //見出しLv4
$fsL4: 1.4; //見出しLv5
$fsL6: 0; //見出しLv6
$fsSmall: 1.4; //サブ見出しサイズ

/* 行間の設定 */
$lhBase: 1.8; //base
$lhBaseSp: 1.5; //sp base



/* 4:colorの設定
-----------------------------------------------------------------*/
/* リンクのカラー設定 */
$clrLink: #DB0310; //標準のリンク色
$clrLinkHover: #ca1f7e; //hover時のリンク色

/* 一般的なiカラー */
$clrBlck: #000; //サイトの黒を定義
$clrWht: #fff; //サイトの白を定義
$clrGry: #e5e5e5; //サイトの灰色を定義

/* プロジェクトカラー設定 */
$clrBase: #ffffff; //ベースカラー
$clrMain: #0cbbee; //メインカラー
$clrAccent: #DB0310; //アクセントカラー
$clrKey: #5D639E; //アクセントカラー
$clrProject01: #0cbbee; //その他
$clrProject02: #555; //その他
$clrProject03: #fff; //その他
$clrProject04: #fff; //その他
$clrProject05: #fff; //その他
$clrPrimary: #DB0310; //選択
$clrInfo: #fff; //情報
$clrSuccess: #fff; //成功
$clrWarning: #ce1818; //注意
$clrDanger: #fff; //危険

/* ボタンリンクのカラー設定 */
$clrLinkBtn: #fff; //ボタンの文字色
$clrLinkBtnHover: #fff; //hover時のボタンの文字色
$clrhover: 10%; //hoverした際の彩度の設定

/* ボーダーカラーの設定 */
$clrBorderTable: #e5e5e5; //tableのborderカラー
$clrBorderBlock: #f3f4f5; //ブロック要素のborderカラー



/* 5:マージンの設定
-----------------------------------------------------------------*/
$mgSmall: 0;
$mgNormal: 0;
$mgLarge: 0;
$mgContainer: 0;


/* 6:componentパーツ「.c-grid」の余白の設定
 * 詳しくは下記URLからご確認下さい。
 * http://
-----------------------------------------------------------------*/
/* PC */
$gridPaddingPc: 0px;//paddingの設定
$gridMarginRightPc: 40px;//margin-rightの設定
$gridMarginBottomPc: 40px;//margin-bottomの設定

/* TAB */
$gridPaddingTab: 0px;//paddingの設定
$gridMarginRightTab: 0px;//margin-rightの設定
$gridMarginBottomTab: 0px;//margin-bottomの設定

/* SP */
$gridPaddingSp: 0px;//paddingの設定
$gridMarginRightSp: 20px;//margin-rightの設定
$gridMarginBottomSp: 20px;//margin-bottomの設定



/* 7:componentパーツ「.c-container, .c-inner」の幅設定
-----------------------------------------------------------------*/
$containerWidthBase: 1000px;//ベース幅
$containerWidthSmall: 1000px;//最小幅
$containerWidthLarge: 1310px;//最大幅
$containerWrap: 1000px;//タブレットでの表示サイズ

/* サブコンテンツの幅指定 */
$innerCol2sub: 260px;


/* 8:ブレイクポイントの設定
-----------------------------------------------------------------*/
$queryPc: "print, screen and (min-width: 768px)";//PC
$queryPcTab: "screen and (min-width:768px)";//PC&TAB
$queryTab: "screen and (max-width: 1159px)";//TAB
$querySp: "screen and (max-width: 767px)";//SP


/* 9:フォームの設定
-----------------------------------------------------------------*/

// 基本設定
$elmColor: #0cbbee; // 要素色
$elmTextColor: #000; // テキストカラー
$elmPlaceHolderColor: #aaaaaa; // placeholderのテキストカラー
$elmTextSize: 1.6rem; // テキストサイズ
$elmBoxItemBgColor: #fff;// 背景色
$elmBoxItemBdColor: #e5e5e5;// 線色
$elmColorPrimary: $clrPrimary;// 選択時

// チェックボックス・ラジオボックスの編集
$elmBoxItmeMg: 0.5em;// 要素間の余白
$lblBasePdng: 10px;  // ボックスとラベルの余白
$elmWidth: 16px; // ボックスの高さ
$elmHeight: 16px; // ボックスの幅

// ボタンの色
$elmBtnTextColor: #fff;
$elmBtnBgColor: #ccc;



/* 値を変更しないでください */
// Label Padding
$lblPl: $lblBasePdng + $elmWidth;

// Radio Base Position
$elmMt: -($elmHeight / 2);
$elmMl: $lblBasePdng;

// Radio Checked Size
$elmRadioWidth: $elmWidth * 0.64;
$elmRadioHeight: $elmHeight * 0.64;

// Radio After Position
$elmAfterMt: -($elmRadioHeight / 2);
// $elmAfterMl: ($elmWidth - $elmRadioWidth) / 2 + $elmMl;
$elmAfterMl: ($elmWidth - $elmRadioWidth) / 2;


// Check Checked Size
$elmCheckWidth: $elmWidth * 0.57;
$elmCheckHeight: $elmHeight * 0.36;

// Check After Position
$elmCheckAftrMt: -($elmHeight / 4);
// $elmCheckAftrMl: ($elmWidth - $elmCheckWidth) / 2 + $elmMl;
$elmCheckAftrMl: ($elmWidth - $elmCheckWidth) / 2;