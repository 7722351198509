@charset "UTF-8";
/* ----------------------------------------------------------------
CSRのみのスタイル
-----------------------------------------------------------------*/
/* CSRデータ
-----------------------------------------------------------------*/
.c-container--csr {
    @media #{$querySp} {
    }
    @media #{$queryPc} {
    }
    .c-inner--md {
        @media #{$querySp} {
            width: 95%;
            margin: 0 auto 50px;
        }
        @media #{$queryPc} {
            margin-bottom: 150px;
        }
        .section--csr {
            @media #{$querySp} {
                margin-bottom: 30px;
            }
            @media #{$queryPc} {
                margin-bottom: 50px;
            }
            .wrap-txt_cot_box_04 {
                border:1px solid #000;
                padding: 24px 32px;
                @media #{$querySp} {
                    margin: 0 0 20px 0;
                }
                @media #{$queryPc} {
                    margin: 0 0 20px 0;
                }
                h5 {
                    &.under_line {
                        font-size: 1.125em;
                        font-weight: bold;
                        border-bottom: 1px solid #000;
                        @media #{$querySp} {
                            width: 100%;
                            padding:0 0 7px 0;
                            margin: 0 0 10px 0;
                        }
                        @media #{$queryPc} {
                            width: 498px;
                            padding:0 0 14px 0;
                            margin: 0 0 20px 0;
                        }
                    }

                }
            }
            .table {
                @media #{$querySp} {
                    margin-top: 30px;
                }
                @media #{$queryPc} {
                    margin-top: 50px;
                }
                tr {
                    border-bottom: solid 1px #000;
                    @media #{$querySp} {}
                    @media #{$queryPc} {}
                    th {
                        font-weight: 600;
                        @media #{$querySp} {
                            padding: 10px;
                        }
                        @media #{$queryPc} {
                            vertical-align: top;
                            padding: 15px 0 15px 60px;
                        }
                    }
                    td {
                        @media #{$querySp} {
                            padding: 10px 10px 10px 0;
                        }
                        @media #{$queryPc} {
                            vertical-align: top;
                            padding: 15px 0;
                        }
                    }
                    &:nth-child(1) {
                        border-bottom: none;
                        background: #dee6ea;
                        th {
                            font-weight: 600;
                            @media #{$querySp} {
                                width: 25%;
                            }
                            @media #{$queryPc} {
                                width: 25%;
                                vertical-align: middle;
                            }
                        }
                        td {
                            font-weight: 600;
                            @media #{$querySp} {}
                            @media #{$queryPc} {
                                vertical-align: middle;
                            }
                        }
                    }
                }
            }
            p {
                a {
                color: #de5b11;
                &:hover {
                    text-decoration: underline;
                }
            }
            }
            .wrap-txt--li {
                @media #{$querySp} {
                    margin-left: 20px;
                }
                @media #{$queryPc} {
                    margin-left: 27px;
                }
                li {
                    list-style: disc;
                   @media #{$querySp} {
                       
                    }
                    @media #{$queryPc} {
                    } 
                }
            }
            .wrap-txt--ol {
                @media #{$querySp} {
                    margin-left: 20px;
                }
                @media #{$queryPc} {
                    margin-left: 27px;
                }
                li {
                    list-style: decimal;
                   @media #{$querySp} {
                       
                    }
                    @media #{$queryPc} {
                    } 
                    ul {
                        li {
                            list-style: disc;
                           @media #{$querySp} {

                            }
                            @media #{$queryPc} {
                            } 
                        }
                    }
                }
            }
            .wrap-txt--li--cnt {
                @media #{$querySp} {
                    margin-left: 10px;
                }
                @media #{$queryPc} {
                    margin-left: 15px;
                }
                li {
                    position: relative;
                    counter-increment: cnt;
                    @media #{$querySp} {
                        padding-left: 20px;
                       
                    }
                    @media #{$queryPc} {
                        padding-left: 24px;
                    } 
                    &:before {
                        position: absolute;
                        left: 0;
                        display: marker;
                        content: "(" counter(cnt) ") ";
                        @media #{$querySp} {
                        }
                        @media #{$queryPc} {
                        }
                    }
                }
            }
            .tblscroller {
            @media #{$querySp} {
                    font-size: 1.4rem;
                    margin-top: 20px;
                    margin-bottom: 10px;
                }
                @media #{$queryPc} {
                    display: none;
                }
            img {
                @media #{$querySp} {
                    width: 22px;
                    position: relative;
                    top: -1px;
                }
                @media #{$queryPc} {
                    display: none;
                }
            }
        }
            .scroll-table.scroll-table--society {
            @media #{$querySp} {    
                overflow: scroll;
                      -webkit-overflow-scrolling: touch;
            }
            @media #{$queryPc} {
                margin-top: 50px;
            }
            
            .table--scroller {
                
                @media #{$querySp} {
                    width: 1000px;
                    margin-bottom: 10px;
                       
                      
                }
                @media #{$queryPc} {
                    width: 100%;
                }
                tbody {
                    @media #{$querySp} {
                        width: 100%;
                          display:table;
                    }
                    @media #{$queryPc} {
                    }
                    
                
                th {
                    color: #202020;
                    background: #eeeeee;
                    
                    font-weight: 600;
                    text-align: left;
                    vertical-align: middle;
                    padding: 10px 20px;
                    border: solid 1px #ccc;
                        @media #{$querySp} {
                            font-size: 1.2rem;
                        }
                        @media #{$queryPc} {
                            font-size: 1.6rem;
                        }
                    &:nth-child(1) {
                        width: 20% !important;
                        @media #{$querySp} {
                        }
                        @media #{$queryPc} {
                        }
                    }
                    &:nth-child(2) {
                        width: 20% !important;
                        @media #{$querySp} {
                        }
                        @media #{$queryPc} {
                        }
                    }
                    &:nth-child(3) {
                        width: 60% !important;
                        @media #{$querySp} {
                        }
                        @media #{$queryPc} {
                        }
                    }
                    }
                td {
                    
                    text-align: left;
                    vertical-align: top;
                    padding: 15px 20px;
                    border: solid 1px #ccc;
                        @media #{$querySp} {
                            font-size: 1.2rem;
                        }
                        @media #{$queryPc} {
                            font-size: 1.6rem;

                        }
                    &:nth-child(2) {
                        @media #{$querySp} {
                        }
                        @media #{$queryPc} {
                        }
                        
                        
                    }
                    &:nth-child(3) {
                        @media #{$querySp} {
                        }
                        @media #{$queryPc} {
                        }
                    }
                    &.csrpg5_block2_row_coll_color_20200521_1 {
                        background: #fbfaf5;
                    }
                    &.csrpg5_block2_row_coll_color_20200521_2 {
                        background: #fef4f4;
                    }
                }
            }
              
            }
        }
            .csr_20201001 table {
               border-collapse: collapse;
               border: 3px solid #656D73;
                margin-bottom: 30px;
            }
            .csr_20201001 th,td {
               padding: 0.5em;
               border: none;
            }
        }
    }

}

