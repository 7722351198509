@charset "UTF-8";
/*----------------------------------------------------------------
グリッドのコンポーネント
-----------------------------------------------------------------*/
.c-grid {
	width: 100%;
	&:after {
		content: "";
		display: block;
		clear: both;
	}
}

.c-grid__col {
	float: left;
	box-sizing: border-box;

}

@media #{$queryPc} {
	@for $i from 0 through 10 {
		.c-grid--pc#{$i} .c-grid__col {
			width: calc((100% - (#{$gridMarginRightPc + 1} * (#{$i} - 1))) / #{$i});
			padding: $gridPaddingPc;
			margin-bottom: $gridMarginBottomPc;
			&:nth-child(#{$i}n+1) {
				clear: left;
			}
			&:not(:last-child) {
				margin-right: $gridMarginRightPc;
			}
			&:nth-child(#{$i}n) {
				margin-right: 0;
			}
		}
	}
}


@media #{$queryTab} {
	@for $i from 0 through 10 {
		.c-grid--tab#{$i} .c-grid__col {
			width: calc((100% - (#{$gridMarginRightTab} * (#{$i} - 1))) / #{$i});
			padding: $gridPaddingTab;
			margin-bottom: $gridMarginBottomTab;
			&:nth-child(#{$i}n+1) {
				clear: left;
			}
			&:not(:last-child) {
				margin-right: $gridMarginRightTab;
			}
			&:nth-child(#{$i}n) {
				margin-right: 0;
			}
		}
	}
}

@media #{$querySp} {
	@for $i from 0 through 10 {
		.c-grid--sp#{$i} .c-grid__col {
			width: calc((100% - (#{$gridMarginRightSp} * (#{$i} - 1))) / #{$i})!important;
			padding: $gridPaddingSp;
			margin-bottom: $gridMarginBottomSp;
			&:nth-child(#{$i}n+1) {
				clear: left;
			}
			&:not(:last-child) {
				margin-right: $gridMarginRightSp;
			}
			&:nth-child(#{$i}n) {
				margin-right: 0!important;
			}
		}
	}
}

/*----------------------------------------------------------------
グリッドのコンポーネント
-----------------------------------------------------------------*/
.c-grid {
	&:after {
	}
}

.c-grid__col {

}
