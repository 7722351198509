@charset "UTF-8";
/* ----------------------------------------------------------------
拠点・グループ会社　国内拠点のみのスタイル
-----------------------------------------------------------------*/
.c-container--officer {
    .c-inner--officer {
        @media #{$querySp} {
            margin-bottom: 40px;
        }
        @media #{$queryPc} {
            margin-bottom: 100px;
        }
        .wrap-officer {
            @media #{$querySp} {
            }
            @media #{$queryPc} {
                margin-bottom: 0;
            }
            .wrap--inner--officer {
                border-bottom: solid 1px #000;
                @media #{$querySp} {
                    width: 100%;
                    padding: 0 0 15px;
                }
                @media #{$queryPc} {
                    width: 48%;
                    margin-bottom: 40px;
                    padding-bottom: 40px;
                }
                .photo {
                    @media #{$querySp} {
                        width: 95%;
                        margin: 0 auto 20px;
                        text-align: center;
                    }
                    @media #{$queryPc} {
                        width: 167px;
                    }
                    img {
                        @media #{$querySp} {
                        width: 50% !important;
                            height: auto;
                    }
                    }
                }
                .officer-details {
                    @media #{$querySp} {
                        width: 95%;
                        margin: 0 auto 30px;
                    }
                    @media #{$queryPc} {
                        width: 60%;
                    }
                    .p-position {
                        /*font-weight: 600;*/
                        letter-spacing: .11em;
                        @media #{$querySp} {
                            font-size: 1.6rem;
                            margin: 0 0 10px;
                        }
                        @media #{$queryPc} {
                            font-size: 1.8rem;
                            margin: 20px 0;
                        }
                    }
                    .p-name {
                        font-weight: 600;
                        /*letter-spacing: .11em;*/
                        @media #{$querySp} {
                            font-size: 2rem;
                            margin-bottom: 30px;
                        }
                        @media #{$queryPc} {
                            font-size: 2.4rem;
                            line-height: 1;
                            margin-bottom: 40px;
                            
                        }
                        span {
                            font-weight: normal;
                            display: block;
                            line-height: 1.6;
                            @media #{$querySp} {
                                font-size: 1.2rem;
                            }
                            @media #{$queryPc} {
                                font-size: 1.4rem;
                            }
                        }
                    }
                    .btn {
                        @media #{$querySp} {
                        }
                        @media #{$queryPc} {
                        }
                        a {
                            font-size: 1.4rem;
                            margin: 0;
                           @media #{$querySp} {
                            }
                            @media #{$queryPc} {
                                line-height: 2.6;
                            } 
                        }
                    }
                }
            }
        }
    }
}
.companypg8_block2_row {
    @media #{$querySp} {
        margin-bottom: 30px;
    }
    @media #{$queryPc} {
        margin-bottom: 50px;
    }
    h3 {
	font-weight: 600;
	@media #{$querySp} {
		margin-bottom: 24px;
		font-size: 1.8rem;
	}
	@media #{$queryPc} {
		margin-bottom: 32px;
		font-size: 2.4rem;
	}
}
    .table_layout {
        width: 100%;
        margin-bottom: 10px;
        border-collapse: collapse;
        tr {
            border-top: solid 1px #000;
            border-bottom: solid 1px #000;
        }
        th {
            @media #{$querySp} {
                display: inherit;
                width: 33.3333333%;
            }
            @media #{$queryPc} {
                padding: 20px;
            }
        }
        td {
            border-top: solid 1px #000;
            border-bottom: solid 1px #000;
            border-left: solid 1px #000;
            @media #{$querySp} {
                display: inherit;
                width: 33.3333333%;
            }
            @media #{$queryPc} {
                padding: 20px;
            }
        }
    }
    .table_layout tr:last-child {
         border-bottom: solid 1px #000;
}
    .tblscroller {
            @media #{$querySp} {
                    /*font-size: 1.4rem;*/
                    margin-bottom: 10px;
                }
                @media #{$queryPc} {
                    display: none;
                }
            img {
                @media #{$querySp} {
                    width: 22px;
                    position: relative;
                    top: -1px;
                }
                @media #{$queryPc} {
                    display: none;
                }
            }
        }
    table {
            @media #{$querySp} {
            }
            @media #{$queryPc} {
            }
            th {
                font-weight: 600;
                @media #{$querySp} {
                }
                @media #{$queryPc} {
                    font-size: 1.4rem;
                }
            }
            td {
                font-weight: 600;
                @media #{$querySp} {
                }
                @media #{$queryPc} {
                    font-size: 1.4rem;
                }
                a {
                    color: #000;
                    &:hover {
                        text-decoration: underline;
                    } 
                }
            }
            &.table--pattern01 {
                @media #{$querySp} {
                    width: 100%;
                    margin-bottom: 10px;
                }
                @media #{$queryPc} {
                    margin-bottom: 10px;
                }
                tr {
                    border-bottom: solid 1px #000;
                    th {
                        @media #{$querySp} {
                            width: 100%;
                            display: block;
                            padding: 10px 0 5px;

                        }
                        @media #{$queryPc} {
                            width: 38%;
                            padding: 20px 40px 20px 30px;
                        }
                    }
                    td {
                        @media #{$querySp} {
                            width: 100%;
                            display: block;
                            padding: 0 0 10px;
                        }
                        @media #{$queryPc} {
                            padding: 20px 0;
                        }
                    }
                }
            }
        }
    .scroll-table {
            @media #{$querySp} {    
                overflow: scroll;
                      -webkit-overflow-scrolling: touch;
            }
            @media #{$queryPc} {
            }
            
            .table--scroller {
                border-top: 1px solid #cecece;
                border-bottom: 1px solid #cecece;
                
                @media #{$querySp} {
                    width: 1000px;
                    margin-bottom: 10px;
                       
                      
                }
                @media #{$queryPc} {
                    width: 100%;
                }
                tbody {
                    @media #{$querySp} {
                        width: 100%;
                          display:table;
                    }
                    @media #{$queryPc} {
                    }
                    
                
                th {
                    color: #202020;
                    border-top: 1px solid #cecece;
                    border-right: 1px solid #cecece;
                    border-bottom: 1px solid #cecece;
                        text-align: left;
                        vertical-align: middle;
                        padding: 10px 15px;
                    @media #{$querySp} {
                    }
                    @media #{$queryPc} {
                        font-size: 1.4rem;
                    }
                    &:last-child {
                        padding: 0;
                        .table {
                            width: 100%;
                            border: none !important;
                            border-top: 1px solid #cecece !important;
                            th {
                                width: 50%;
                                border: none !important;
                                border-right: 1px solid #cecece !important;
                                &:last-child {
                                    border-right: none !important;
                                }
                            }
                        }
                    }
                    p {
                        padding: 10px 15px;
                    }
                    &:nth-child(4) {
                        @media #{$querySp} {
                        }
                        @media #{$queryPc} {
                        }
                    }
                    
                }
                td {
                    border-top: 1px solid #cecece;
                    border-bottom: 1px solid #cecece;
                    border-left: 1px solid #cecece;
                        text-align: center;
                        vertical-align: middle;
                        padding: 15px;
                    @media #{$querySp} {
                    }
                    @media #{$queryPc} {
                        font-size: 1.4rem;
                    }
                    &:last-child {
                        text-align: left;
                        @media #{$querySp} {
                        }
                        @media #{$queryPc} {
                        }
                        
                        
                    }
                    &:first-child {
                        @media #{$querySp} {
                            width: 20%;
                        }
                        @media #{$queryPc} {
                            width: 20%;
                        }
                    }
                }
            }
                &.table--pattern02 {
                    tr {
                        th {
                            width: 33%;
                        }
                    }
                }
            }
        }
}