@charset "UTF-8";
/* ----------------------------------------------------------------
採用情報のみのスタイル
-----------------------------------------------------------------*/
/* 採用情報トップ
-----------------------------------------------------------------*/
.p-recTop__lead {
	@media #{$querySp} {
		margin-bottom: 30px;
		font-size: 1.4rem;
		line-height: 1.7;
	}
	@media #{$queryPc} {
		margin-bottom: 60px;
		font-size: 1.6rem;
		line-height: 2;
		text-align: center;
	}
}

.p-recTopListWrap {
	width: 100%;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		display: flex;
		justify-content: space-between;
	}
}

.p-recTopListItem {
	@media #{$querySp} {
		margin-bottom: 30px;
	}
	@media #{$queryPc} {
		width: 475px;
	}
	&:last-child {
		@media #{$querySp} {
			margin-bottom: 0;
		}
		@media #{$queryPc} {
		}
	}
}

.p-recTopListItem__link {
	display: block;
	color: #000;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		@include trans($duration: .2s,$delay: 0s);
	}
	&:hover {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			@include opacity(.5);
		}
	}
}

.p-recTopList__photo {
	@media #{$querySp} {
		margin-bottom: 12px;
	}
	@media #{$queryPc} {
		margin-bottom: 24px;
	}
	img {
		@media #{$querySp} {
			width: 100%;
			height: auto;
		}
		@media #{$queryPc} {
		}
	}
}

.p-recTopListItem__head {
	text-align: center;
	line-height: 1;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

.p-recTopListItem__headLg {
	@media #{$querySp} {
		padding-right: 12px;
		background: url("../images/ico_arrow_r_bk.svg") no-repeat right center;
		background-size: 6px auto;
		font-size: 1.5rem;
	}
	@media #{$queryPc} {
		padding-right: 24px;
		font-size: 1.8rem;
		background: url("../images/ico_arrow_r_bk.svg") no-repeat right center;
		background-size: 4px auto;
	}
	&--blank {
		@media #{$querySp} {
			padding-right: 16px;
			background: url("../images/ico_blank.svg") no-repeat right center;
			background-size: 10px auto;
		}
		@media #{$queryPc} {
			background: url("../images/ico_blank.svg") no-repeat right center;
			background-size: 12px auto;
	}
	}
}

.p-recTopListItem__headSm {
	display: block;
	@media #{$querySp} {
		margin-top: 4px;
		font-size: 1.1rem;
	}
	@media #{$queryPc} {
		margin-top: 8px;
		font-size: 1.2rem;
	}
}

/* キャリア採用
-----------------------------------------------------------------*/
.p-recCarNavWrap {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	@media #{$querySp} {
		margin-bottom: 32px;
	}
	@media #{$queryPc} {
		margin-bottom: 64px;
	}
}

.p-recCarNavItem {
	@media #{$querySp} {
		width: 50%;
		margin-bottom: 10px;
	}
	@media #{$queryPc} {
		width: 239px;
		margin-bottom: 8px;
	}
	&:nth-child(odd) {
		@media #{$querySp} {
			padding-right: 5px;
		}
		@media #{$queryPc} {
		}
	}
	&:nth-child(even) {
		@media #{$querySp} {
			padding-left: 5px;
		}
		@media #{$queryPc} {
		}
	}
}

.p-recCarNavItem__link {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	border: 1px solid #000;
	text-align: center;
	color: #000;
	font-weight: 600;
	@media #{$querySp} {
		padding: 12px;
	}
	@media #{$queryPc} {
		min-height: 90px;
		padding: 16px 20px;
		@include trans($duration: .2s,$delay: 0s);
	}
	&:hover {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			background-color: $clrKey;
			color: #fff;
		}
	}
}

.p-recCarTable {
	width: 100%;
	border-bottom: 1px solid #000;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
	tbody {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
		tr {
			@media #{$querySp} {
			}
			@media #{$queryPc} {
			}
			th {
				border-top: 1px solid #000;
				font-weight: 600;
				@media #{$querySp} {
					display: block;
					padding: 8px 8px 2px 8px;
				}
				@media #{$queryPc} {
					width: 200px;
					text-align: center;
					padding: 16px;
				}
			}
			td {
				@media #{$querySp} {
					display: block;
					margin-bottom: 16px;
					padding: 2px 8px 8px 8px;
				}
				@media #{$queryPc} {
					border-top: 1px solid #000;
					padding: 16px;
				}
			}
		}
	}
}

.p-xxxxxxxx {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

.p-xxxxxxxx {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

.p-xxxxxxxx {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

.p-xxxxxxxx {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}





















