@charset "UTF-8";
/* ----------------------------------------------------------------
研究開発のみのスタイル
-----------------------------------------------------------------*/
/* 研究開発トップ
-----------------------------------------------------------------*/
.p-reschTop__lead {
	font-weight: 600;
	text-align: center;
	@media #{$querySp} {
		margin-bottom: 16px;
		font-size: 2.2rem;
	}
	@media #{$queryPc} {
		margin-bottom: 32px;
		font-size: 4.0rem;
	}
}

.p-reschTop__txt {
	@media #{$querySp} {
		margin-bottom: 24px;
	}
	@media #{$queryPc} {
		margin-bottom: 48px;
		text-align: center;
	}
}

.p-reschTopListWrap {
	width: 100%;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		display: flex;
		justify-content: space-between;
	}
}

.p-reschTopListItem {
	@media #{$querySp} {
		margin-bottom: 30px;
	}
	@media #{$queryPc} {
		width: 48.437%;
	}
	&:last-child {
		@media #{$querySp} {
			margin-bottom: 0;
		}
		@media #{$queryPc} {
		}
	}
	&--bottom {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			width: 24.296%;
		}
	}
}

.p-reschTopListItem__link {
	display: block;
	color: #000;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		@include trans($duration: .2s,$delay: 0s);
	}
	&:hover {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			@include opacity(.5);
		}
	}
}

.p-reschTopList__photo {
	@media #{$querySp} {
		position: relative;
		overflow: hidden;
		padding-top: 34.944%;
		margin-bottom: 12px;
	}
	@media #{$queryPc} {
		margin-bottom: 24px;
	}
	img {
		width: 100%;
		height: auto;
		@media #{$querySp} {
			position: absolute;
			top: 0;
			left: 0;
		}
		@media #{$queryPc} {
		}
	}
}

.p-reschTopListItem__head {
	text-align: center;
	line-height: 1;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

.p-reschTopListItem__headLg {
	@media #{$querySp} {
		padding-right: 12px;
		background: url("../images/ico_arrow_r_bk.svg") no-repeat right center;
		background-size: 6px auto;
		font-size: 1.5rem;
	}
	@media #{$queryPc} {
		padding-right: 24px;
		font-size: 1.8rem;
		background: url("../images/ico_arrow_r_bk.svg") no-repeat right center;
		background-size: 4px auto;
	}
}

.p-reschTopListItem__headSm {
	display: block;
	@media #{$querySp} {
		margin-top: 4px;
		font-size: 1.1rem;
	}
	@media #{$queryPc} {
		margin-top: 8px;
		font-size: 1.2rem;
	}
}

.p-xxxxxxxx {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}





















