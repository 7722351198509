@charset "UTF-8";
/* ----------------------------------------------------------------
the_content() を出力する親 div
-----------------------------------------------------------------*/
.wp-content {

h1,
h2,
h3,
h4,
h5,
h6 {
	color: #000;
	font-weight: 600;
	&:first-child {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			margin-top: 0;
		}
	}
	&:last-child {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			margin-bottom: 0;
		}
	}
}

h1 {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

h2 {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

h3 {
	position: relative;
	border-bottom: 1px solid #f0f0f0;
	@media #{$querySp} {
		margin: 30px 0;
		padding-bottom: 8px;
		font-size: 2.0rem;
	}
	@media #{$queryPc} {
		margin: 60px 0;
		padding-bottom: 16px;
		font-size: 2.8rem;
	}
	&:after {
		position: absolute;
		content: "";
		bottom: -1px;
		left: 0;
		background-color: #000;
		@media #{$querySp} {
			width: 10px;
		    height: 1px;
		}
		@media #{$queryPc} {
		    width: 20px;
		    height: 1px;
		}
	}
	&:first-child {
		margin-top: 0;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
}

h4 {
	position: relative;
	@media #{$querySp} {
		margin: 16px 0;
		padding-bottom: 4px;
		font-size: 1.8rem;
	}
	@media #{$queryPc} {
		margin: 32px 0;
		padding-bottom: 8px;
		font-size: 2.0rem;
	}
	&:after {
		position: absolute;
		content: "";
		bottom: -1px;
		left: 0;
		background-color: #ddd;
		@media #{$querySp} {
			width: 10px;
		    height: 1px;
		}
		@media #{$queryPc} {
		    width: 20px;
		    height: 1px;
		}
	}
}

h5 {
	@media #{$querySp} {
		margin: 12px 0;
		font-size: 1.6rem;
	}
	@media #{$queryPc} {
		margin: 24px 0;
		font-size: 1.8rem;
	}
}

h6 {
	@media #{$querySp} {
		margin: 8px 0;
		font-size: 1.4rem;
	}
	@media #{$queryPc} {
		margin: 16px 0;
		font-size: 1.6rem;
	}
}

p {
	@media #{$querySp} {
		margin: 15px 0;
		line-height: 1.6;
	}
	@media #{$queryPc} {
		margin: 30px 0;
		font-size: 1.6rem;
		line-height: 2.0;
	}
	&:first-child {
		margin-top: 0;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
	&:last-child {
		margin-bottom: 0;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
}

strong {
	font-weight: 600;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

//リスト共通
ul > li,
ol > li, {
	position: relative;
	z-index: 1;
	padding-left: 16px;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

ul,
ol {
	@media #{$querySp} {
		margin: 16px 0;
		line-height: 1.5;
	}
	@media #{$queryPc} {
		margin: 24px 0;
		line-height: 1.8;
	}
	li {
		@media #{$querySp} {
			margin: 15px 0;
		}
		@media #{$queryPc} {
			margin: 30px 0;
		}
		&:first-child {
			margin-top: 0;
			@media #{$querySp} {
			}
			@media #{$queryPc} {
			}
		}
		&:last-child {
			margin-bottom: 0;
			@media #{$querySp} {
			}
			@media #{$queryPc} {
			}
		}
	}
}

//ドットリスト
ul > li {
	&:before {
		display: block;
		content: "";
		width: .5em;
		height: .5em;
		border-radius: 100%;
		position: absolute;
		z-index: 1;
		top: 0.6em;
		left: 1px;
		width: 4px;
		height: 4px;
		border-radius: 100%;
		background-color: $clrBlck;
		font-weight: bold;
	}
}

//番号リスト
ol {
	counter-reset: li;
}

ol > li {
	&:nth-child(n+10) {
		padding-left: 1.8em;
	}
	&:before {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		content:counter(li) ". ";
		counter-increment: li;
		font-weight: 600;
		color: $clrBlck;
	}
}

table {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

img {
	@media #{$querySp} {
		max-width: 100%;
	}
	@media #{$queryPc} {
	}
}

}