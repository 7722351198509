@charset "UTF-8";
/* ----------------------------------------------------------------
CSRのみのスタイル
-----------------------------------------------------------------*/
/* CSRデータ
-----------------------------------------------------------------*/
.section--developing_story {
    @media #{$querySp} {
        margin-bottom: 40px;
    }
    @media #{$queryPc} {
        margin-bottom: 50px;
    }
    &.section--developing_story--kv {
        position: relative;
       @media #{$querySp} {
           margin-bottom: 30px;
        }
        @media #{$queryPc} {
            margin-top: 63px;
            margin-bottom: 50px;
            padding-left: 30px;
        } 
        .section--developing_story--kv-inner {
            background: #efefef;
           @media #{$querySp} {
               padding: 40px 20px 20px;
            }
            @media #{$queryPc} {
                display: -webkit-flex;
                display: flex;
                -webkit-align-items: center;
                align-items: center;
                height: 290px;
            } 
            .inner--img {
               @media #{$querySp} {
                   width: 90%;
                   margin: 0 auto 20px;
                }
                @media #{$queryPc} {
                    position: relative;
                    top: -30px;
                    left: -30px;
                }  
                img {
                    
                }
            }
            .inner--head2 {
               @media #{$querySp} {
                }
                @media #{$queryPc} {
                }  
                h2 {
                    font-weight: 600;
                    line-height: 2;
                    @media #{$querySp} {
                        font-size: 2.06rem;
                        text-align: center;
                    }
                    @media #{$queryPc} {
                        font-size: 3.0rem;
                    }
                }
            }
        }
    }
    .p--developing_story {
        @media #{$querySp} {
        }
        @media #{$queryPc} {
        }
    }
    .p--developing_story--left {
        @media #{$querySp} {
        }
        @media #{$queryPc} {
            margin-right: 30px;
        }
    }
    .img--developing_story {
        @media #{$querySp} {
        }
        @media #{$queryPc} {
        }
        span {
            display: block;
            text-align: center;
            font-size: 1.2rem;
            @media #{$querySp} {
                padding-top: 10px;
            }
            @media #{$queryPc} {
                padding-top: 15px;
            }
        }
    }
    .img--developing_story--left {
        @media #{$querySp} {
        }
        @media #{$queryPc} {
            margin-right: 30px;
        }
    }
    h3 {
        font-weight: 600;
        letter-spacing: .11em;
        display: block;
        @media #{$querySp} {
            font-size: 1.6rem;
            margin-bottom: 20px;
            line-height: 24px;
        }
        @media #{$queryPc} {
            font-size: 2rem;
            margin-bottom: 20px;
            line-height: 36px;
        }
    }
    p {
        letter-spacing: .07em;
        @media #{$querySp} {
            margin-bottom: 15px;
            line-height: 1.8;
        }
        @media #{$queryPc} {
            margin-bottom: 15px;
            line-height: 1.8;
        }
    }
    &.developing_story--order {
        position: relative;
        @media #{$querySp} {
        }
        .wrap {
            @media #{$querySp} {
                display: flex;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
            }
            @media #{$queryPc} {
                padding-top: 50px;
            }
            .wrap--block {
                display: block;
                @media #{$querySp} {
                        order: 2;
                    width: 100%;
                    }
                @media #{$queryPc} {
                    position: absolute;
                    top: 0;
                }
            }
            .p--developing_story {
                @media #{$querySp} {
                    order: 3;
                }
            }
            .img--developing_story {
                @media #{$querySp} {
                    order: 1;
                    margin: auto;
                }
            }
        }
    }
    .section--developing_story--electrolyte_analyzers {
        .u-aCenter--p--developing_story {
            span {
                display: block;
                text-align: center;
                font-size: 1.2rem;
                @media #{$querySp} {
                    padding-top: 10px;
                }
                @media #{$queryPc} {
                    padding-top: 15px;
                }
            }
        }
    }
    .box_black {
        border: solid 1px #000;
        @media #{$querySp} {
            margin-top: 30px;
            padding: 15px 20px 0;
        }
        @media #{$queryPc} {
            margin-top: 50px;
            padding: 20px 30px 10px;
        }
    }
    .img-wid50 {
        @media #{$querySp} {
            width: 50% !important;
            height: auto;
        }
    }
    .img-wid66 {
        @media #{$querySp} {
            width: 66% !important;
            height: auto;
        }
    }
}
.p-pdListDlWrap--developing_story {
        .p-pdListDlItem {
            .p-pdListDlItem__link span:before {
                display: none !important;
            }
            .p-pdListDlItem__link {
                color: #000;
                background: #fff;
                border: solid 1px #000;
                &:hover {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			color: #fff;
			background: $clrKey;
		}
		
	}
            }
        }
    }
