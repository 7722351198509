@charset "UTF-8";
/* ----------------------------------------------------------------
リセットの読み込み
-----------------------------------------------------------------*/
/**
* YUI 3.5.0 - reset.css (http://developer.yahoo.com/yui/3/cssreset/)
* http://cssreset.com
* Copyright 2012 Yahoo! Inc. All rights reserved.
* http://yuilibrary.com/license/
*/
/*
TODO will need to remove settings on HTML since we can't namespace it.
TODO with the prefix, should I group by selector or property for weight savings?
*/
html{
color:#000;
background:#FFF;
}
/*
TODO remove settings on BODY since we can't namespace it.
*/
/*
TODO test putting a class on HEAD.
- Fails on FF.
*/
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td {
margin:0;
padding:0;
}
table {
border-collapse:collapse;
border-spacing:0;
}
fieldset,
img {
border:0;
}
/*
TODO think about hanlding inheritence differently, maybe letting IE6 fail a bit...
*/
address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
font-style:normal;
font-weight:normal;
}
ol,
ul {
list-style:none;
}
caption,
th {
text-align:left;
}
h1,
h2,
h3,
h4,
h5,
h6 {
// font-size:100%;
font-weight:normal;

}
q:before,
q:after {
content:'';
}
abbr,
acronym {
border:0;
font-variant:normal;
}
/* to preserve line-height and selector appearance */
sup {
vertical-align:text-top;
}
sub {
vertical-align:text-bottom;
}
input,
textarea,
select {
font-family:inherit;
font-size:inherit;
font-weight:inherit;
}
/*to enable resizing for IE*/
input,
textarea,
select {
*font-size:100%;
}
/*because legend doesn't inherit in IE */
legend {
color:#000;
}
/* YUI CSS Detection Stamp */
#yui3-css-stamp.cssreset { display: none; }







/*! sanitize.css v3.0.0 | CC0 1.0 Public Domain | github.com/10up/sanitize.css */
/*
 * Normalization
 */
audio:not([controls]) {
  display: none;
  /* Chrome 44-, iOS 8+, Safari 9+ */
}

button {
  overflow: visible;
  /* Internet Explorer 11- */
  -webkit-appearance: button;
  /* iOS 8+ */
}

details {
  display: block;
  /* Edge 12+, Firefox 40+, Internet Explorer 11-, Windows Phone 8.1+ */
}

html {
  /*-ms-overflow-style: -ms-autohiding-scrollbar;*/
  /* Edge 12+, Internet Explorer 11- */
  /*overflow-y: scroll;*/
  /* All browsers without overlaying scrollbars */
  -webkit-text-size-adjust: 100%;
  /* iOS 8+ */
}

input {
  -webkit-border-radius: 0;
  /* iOS 8+ */
}
@nest &[type="button"], &[type="reset"], &[type="submit"] {
  input {
    -webkit-appearance: button;
    /* iOS 8+ */
  }
}
@nest &[type="number"] {
  input {
    width: auto;
    /* Firefox 36+ */
  }
}
@nest &[type="search"] {
  input {
    -webkit-appearance: textfield;
    /* Chrome 45+, Safari 9+ */
  }
  @nest &::-webkit-search-cancel-button, &::-webkit-search-decoration {
    input {
      -webkit-appearance: none;
      /* Chrome 45+, Safari 9+ */
    }
  }
}
main {
  display: block;
  /* Android 4.3-, Internet Explorer 11-, Windows Phone 8.1+ */
}

pre {
  overflow: auto;
  /* Internet Explorer 11- */
}

progress {
  display: inline-block;
  /* Internet Explorer 11-, Windows Phone 8.1+ */
}

small {
  font-size: var(--small-font-size, 75%);
  /* All browsers */
}

summary {
  display: block;
  /* Firefox 40+, Internet Explorer 11-, Windows Phone 8.1+ */
}

svg:not(:root) {
  overflow: hidden;
  /* Internet Explorer 11- */
}

template {
  display: none;
  /* Android 4.3-, Internet Explorer 11-, iOS 7-, Safari 7-, Windows Phone 8.1+ */
}

textarea {
  overflow: auto;
  /* Edge 12+, Internet Explorer 11- */
}

[hidden] {
  display: none;
  /* Internet Explorer 10- */
}

/*
 * Universal inheritance
 */
*,
::before,
::after {
  box-sizing: inherit;
}

* {
  font-size: inherit;
  line-height: inherit;
}

::before,
::after {
  text-decoration: inherit;
  vertical-align: inherit;
}

/*
 * Opinionated defaults
 */
/* specify the border style and width of all elements */
*,
::before,
::after {
  border-style: solid;
  border-width: 0;
}

/* specify the core styles of all elements */
* {
  background-repeat: var(--background-repeat, no-repeat);
  margin: 0;
  padding: 0;
}

/* specify the root styles of the document */
:root {
  background-color: var(--html-background-color, #ffffff);
  box-sizing: var(--html-box-sizing, border-box);
  color: var(--html-color, #000000);
  cursor: var(--html-cursor, default);
  /*font: var(--html-font-size, 100%)/var(--html-line-height, 1.5) var(--font-family, sans-serif);*/
  text-rendering: var(--html-text-rendering, optimizeLegibility);
}

/* specify the text decoration of anchors */
a {
  text-decoration: var(--anchor-text-decoration, none);
}

/* specify the alignment of media elements */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: var(--media-element-vertical-align, middle);
}

/* specify the background color of form elements */
button,
input,
select,
textarea {
  background-color: var(--form-element-background-color, transparent);
}

/* specify the inherited color and font of form elements */
button,
input,
select,
textarea {
  color: inherit;
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
}

/* specify the minimum height of form elements */
button,
[type="button"],
[type="date"],
[type="datetime"],
[type="datetime-local"],
[type="email"],
[type="month"],
[type="number"],
[type="password"],
[type="reset"],
[type="search"],
[type="submit"],
[type="tel"],
[type="text"],
[type="time"],
[type="url"],
[type="week"],
select,
textarea {
  min-height: var(--form-element-min-height, 1.5em);
}

/* specify the font family of code elements */
code,
kbd,
pre,
samp {
  font-family: var(--monospace-font-family, monospace), monospace;
}

/* specify the standard appearance of selects */
select {
  -moz-appearance: none;
  /* Firefox 40+ */
  -webkit-appearance: none;
  /* Chrome 45+ */
}
@nest &::-ms-expand {
  select {
    display: none;
    /* Edge 12+, Internet Explorer 11- */
  }
}
@nest &::-ms-value {
  select {
    color: currentColor;
    /* Edge 12+, Internet Explorer 11- */
  }
}
/* specify the border styling of tables */
table {
  border-collapse: var(--table-border-collapse, collapse);
  border-spacing: var(--table-border-spacing, 0);
}

/* specify the resizability of textareas */
textarea {
  resize: var(--textarea-resize, vertical);
}

/* specify the progress cursor of updating elements */
[aria-busy="true"] {
  cursor: progress;
}

/* specify the pointer cursor of trigger elements */
[aria-controls] {
  cursor: pointer;
}

/* specify the unstyled cursor of disabled, not-editable, or otherwise inoperable elements */
[aria-disabled] {
  cursor: default;
}

/* specify the style of visually hidden yet accessible elements */
[hidden][aria-hidden="false"] {
  clip: rect(0 0 0 0);
  display: inherit;
  position: absolute;
}
@nest &:focus {
  [hidden][aria-hidden="false"] {
    clip: auto;
  }
}







/*
OZ RESET CSS
Author: ONZE
Author URI: http://on-ze.com
Version: 1.1
License: GNU General Public License
License URI: http://www.gnu.org/licenses/gpl-2.0.html
*/
* {
  border: 0;
  margin: 0;
  outline: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  font: inherit;
  font-family: inherit;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  text-decoration: none;
  vertical-align: baseline;
}

html {
  font-size: 62.5%;
  width: 100%;
  height: 100%;
}

body {
  font-size: 10px;
  font-size: 1.0rem;
  line-height: 1;
  position: relative;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -o-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

article, aside, dialog, figure, footer, header, main, menu, nav, section {
  display: block;
}

audio, canvas, video {
  display: inline-block;
}

br,
hr {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none;
}

input,
select {
  vertical-align: middle;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}