@charset "UTF-8";
/* ----------------------------------------------------------------
 ir/event
-----------------------------------------------------------------*/
.wrap--ir_event {
    .left {
        float: left;
    }
    .right {
        float: right;
    }
    .wrap-img_calendar {
        @media #{$querySp} {
            margin: 0 0 48px 0;
        }
        @media #{$queryPc} {
            margin: 0 0 96px 0;
        }
        img {
            width:100%;
        }
    }
    .wrap-calendar_nav {
        width: 100%;
        a {
            color: #000;
            float:left;
            display: block;
            font-size: 1em;
            font-weight: bold;
            border:1px solid #000;
            box-sizing: border-box;
            text-align: center;
            &:last-child {
                margin: 0;
            }
            &:hover {
                color: #FFF;
                background: $clrKey;
                -webkit-transition: all .2s ease-in-out 0s;
                transition: all .2s ease-in-out 0s;
            }
            @media #{$querySp} {
                width: 48%;
                padding: 10px 0;
                margin: 0 2% 10px 0;
                &:nth-child(2n) {
                    margin: 0 0 10px 2%;
                }
            }
            @media #{$queryPc} {
                width: 113px;
                padding:10px 0;
                margin: 0 10px 0 0;
            }
        }
    }
    .wrap-calendar_content {
        width: 100%;
        @media #{$querySp} {
            margin: 0 0 30px 0;
        }
        @media #{$queryPc} {
            margin: 0 0 60px 0;
        }
        .year {
            font-size: 1.25em;
            font-weight: bold;
            margin: 0 0 20px 0;
        }
    }
    .wrap-general_nav {
        width: 100%;
        @media #{$querySp} {
            margin: 0 0 23px 0;
        }
        @media #{$queryPc} {
            margin: 0 0 46px 0;
        }
        a {
            color: #000;
            float:left;
            display: block;
            padding: 10px 0;
            font-size: 1em;
            font-weight: bold;
            border:1px solid #000;
            box-sizing: border-box;
            text-align: center;
            &:hover {
                color: #FFF;
                background: $clrKey;
                -webkit-transition: all .2s ease-in-out 0s;
                transition: all .2s ease-in-out 0s;
            }
            @media #{$querySp} {
                width: 48%;
                margin: 0 2% 10px 0;
                &:nth-child(2n) {
                    margin: 0 0 10px 2%;
                }
            }
            @media #{$queryPc} {
                width: 160px;
                margin: 0 4px 4px 0;
                &:nth-child(6n) {
                    margin: 0 0 4px 0;
                }
            }
        }
    }
    .wrap-general_box {
        @media #{$querySp} {
            margin: 0 0 70px 0;
        }
        @media #{$queryPc} {
            margin: 0 0 140px 0;
        }
        &.col_1 {
            .wrap-g_b_right {
                width: 100%;
                p {
                    a {
                        color: #ff5611;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
        &.col_2 {
            .wrap-g_b_left {
                float: left;
                @media #{$querySp} {
                    width: 100%;
                    margin: 0 0 16px 0;
                }
                @media #{$queryPc} {
                    width: 399px;
                }
                img {
                    width: 100%;
                }
            }
            .wrap-g_b_right {
                float: left;
                @media #{$querySp} {
                    width: 100%;
                }
                @media #{$queryPc} {
                    width: 551px;
                    margin: 0 0 0 30px;
                }
            }
        }
    }
    .wrap-earnings_nav {
        width: 100%;
        @media #{$querySp} {
            margin: 0 0 23px 0;
        }
        @media #{$queryPc} {
            margin: 0 0 46px 0;
        }
        a {
            color: #000;
            float:left;
            display: block;
            padding: 10px 0;
            font-size: 1em;
            font-weight: bold;
            border: 1px solid #000;
            box-sizing: border-box;
            text-align: center;
            &:hover {
                color: #FFF;
                background: $clrKey;
                -webkit-transition: all .2s ease-in-out 0s;
                transition: all .2s ease-in-out 0s;
            }
            @media #{$querySp} {
                width: 48%;
                margin: 0 2% 10px 0;
                &:nth-child(2n){
                    margin: 0 0 10px 2%;
                }
                // &:nth-child(6n) {
                //     margin: 0 0 10px 0;
                // }
            }
            @media #{$queryPc} {
                width: 119px;
                margin: 0 4px 0 0;
                &:last-child {
                    margin: 0;
                }
            }
        }
    }
    .wrap-earnings-year {
        h5.midashi {
            font-size: 1.5em;
            font-weight: bold;
            @media #{$querySp} {
                margin: 0 0 8px 0;
            }
            @media #{$queryPc} {
                margin: 0 0 16px 0;
            }
        }
        .wrap-earnings_box {
            border-top: 1px solid #000;
            @media #{$querySp} {
                padding: 15px 0 0 0;
                margin: 0 0 20px 0;
            }
            @media #{$queryPc} {
                padding: 30px 0 0 0;
                margin: 0 0 40px 0;
            }
            &.col_1 {
                .wrap-e_b_right {
                    width: 100%;
                    .wrap-table {
                        width:100%;
                    }
                }
            }
            &.col_2 {
                .wrap-e_b_left {
                    float: left;
                    @media #{$querySp} {
                        width: 100%;
                        margin: 0 0 16px 0;
                    }
                    @media #{$queryPc} {
                        width: 399px;
                    }
                    .wrap-img {
                        width:75%;
                        img {
                            width: 100%;
                        }
                    }
                }
                .wrap-e_b_right {
                    float: left;
                    @media #{$querySp} {
                        width: 100%;
                    }
                    @media #{$queryPc} {
                        width: 551px;
                        margin: 0 0 0 30px;
                    }
                }
            }
        }
    }
// individual_investor
    .wrap-individual_content {
        h5.midashi {
            font-size: 1.5em;
            font-weight: bold;
            @media #{$querySp} {
                margin: 0 0 8px 0;
            }
            @media #{$queryPc} {
                margin: 0 0 16px 0;
            }
        }
        .wrap-new_individual {

        }
        .wrap-individual_box {
            border-top: 1px solid #000;
            @media #{$querySp} {
                padding: 15px 0 0 0;
                margin: 0 0 20px 0;
            }
            @media #{$queryPc} {
                padding: 30px 0 0 0;
                margin: 0 0 40px 0;
            }
        }
    }
    .col_1 {
        .tbl_type_01 {
            width: 100%;
            th {
                width: 10%;
                @media #{$querySp} {
                    width: 20%;
                    padding: 0 0 4px 0;
                }
                @media #{$queryPc} {
                    width: 10%;
                    padding: 0 0 8px 0;
                }
            }
            td {
                @media #{$querySp} {
                    width: 80%;
                    padding: 0 0 4px 0;
                }
                @media #{$queryPc} {
                    width: 90%;
                    padding: 0 0 8px 0;
                }
            }
        }
    }
    .col_2 {
        .tbl_type_01 {
            width: 100%;
            th {
                width: 20%;
                @media #{$querySp} {
                    padding: 0 0 4px 0;
                }
                @media #{$queryPc} {
                    padding: 0 0 8px 0;
                }
            }
            td {
                width: 80%;
                @media #{$querySp} {
                    padding: 0 0 4px 0;
                }
                @media #{$queryPc} {
                    padding: 0 0 8px 0;
                }
            }
        }
    }
    .wrap-table {
        .tbl_type_01 {
            a {
                position: relative;
                color: #ff5611;
                padding: 0 0 0 24px;
                &:hover{
                    text-decoration: underline;
                }
                &:after {
                    position: absolute;
                    display: inline-block;
                    content: "";
                    background: url("../images/ico_pdf.png") no-repeat center top;
                    @media #{$querySp} {
                        left: 0;
                        top: 0;
                        width: 18px;
                        height: 16px;
                        background-size: 12px auto;
                    }
                    @media #{$queryPc} {
                        left: 0px;
                        top: -2px;
                        width: 18px;
                        height: 22px;
                        background-size: 18px auto;
                    }
                }
                &.movie {
                    padding: 0;
                    &:after {
                        position: absolute;
                        display: inline-block;
                        content: "";
                        background: url("../images/ir/report/briefing_02.jpg") no-repeat center top;
                        @media #{$querySp} {
                            top: 0;
                            right: -20px;
                            left: initial;
                            width: 20px;
                            height: 20px;
                            background-size: 15px auto;
                        }
                        @media #{$queryPc} {
                            top: -2px;
                            right: -30px;
                            left: initial;
                            width: 20px;
                            height: 20px;
                            background-size: 20px auto;
                        }
                    }
                }
            }
            p {
                @media #{$querySp} {
                    margin: 0 0 4px 0;
                }
                @media #{$queryPc} {
                    margin: 0 0 8px 0;
                }
            }
            ul {
                li {
                    padding-left:1em;
                    text-indent:-1em;
                }
            }
        }
        .tbl_type_02 {
            width:100%;
            th {
                background: #efefef;
                padding: 12.5px 0;
                font-weight: bold;
                &:first-child {
                    @media #{$querySp} {
                        width:36%;
                        padding: 12.5px 0 12.5px 4%;
                    }
                    @media #{$queryPc} {
                        width:21%;
                        padding: 12.5px 0 12.5px 4%;
                    }
                }
                &:last-child {
                    @media #{$querySp} {
                        width:60%;
                    }
                    @media #{$queryPc} {
                        width:75%;
                    }
                }
            }
            td {
                border-bottom: 1px solid #000;
                padding: 20px 0;
                &:first-child {
                    @media #{$querySp} {
                        width:36%;
                        padding: 12.5px 0 12.5px 4%;
                    }
                    @media #{$queryPc} {
                        width:21%;
                        padding: 12.5px 0 12.5px 4%;
                    }
                }
                &:last-child {
                    @media #{$querySp} {
                        width:60%;
                    }
                    @media #{$queryPc} {
                        width:75%;
                    }
                }
            }
            a {
                position: relative;
                color: #ff5611;
                /*padding: 0 0 0 24px;*/
                &:hover{
                    text-decoration: underline;
                }
                &:after {
                    position: absolute;
                    display: inline-block;
                    content: "";
                    /*background: url("../images/ico_pdf.png") no-repeat center top;*/
                    @media #{$querySp} {
                        left: 0;
                        top: 0;
                        width: 18px;
                        height: 16px;
                        background-size: 12px auto;
                    }
                    @media #{$queryPc} {
                        left: 0;
                        top: -2px;
                        width: 18px;
                        height: 22px;
                        background-size: 18px auto;
                    }
                }
            }
        }
    }
}
