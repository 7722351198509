@charset "UTF-8";
/* ----------------------------------------------------------------
トクヤマ人を描くのみのスタイル
-----------------------------------------------------------------*/
/* トクヤマ人を描く
-----------------------------------------------------------------*/
.c-container--feature {
    @media #{$querySp} {
    }
    @media #{$queryPc} {
    }
    .c-inner--md--featuretop {
        @media #{$querySp} {
        }
        @media #{$queryPc} {
            padding: 0;
        }
    }
    .kv {
        @media #{$querySp} {
        }
        @media #{$queryPc} {
        }
        h2 {
            font-family: "Hiragino Mincho ProN W3", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
            @media #{$querySp} {
                font-size: 3rem;
            }
            @media #{$queryPc} {
                font-size: 5rem;
                letter-spacing: .2em;
            }
        }
    }
    .p-pdTopMore__Head {
        @media #{$querySp} {
            margin-bottom: 30px;
        }
        @media #{$queryPc} {
            margin-bottom: 50px;
        }
        .left {
            display: block;
            @media #{$querySp} {
                font-size: 1.2rem;
                padding: 5px;
            }
            @media #{$queryPc} {
                width: 150px;
                font-size: 1.6rem;
                letter-spacing: -.05em;
                padding: 15px 20px;
            }
        }
        .right {
            display: block;
            @media #{$querySp} {
                font-size: 1.2rem;
                padding: 5px;
            }
            @media #{$queryPc} {
                width: 850px;
                font-size: 1.6rem;
                letter-spacing: -.08em;
                padding: 15px 20px;
            }
        }
    }
    .section--feature {
        @media #{$querySp} {
        }
        @media #{$queryPc} {
        }
        h3 {
            font-weight: 600;
            letter-spacing: .04em;
            display: block;
            @media #{$querySp} {
                font-size: 1.6rem;
                margin-bottom: 20px;
                line-height: 24px;
            }
            @media #{$queryPc} {
                font-size: 2rem;
                margin-bottom: 20px;
                line-height: 36px;
            }
        }
    }
    .c-inner--feature {
        @media #{$querySp} {
            width: 95%;
            margin: 30px auto 30px;
        }
        @media #{$queryPc} {
            width: 1000px;
            margin: 100px auto 50px;
        }
        .ttl--feature {
            position: relative;
            background: #efefef;
            @media #{$querySp} {
            }
            @media #{$queryPc} {
            }
            .feature--num {
                background: #000;
                position:absolute; 
                text-align: center;
                @media #{$querySp} {
                    width: 30px;
                    height: 30px;
                    top: -30px;
                    left: 0;
                }
                @media #{$queryPc} {
                    width: 95px;
                    height: 95px;
                    top: -44.5px;
                    left: 30px;
                }
                span {
                    color: #fff;
                    font-weight: 600;
                    @media #{$querySp} {
                        font-size: 1.8rem;
                        line-height: 30px;
                    }
                    @media #{$queryPc} {
                        font-size: 4.8rem;
                        line-height: 95px;
                    }
                }
            }
            .wrap--feature {
                @media #{$querySp} {
                }
                @media #{$queryPc} {
                }
                .feature-left {
                    @media #{$querySp} {
                        padding: 0 20px;
                    }
                    @media #{$queryPc} {
                        float: left;
                        width: 50%;
                        padding-right: 30px;
                        padding-left: 30px;
                    }
                    h3 {
                        font-weight: 600;
                        border-bottom: solid 1px #000;
                        @media #{$querySp} {
                            font-size: 2rem;
                            padding: 20px 0 10px;
                        }
                        @media #{$queryPc} {
                            font-size: 3rem;
                            line-height: 1.4;
                            margin-bottom: 30px;
                            padding-top: 70px;
                            padding-bottom: 20px;
                        }
                    }
                    .wrap {
                        @media #{$querySp} {
                            margin-bottom: 0;
                            padding-bottom: 20px;
                        }
                        @media #{$queryPc} {
                        }
                        .wrap-left--name {
                            @media #{$querySp} {
                                text-align: center;
                                font-size: 1.4rem;
                                margin-bottom: 20px;
                            }
                            @media #{$queryPc} {
                                width: 70%;
                                font-size: 2rem;
                            }
                            span {
                                display: block;
                                @media #{$querySp} {
                                    font-size: 1.2rem;
                                }
                                @media #{$queryPc} {
                                    font-size: 1.6rem;
                                } 
                            }
                        }
                        .wrap-right--img {
                            @media #{$querySp} {
                                width: 40%;
                                margin: auto;
                            }
                            @media #{$queryPc} {
                                width: 30%;
                            }
                            img {
                                width: 100%;
                                height: auto;
                            }
                        }
                    }
                }
                .feature-right {
                    @media #{$querySp} {
                        width: 60%;
                        margin: 0 auto 10px;
                    }
                    @media #{$queryPc} {
                        float: right;
                        width: 50%;
                    }
                    .feature-right--img {
                       @media #{$querySp} {
                        }
                        @media #{$queryPc} {
                        } 
                        img {
                            width: 100%;
                            height: auto;
                            @media #{$querySp} {
                            }
                        }
                    }
                }
            }
        }
    }
    
    
/* トクヤマ人バナー
-----------------------------*/    
    .c-inner--featurebnr {
        @media #{$querySp} {
            margin: 50px auto 0;
        }
        @media #{$queryPc} {
            width: 1000px;
            margin: 100px auto 0;
        }  
        .featurebnr--list {
            @media #{$querySp} {
            }
            @media #{$queryPc} {
            } 
            li {
                position: relative;
                counter-increment: section;
                @media #{$querySp} {
                    width: 60%;
                    margin: 0 auto 20px;
                    text-align: center;
                }
                @media #{$queryPc} {
                    display: inline-block;
                    margin: 0 6px 30px 0; 
                }
                &:nth-child(3n) {
                    @media #{$querySp} {
                }
                    @media #{$queryPc} {
                        margin: 0 0 30px 0; 
                    }
                }
                .feature-img {
                    position: relative;
                    display: block;
                    @media #{$querySp} {
                    }
                    @media #{$queryPc} {
                    }
                    &:before {
                        color: #fff;
                        background: #000;
                        position: absolute;
                        left: 0;
                        top: 0;
                        display: marker;
                        content: counter(section, decimal-leading-zero);
                        font-weight: 600;
                        text-align: center;
                        @media #{$querySp} {
                            font-size: 1.3rem;
                            width: 20px;
                            height: 20px;
                            line-height: 1.4;
                        }
                        @media #{$queryPc} {
                            font-size: 1.8rem;
                            width: 30px;
                            height: 30px;
                            line-height: 1.6;
                        }
                    }
                    img {
                        @media #{$querySp} {
                            width: 100%;
                            height: auto;
                        }
                        @media #{$queryPc} {
                        }
                    }
                }
                .feature-dep {
                    color: #000;
                    display: block;
                    text-align: center;
                    line-height: 1;
                    @media #{$querySp} {
                        font-size: 1rem;
                        padding-top: 5px;
                    }
                    @media #{$queryPc} {
                        font-size: 1.2rem;
                        padding-top: 12px;
                    }
                    .feature-name {
                        display: block;
                        font-weight: 600;
                        @media #{$querySp} {
                            font-size: 1.2rem;
                            padding-top: 5px;
                        }
                        @media #{$queryPc} {
                            font-size: 1.6rem;
                            padding-top: 10px;
                        }
                    }
                }
            }
        }
    }
    .clearfix::after {
       content: "";
       display: block;
       clear: both;
    }
}

