@charset "UTF-8";
/* ----------------------------------------------------------------
資料ダウンロードのみのスタイル
-----------------------------------------------------------------*/
.p-dlItemWrap {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		width: 100%;
		overflow: hidden;
	}
}

.p-dlItemArea {
	@media #{$querySp} {
		display: flex;
		flex-wrap: wrap;
	}
	@media #{$queryPc} {
		overflow: hidden;
		zoom: 1;
		margin-right: -20px;
	}
}

.p-dlItemBox {
	@media #{$querySp} {
		width: 50%;
	}
	@media #{$queryPc} {
		width: 180px;
		float: left;
		margin-right: 20px;
	}
	&:nth-child(odd) {
		@media #{$querySp} {
			padding-right: 8px;
		}
		@media #{$queryPc} {
		}
	}
	&:nth-child(even) {
		@media #{$querySp} {
			padding-left: 8px;
		}
		@media #{$queryPc} {
		}
	}
}

.p-dlItem {
	display: block;
	@media #{$querySp} {
		margin-bottom: 16px;
	}
	@media #{$queryPc} {
		margin-bottom: 24px;
		@include trans($duration: .2s,$delay: 0s);
	}
	&:hover {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			@include opacity(.5);
		}
	}
}

.p-dlItem__photo {
	width: 100%;
	border: 1px solid #ddd;
	@media #{$querySp} {
		margin-bottom: 8px;
	}
	@media #{$queryPc} {
		margin-bottom: 8px;
	}
	img {
		width: 100%;
		height: auto;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
}

.p-dlItem__txt {
	text-align: center;
	color: #000;
	@media #{$querySp} {
		font-size: 1.1rem;
	}
	@media #{$queryPc} {
		font-size: 1.2rem;
	}
	span {
		position: relative;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
		&:before {
			content: "";
			display: inline-block;
			@media #{$querySp} {
				width: 16px;
				height: 20px;
				margin-bottom: -4px;
				padding-left: 20px;
				background: url("../images/ico_pdf.png") no-repeat left top;
			 	background-size: 16px auto;
			}
			@media #{$queryPc} {
				width: 18px;
				height: 22px;
				margin-bottom: -5px;
				padding-left: 24px;
				background: url("../images/ico_pdf.png") no-repeat left top;
			 	background-size: 18px auto;
			}
		}
	}
}

.p-xxxxxxxx {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

.p-xxxxxxxx {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}





















