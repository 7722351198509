@charset "UTF-8";
/* ----------------------------------------------------------------
 ir
-----------------------------------------------------------------*/
.c-container--ir {
    .c-inner--md {
        padding: 0;
    }
    .c-inner--md.c-inner--980{
        padding: 0 10px;
    }
}
.cf {
    min-height: 1px;
    &::after {
        content: "";
        display: block;
        clear: both;
        height: 0;
        visibility: hidden;
    }
}
.sp-only {
    @media #{$querySp} {
        display: block;
    }
    @media #{$queryPc} {
        display: none;
    }
}
.mt120 {
    @media #{$querySp} {
        margin-top: 60px;
    }
    @media #{$queryPc} {
        margin-top: 120px;
    }
}
.ml20 {
    @media #{$querySp} {
        margin-left: 10px;
    }
    @media #{$queryPc} {
        margin-left: 20px;
    }
}
.mb20 {
    @media #{$querySp} {
        margin-bottom: 10px;
    }
    @media #{$queryPc} {
        margin-bottom: 20px;
    }
}
.mb40 {
    @media #{$querySp} {
        margin-bottom: 20px;
    }
    @media #{$queryPc} {
        margin-bottom: 40px;
    }
}
.mb120 {
    @media #{$querySp} {
        margin-bottom: 60px !important;
    }
    @media #{$queryPc} {
        margin-bottom: 120px !important;
    }
}
.font_b {
    font-weight: bold;
}
.title_01 {
    font-size: 1.5em;
    @media #{$querySp} {
        margin-bottom: 20px !important;
    }
    @media #{$queryPc} {
        margin-bottom: 40px !important;
    }
}
.wrap--ir {
    .wrap-left {
        @media #{$querySp} {
            width: 100%;
        }
        @media #{$queryPc} {
            width: 543px;
            display: block;
        }
        img {
            @media #{$querySp} {
                width: 100%;
                height: auto;
            }
        }
    }
    .wrap-right {
        @media #{$querySp} {
            width: 95%;
            margin: 30px auto;
        }
        @media #{$queryPc} {
            width: 437px;
        }
        h3 {
            font-weight: 600;
            margin-bottom: 20px;
            @media #{$querySp} {
                font-size: 2rem;
            }
            @media #{$queryPc} {
                font-size: 2.4rem;
                line-height: 1;
            }
            span {
                font-weight: normal;
                padding-left: 10px;
                @media #{$querySp} {
                    font-size: 1.2rem;
                    padding-left: 5px;
                }
                @media #{$queryPc} {
                    font-size: 1.4rem;
                }
            }
        }
    }
    .midashi {
        font-size: 2em;
        margin: 0 auto;
    }
    h3.midashi {
        text-align: center;
        letter-spacing: .2em;
        font-weight: bold;
        @media #{$querySp} {
            font-size: 2.06rem;
            margin: 0 auto 28px;
        }
        @media #{$queryPc} {
            font-size: 3.0rem;
            margin: 0 auto 56px;
        }
    }
    h5.midashi {
        font-size: 2rem;
        margin-bottom: 20px;
        line-height: 36px;
        font-weight: 600;
        letter-spacing: .11em;
        @media #{$querySp} {
            margin:0 0 14px 0;
        }
        @media #{$queryPc} {
            margin:0 0 28px 0;
        }
    }
    h5.left-ruled-line {
        position: relative;
        font-size: 1.24em;
        font-weight: bold;
        position: relative;
        display: inline-block;
        padding: 0 0 0 40px;
        &::before {
            content: '';
            position: absolute;
            top: 42%;
            left:0;
            display: inline-block;
            width: 27px;
            height: 4px;
            background: $clrKey;
        }
        @media #{$querySp} {
            margin:0 0 10px 0;
        }
        @media #{$queryPc} {
            margin:0 0 20px 0;
        }

    }
    .wrap-btn {
        &.type_01 {
            a {
                display: inline-block;
                padding: 20px 15px;
                text-align: center;
                background: #ADB0CE;
                text-align: center;
                color: #FFF;
                font-family: 'Noto Sans Japanese', 'Hiragino Kaku Gothic ProN', 'Meiryo', 'MS PGothic', sans-serif;
                text-decoration: none !important;
                webkit-transition: .2s;
                -moz-transition: .2s;
                -khtml-transition: .2s;
                -o-transition: .2s;
                transition: .2s;
                p {
                    i {
                        color: #5D639E;
                        margin: 0 0 0 10px;
                    }
                }
                @media #{$querySp} {
                    margin:0 0 10px 0;
                    width: 100%;
                }
                @media #{$queryPc} {
                    margin:0 0 20px 0;
                    min-width: 545px;
                }
                &:hover{
                    background-color: #5d639e;
                }
            }
        }
        &.type_02 {
            a {
                width: 100%;
                display: inline-block;
                padding: 20px 15px;
                text-align: center;
                border: 1px solid #000;
                text-align: center;
                color: #000;
                font-size: 2.4rem;
                font-weight: bold;
                text-decoration: none !important;
                webkit-transition: .2s;
                -moz-transition: .2s;
                -khtml-transition: .2s;
                -o-transition: .2s;
                transition: .2s;
                @media #{$querySp} {
                    margin:0 0 10px 0;
                }
                @media #{$queryPc} {
                    margin:0 0 20px 0;
                }
                &:hover{
                    background-color: #5d639e;
                    color: #fff;
                    opacity: 1;
                }
            }
            p {
                text-align: center;
            }
        }
    }
    .p-reschTopListItem__headLg {
        &.link {
            @media #{$querySp} {
                padding-right: 22px;
                font-size: 1.8rem;
                background: url(../images/ico_blank.svg) no-repeat right center;
                background-size: 14px auto;
            }
            @media #{$queryPc} {
                padding-right: 28px;
                font-size: 1.8rem;
                background: url(../images/ico_blank.svg) no-repeat right center;
                background-size: 14px auto;
            }
        }
    }
    .wrap-table {
        &.t-scroll {
            @media #{$querySp} {
                overflow-x: scroll;
                white-space: nowrap;
                -webkit-overflow-scrolling: touch;
            }
            @media #{$queryPc} {

            }
        }
    }

}
.blank-link {
    li {
        background: #a2a8da;
        position: relative;
        margin-bottom: 10px;
        a {
            color: #fff;
            font-size: 1.4rem;
            font-weight: 600;
            display: block;
            padding: 15px 45px;
            &:after {
                content: "";
                display: inline-block;
                background: url("../images/ico_arrow_r_wh.svg") no-repeat center top;
                background-size: 6px auto;
                position: absolute;
                right: 10px;
                top: 40%;
                @media #{$querySp} {
                    width: 5px;
                    height: 12px;
                }
                @media #{$queryPc} {
                    width: 6px;
	                height: 10px;
                }
            }
            &:before {
                @media #{$querySp} {
                    position: absolute;
                    left: 15px;
                    display: inline-block;
                    content: "";
                    width: 18px;
                    height: 22px;
                    background: url("../images/ico_pdf_wh.png") no-repeat center top;
                    background-size: 18px auto;
                }
                @media #{$queryPc} {
                    position: absolute;
                    left: 15px;
                    display: inline-block;
                    content: "";
                    width: 18px;
                    height: 22px;
                    background: url("../images/ico_pdf_wh.png") no-repeat center top;
                    background-size: 18px auto;
                }
            }
            &:hover {
                background-color: #5d639e;
            }
        }
        &.anothersite-link {
            a {
                background: #FFF;
                color: #000;
                &:hover {
                    background-color: #5d639e;
                    color: #FFF;
                }
                &:after {
                    background: url("../../images/ico_arrow_r_bk.svg") no-repeat center top;
                }
            }
        }
        &.anothersite-link--02 {
            a {
                border: solid 1px #000;
                color: #000;
                background: #FFF;
                padding: 15px 15px;
                &:before {
                    background: none;
                }
                &:after {
                    background: url("../../images/ico_arrow_r_wh.svg") no-repeat center top;
                }
                &:hover {
                    background-color: #5d639e;
                    color: #FFF;
                }
            }
        }
    }
    dt {
        font-size: 1.5rem;
    }
    dd {
        a {
            color: #000;
            font-size: 1.5rem;
            -webkit-transition: all .1s ease-in-out 0s;
            transition: all .1s ease-in-out 0s;
            font-weight: 600;
            display: block;
            padding: 0 45px 0 0;
            &.pdf {
                &:before {
                    @media #{$querySp} {
                        position: absolute;
                        left: 15px;
                        display: inline-block;
                        content: "";
                        width: 18px;
                        height: 22px;
                        background: url("../images/ico_pdf.png") no-repeat center top;
                        background-size: 18px auto;
                    }
                    @media #{$queryPc} {
                        position: absolute;
                        left: 15px;
                        display: inline-block;
                        content: "";
                        width: 18px;
                        height: 22px;
                        background: url("../images/ico_pdf.png") no-repeat center top;
                        background-size: 18px auto;
                    }
                }
            }
            &:hover {
                opacity: 0.7;
            }
            &:after {
                content: "";
                display: inline-block;
                @media #{$querySp} {
                    margin-left: 8px;
                    width: 6px;
                    height: 6px;
                    border: 0;
                    border-top: solid 1px #000;
                    border-right: solid 1px #000;
                    transform: rotate(45deg);
                }
                @media #{$queryPc} {
                    margin-left: 16px;
                    width: 8px;
                    height: 8px;
                    border: 0;
                    border-top: solid 2px #000;
                    border-right: solid 2px #000;
                    transform: rotate(45deg);
                }
            }
        }
    }
}
/* ここの記述を使用しているところはクラス追加指定して使用するように。この記述だと他部分でも適応される */
// a[href$=".pdf"]:before {
//                     @media #{$querySp} {
//                             position: absolute;
// 				            left: 15px;
// 				            display: inline-block;
// 				            content: "";
// 				            width: 18px;
// 				            height: 22px;
// 				            background: url("../images/ico_pdf.png") no-repeat center top;
// 				            background-size: 18px auto;
// 		                }
//                     @media #{$queryPc} {
//                         position: absolute;
// 				        left: 15px;
// 				        display: inline-block;
// 				        content: "";
// 				        width: 18px;
// 				        height: 22px;
// 				        background: url("../images/ico_pdf.png") no-repeat center top;
// 				        background-size: 18px auto;
//                     }
//                 }
.anothersite-link {
    background: #a2a8da;
    border: solid 1px #000;
    a {
        position: relative;
        line-height: 1.3;
        @media #{$querySp} {
            font-size: 1.6rem !important;
            padding: 9px 50px 9px 50px !important;
        }
        @media #{$queryPc} {
            font-size: 1.8rem !important;
            padding: 9px 50px 9px 60px !important;
        }
        span {
            font-size: 1.2rem;
        }
        &:before {
            content: "";
            display: inline-block;
            background: url("../images/ir/index/ico-rosoku.png") no-repeat center center !important;
            position: absolute;
            left: 10px;
            @media #{$querySp} {
                background-size: 25px auto;
                width: 25px;
                height: 23px;
                top: 17px;
            }
            @media #{$queryPc} {
                background-size: 38px auto;
                width: 38px;
                height: 36px;
                top: 15px;
            }
        }
        &:after {
        }
        &:hover {
            @media #{$querySp} {
            }
            @media #{$queryPc} {
            }
        }

    }
}
.news-wrap {
    .blank-link {
        @media #{$querySp} {
            margin-top: 60px;
        }
        @media #{$queryPc} {
            margin-bottom: 100px;
        }
        dl {
            border-bottom: 1px solid #ccc;
            @media #{$querySp} {
                padding: 16px 0;
            }
            @media #{$queryPc} {
                display: flex;
                align-items: center;
                /*flex-wrap: wrap;*/
                padding: 15px 0 5px;
            }
            &:last-child {
                border: none;
            }
        }
        dt {
            @media #{$querySp} {
                font-size: 1.4rem;
                margin-bottom: 10px;
            }
            @media #{$queryPc} {
                margin-right: 30px;
            }
        }
        dd {
            position: relative;
            @media #{$querySp} {
                font-size: 1.4rem;
            }
            @media #{$queryPc} {
                flex: 1;
            }
            a {
                color: #000;
                display: block;
                font-weight: normal;
                @media #{$querySp} {
                    font-size: 1.4rem;
                }
                &.pdf {
                    padding-left: 30px;
                }
                &:hover {
                    opacity: 0.5;
                }
            }
            a[href$=".pdf"]:before {
                left: 0;
            }
        }
    }
}
.c-inner--l {
    @media #{$querySp} {
        width: 95%;
        margin: 0 auto 40px;
    }
    @media #{$queryPc} {
        margin-bottom: 70px;
    }
    .wrap {
        @media #{$querySp} {
            margin-bottom: 0;
            }
        @media #{$queryPc} {
            margin-bottom: 20px;
        }
        .wrap-menu {
            border: solid 1px #000;
            @media #{$querySp} {
                margin-bottom: 30px;
                padding: 20px;
            }
            @media #{$queryPc} {
                width: 32%;
                padding: 30px 40px;
            }
            h3 {
                position: relative;
                a {
                    color: #000;
                    &:after {
                        top: 40%;
                    }
                }
            }
            li {
                position: relative;
                @media #{$querySp} {
                    margin-bottom: 10px;
                }
                @media #{$queryPc} {
                    margin-bottom: 5px;
                }
                a {
                    color: #000;
                    font-size: 1.6rem;
                    line-height: 1.6;
                    &:after {
                        top: 34%;
                    }
                }
            }
            a {
                @include trans($duration: .1s,$delay: 0s);
                &:after {
                    display: inline-block;
				    content: "";
                    width: 4px;
                    height: 14px;
                    margin-left: 10px;
                    background: url("../images/ico_arrow_r_bk.svg") no-repeat center center;
				    background-size: 4px auto;
                    /*position: absolute;*/
                    @media #{$querySp} {
                    }
                    @media #{$queryPc} {
                    }
		}
            }
            a:hover {
                /*opacity: 0.5;*/
                @media #{$queryPc} {
                    text-decoration: underline;
                    color: $clrKey;
                }
            }

        }
    }
}
