@charset "UTF-8";
/* ----------------------------------------------------------------
コーポレート・ガバナンスのみのスタイル
-----------------------------------------------------------------*/
.c-container--faq {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
    .c-inner--md {
        @media #{$querySp} {
            margin-bottom: 50px;
        }
        @media #{$queryPc} {
            margin-bottom: 100px;
        }
        .link-list {
            
            @media #{$querySp} {
                margin-bottom: 50px;
            }
            @media #{$queryPc} {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                justify-content: space-between;
                margin-top: 150px;
            }
            li {
                
                @media #{$querySp} {
                    width: 50%;
                    float: left;
                    }
                @media #{$queryPc} {
                    border-right: solid 1px #000; 
                    line-height: 1;
                }
                &:last-child {
                    @media #{$querySp} {
                    }
                    @media #{$queryPc} {
                            border: none;
                    }
                }
                &:nth-child(2n) {
                    @media #{$querySp} {
                        float: right;
                    }
                    @media #{$queryPc} {
                    }
                }
                a {
                    color: #000;
                    font-weight: 600;
                    @media #{$querySp} {
                        font-size: 1.4rem;
                        text-align: center;
                        display: block;
                        padding: 10px 0;
                    }
                    @media #{$queryPc} {
                        font-size: 1.6rem;
                        padding: 0 30px;
                    }
                    
                    &:hover {
                        span {
                            border-bottom: solid 1px #5d639e;
                            @media #{$querySp} {
                            }
                            @media #{$queryPc} {
                            }
                        }
                    }
                }
            }
            &.clearfix::after {
               content: "";
               display: block;
               clear: both;
            }
        }
        .primary_head {
            clear: both;
            h3 {
                   font-weight: 600;
                   background: #efefef;
                   text-align: center;
                   @media #{$querySp} {
                       font-size: 2rem;
                       line-height: 2.2;
                       margin-bottom: 20px;
                   }
                   @media #{$queryPc} {
                       font-size: 2.4rem;
                       line-height: 2.6;
                       margin-top: 80px;
                       margin-bottom: 50px;
                   }
               }
        }
        .block2_sec_intro_row_q {
                h4 {
                    color: #202020;
                    position: relative;
                    font-weight: 600;
                    @media #{$querySp} {
                       font-size: 1.8rem;
                       margin-bottom: 20px;
                        padding: 15px 0 0 45px;
                   }
                   @media #{$queryPc} {
                       font-size: 2.2rem;
                       margin-bottom: 20px;
                       padding: 25px 0 0 55px;
                   }
            }
            span {
                font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif; 
                font-weight: normal;
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                @media #{$querySp} {
                       font-size: 3.3rem;
                       margin-bottom: 20px;
                   }
                   @media #{$queryPc} {
                       font-size: 4.3rem;
                   }

            }
        }
        .block2_sec_intro_row_a {
            width: 100%;
            border-bottom: solid 1px #000;
            @media #{$querySp} {
                margin-bottom: 20px;
                padding-bottom: 40px;
            }
            @media #{$queryPc} {
                margin-bottom: 20px;
                padding-bottom: 50px;
            }
            p {
                /*font-weight: bold;*/
                position: relative;
                margin: 0 0 0 0;
                @media #{$querySp} {
                    padding: 0 0 0 45px;
                }
                @media #{$queryPc} {
                    padding: 0 0 0 55px;
                    font-size: 1.6rem;
                }
                span {
                    font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif; 
                    font-weight: normal;
                    display: block;
                    position: absolute;
                    left: 0;
                    color: #5d639e;
                    @media #{$querySp} {
                        font-size: 3.3rem;
                        top: -10px;
                    }
                    @media #{$queryPc} {
                        font-size: 4.3rem;
                        top: -20px;
                    }
                }
            }
            a {
                color: #de5b11;
                &:hover {
                    text-decoration: underline;
                }
            }
            &.brnone {
                border: none !important
            }
        }
    }
}
