@charset "UTF-8";
/* ----------------------------------------------------------------
コーポレート・ガバナンスのみのスタイル
-----------------------------------------------------------------*/
.c-inner--governance {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
    .u-fs12 {
    font-weight: normal;
}
    .lead {
        /*font-size: 1.6rem;*/
        /*font-weight: 600;*/
        text-align: center;
        @media #{$querySp} {
        }
        @media #{$queryPc} {
        }
    }
    .btn {
        position: relative;
        @media #{$querySp} {
            margin: 30px 0;
        }
        @media #{$queryPc} {
            margin: 50px 0;
            padding-bottom: 50px;
        }
        a {
            @media #{$querySp} {
                padding-right: 30px;
                font-size: 1.6rem;
        }
            @media #{$queryPc} {
                width: 360px;
                text-align: left;
                padding-left: 30px;
                position: absolute;
                right: 0;
            }
            a:before {
                @media #{$querySp} {
            }
                @media #{$queryPc} {
                    right: 0;
                }
            }
            &[href$=".pdf"] {
                &:before {
                    
                    @media #{$querySp} {
                        top: 18px;
                        right: 25px;
                        left: auto;
                    }
                    @media #{$queryPc} {
                        top: 20px;
                        right: 40px;
                        left: auto;
                    }
                }
            }
            &:after {
                @media #{$querySp} {
                    right: 10px;
            }
                @media #{$queryPc} {
                    right: 15px;
                }
            }
        }
        
    }
    .c-governance {
        /*font-weight: 600;*/
        @media #{$querySp} {
            margin-bottom: 50px;
        }
        @media #{$queryPc} {
            margin-bottom: 50px;
        }
        p {
            
        }
        h3 {
                font-weight: 600;
                background: #efefef;
                text-align: center;
                @media #{$querySp} {
                    font-size: 2rem;
                    line-height: 2.2;
                    margin-bottom: 20px;
                }
                @media #{$queryPc} {
                    font-size: 2.4rem;
                    line-height: 2.6;
                    margin-top: 80px;
                    margin-bottom: 50px;
                }
            }
        h4 {
                font-weight: 600;
                @media #{$querySp} {
                    font-size: 1.6rem;
                    margin-bottom: 10px;
                }
                @media #{$queryPc} {
                    font-size: 1.8rem;
                    margin-bottom: 10px;
                }
            }
        .tblscroller {
            @media #{$querySp} {
                    /*font-size: 1.6rem;*/
                    margin-bottom: 10px;
                }
                @media #{$queryPc} {
                    display: none;
                }
            img {
                @media #{$querySp} {
                    width: 22px;
                    position: relative;
                    top: -1px;
                }
                @media #{$queryPc} {
                    display: none;
                }
            }
        }
        table {
            @media #{$querySp} {
            }
            @media #{$queryPc} {
            }
            th {
                /*font-size: 1.6rem;*/
                font-weight: 600;
            }
            td {
                /*font-size: 1.6rem;*/
                font-weight: normal;
                a {
                    color: #000;
                    &:hover {
                        text-decoration: underline;
                    } 
                }
            }
            &.table--pattern01 {
                @media #{$querySp} {
                    width: 100%;
                    margin-bottom: 10px;
                }
                @media #{$queryPc} {
                    margin-bottom: 10px;
                }
                tr {
                    border-bottom: solid 1px #000;
                    th {
                        @media #{$querySp} {
                            width: 100%;
                            display: block;
                            padding: 10px 0 5px;

                        }
                        @media #{$queryPc} {
                            width: 38%;
                            padding: 20px 40px 20px 30px;
                        }
                    }
                    td {
                        @media #{$querySp} {
                            width: 100%;
                            display: block;
                            padding: 0 0 10px;
                        }
                        @media #{$queryPc} {
                            padding: 20px 0;
                        }
                    }
                }
            }
        }
        .scroll-table {
            @media #{$querySp} {    
                overflow: scroll;
                      -webkit-overflow-scrolling: touch;
            }
            @media #{$queryPc} {
            }
            
            .table--scroller {
                border: 1px solid #cecece;
                
                @media #{$querySp} {
                    width: 1000px;
                    margin-bottom: 10px;
                       
                      
                }
                @media #{$queryPc} {
                    width: 100%;
                }
                tbody {
                    @media #{$querySp} {
                        width: 100%;
                          display:table;
                    }
                    @media #{$queryPc} {
                    }
                    
                
                th {
                    color: #202020;
                    background: #eeeeee;
                    border: 1px solid #cecece;
                        /*font-size: 1.6rem;*/
                    font-weight: normal;
                        text-align: center;
                        vertical-align: middle;
                        padding: 10px 15px;
                    @media #{$querySp} {
                    }
                    @media #{$queryPc} {
                    }
                    &:last-child {
                        padding: 0;
                        .table {
                            width: 100%;
                            border: none !important;
                            border-top: 1px solid #cecece !important;
                            th {
                                width: 50%;
                                border: none !important;
                                border-right: 1px solid #cecece !important;
                                &:last-child {
                                    border-right: none !important;
                                }
                            }
                        }
                    }
                    p {
                        padding: 10px 15px;
                    }
                    &:nth-child(4) {
                        @media #{$querySp} {
                            width: 360px;
                        }
                        @media #{$queryPc} {
                            width: 360px;
                        }
                    }
                    
                }
                td {
                    border: 1px solid #cecece;
                        /*font-size: 1.6rem;*/
                        text-align: center;
                        vertical-align: middle;
                        padding: 15px;
                    @media #{$querySp} {
                    }
                    @media #{$queryPc} {
                        
                    }
                    &:nth-child(4) {
                        text-align: left;
                        @media #{$querySp} {
                        }
                        @media #{$queryPc} {
                            width: 360px;
                        }
                        
                        
                    }
                    &:nth-child(5) {
                        @media #{$querySp} {
                            /*width: 141px;*/
                        }
                        @media #{$queryPc} {
                        }
                    }
                }
            }
                &.table--pattern02 {
                    tr {
                        th {
                            width: 33%;
                        }
                    }
                }
            }
        }
        }
        .intro_sec2_in1 {
            font-weight: normal;
            @media #{$querySp} {
                margin-bottom: 30px;
            }
            @media #{$queryPc} {
                margin-bottom: 50px;
            }
        }
        .intro_sec2_in3 {
            font-weight: normal;
            li {
                @media #{$querySp} {
                    /*font-size: 1.6rem;*/
                    line-height: 1.6;
                }
                @media #{$queryPc} {
                    /*font-size: 1.6rem;*/
                    line-height: 2;
                }
            }
        }
        .intro_sec2_in4 {
            font-weight: normal;
            width: 100%;
            padding: 0 0 0 20px;
        }
        ol.kakko_s{
          padding:0 0 0 0;
          margin:0 0 0 2em;
          list-style-type:none;
            li{
              list-style-position: outside;
              counter-increment: num;
              text-indent: -1.5em;
              padding-left: 0em;
                &:before{
                  display: marker;
                  content: counter(num, upper-alpha) "）";
                }
            }
        }
        ol.kakko{
          padding:0 0 0 0;
          margin:0;
          list-style-type:none;
            li{
              list-style-position: outside;
              counter-increment: cnt;
              text-indent: -3em;
              padding-left: 1em;
                &:before{
                  display: marker;
                  content: "（" counter(cnt) "）";
                }
            }
        }
        ol.ast{
          padding:0 0 0 0;
          margin:0 0 0 2em;
          list-style-type:none;
            li{
              list-style-position: outside;
              counter-increment: a_cnt;
              text-indent: -1.5em;
              padding-left: 0em;
                &:before{
                  display: marker;
                  content: "*" counter(a_cnt) " ";
                
            }
        }
    }
    .companypg9_block7_intro_sec2 {
        font-weight: normal;
        ol {
            list-style-type: decimal;
            margin-top: 0;
            margin-left: 2em;
            @media #{$querySp} {
                margin-bottom: 30px;
            }
            @media #{$queryPc} {
                margin-bottom: 50px;
            }
            li {
                @media #{$querySp} {
                    /*font-size: 1.6rem;*/
                    line-height: 1.6;
                }
                @media #{$queryPc} {
                    /*font-size: 1.6rem;*/
                    line-height: 2;
                }
            }
            .intro_sec2_in2 {
                @media #{$querySp} {
                    /*font-size: 1.6rem;*/
                    line-height: 1.6;
                }
                @media #{$queryPc} {
                    /*font-size: 1.6rem;*/
                    line-height: 2;
                }
            }
        }
    }
}
