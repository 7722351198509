@charset "UTF-8";
/* ----------------------------------------------------------------
会社情報のみのスタイル
-----------------------------------------------------------------*/
.p-comTopBottomListWrap {
	width: 100%;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}
}

.p-comTopBottomListItem {
	@media #{$querySp} {
		margin-bottom: 20px;
	}
	@media #{$queryPc} {
		display: table;
		width: 24.062%;
		height: 80px;
		margin-bottom: 20px;
	}
	&:nth-last-child(-n+4) {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			margin-bottom: 0;
		}
	}
	&:last-child {
		@media #{$querySp} {
			margin-bottom: 0;
		}
		@media #{$queryPc} {
		}
	}
}

.p-comTopBottomListItem__link {
	border: 1px solid #000;
	text-align: center;
	font-weight: 600;
	color: #000;
	@media #{$querySp} {
		display: block;
		padding: 16px;
		font-size: 1.4rem;
		background: url("../images/ico_arrow_r_bk.svg") no-repeat right 16px center;
		background-size: 6px auto;
	}
	@media #{$queryPc} {
		display: table-cell;
		vertical-align: middle;
		font-size: 1.6rem;
		line-height: 1.4;
		background: url("../images/ico_arrow_r_bk.svg") no-repeat right 20px center;
		background-size: 4px auto;
		@include trans($duration: .2s,$delay: 0s);
		padding: 0 30px 0 20px;
	}
	&:hover {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			background: url("../images/ico_arrow_r_wh.svg") no-repeat right 20px center $clrKey;
			background-size: 4px auto;
			color: #fff;
			@include trans($duration: .2s,$delay: 0s);
		}
	}
}

/* ----------------------------------------------------------------
事業紹介のみのスタイル
-----------------------------------------------------------------*/
.p-comBusLead {
	font-weight: 600;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		width: 600px;
		margin: 0 auto;
		font-size: 1.6rem;
	}
}

.p-comBusListAllWrap {
	@media #{$querySp} {
		margin-bottom: 70px;
	}
	@media #{$queryPc} {
		margin-bottom: 170px;
	}
}

.p-comBusListWrap {
	position: relative;
	@media #{$querySp} {
		margin-bottom: 32px;
	}
	@media #{$queryPc} {
		display: flex;
		flex-direction: row-reverse;
		margin-bottom: 56px;
	}
	&--rev {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			flex-direction: row;
		}
	}
}

.p-comBusListPhoto {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
		position: absolute;
		top: 30px;
		left: 0;
		width: 45.428%;
		z-index: 1;
	}
	img {
		width: 100%;
		height: auto;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
	&--rev {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			left: initial;
			right: 0;
		}
	}
}

.p-comBusListTxt {
	background-color: #f4f4f4;
	@media #{$querySp} {
		padding: 30px 15px;
	}
	@media #{$queryPc} {
		width: 59%;
		padding: 48px 56px 48px 88px;
	}
	&--rev {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			padding: 48px 88px 48px 56px;
		}
	}
}

.p-comBusListTxt__box {
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

.p-comBusListTxt__head {
	border-bottom: 1px solid #000;
	line-height: 1;
	vertical-align: sub;
	@media #{$querySp} {
		padding-bottom: 15px;
		font-size: 1.7rem;
		letter-spacing: .1em;
	}
	@media #{$queryPc} {
		margin-bottom: 16px;
		padding-bottom: 20px;
		font-size: 2.6rem;
		letter-spacing: .15em;
	}
}

.p-comBusListTxt__more {
	background-color: #000;
	color: #fff;
	text-align: center;
	@media #{$querySp} {
		display: block;
		width: 80%;
		margin: 0 auto;
		padding: 12px;
		font-size: 1.1rem;
	}
	@media #{$queryPc} {
		display: inline-block;
		min-width: 230px;
		padding: 5px 16px;
		@include trans($duration: .2s,$delay: 0s);
	}
	&:after {
		content: "";
		display: inline-block;
		width: 4px;
		padding-left: 16px;
		background: url("../images/ico_arrow_r_wh.svg") no-repeat center top;
		background-size: 4px auto;
		@media #{$querySp} {
			height: 7px;
		}
		@media #{$queryPc} {
			height: 8px;
		}
	}
	&:hover {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			@include opacity(.5);
		}
	}
}





































