@charset "UTF-8";
/* ----------------------------------------------------------------
newsのみのスタイル
-----------------------------------------------------------------*/
.p-newsTabWrap {
	display: flex;
	@media #{$querySp} {
		flex-wrap: wrap;
	}
	@media #{$queryPc} {
		justify-content: space-between;
	}
}

.p-newsTabItem {
	@media #{$querySp} {
		width: 50%;
		margin-bottom: 4px;
	}
	@media #{$queryPc} {
		width: 160px;
	}
	&:nth-child(even) {
		@media #{$querySp} {
			padding-left: 2px;
		}
		@media #{$queryPc} {
		}
	}
	&:nth-child(odd) {
		@media #{$querySp} {
			padding-right: 2px;
		}
		@media #{$queryPc} {
		}
	}
}

.p-newsTabItem__link {
	display: block;
	border: 1px solid #000;
	text-align: center;
	letter-spacing: .1em;
	color: #000;
	@media #{$querySp} {
		padding: 5px;
	}
	@media #{$queryPc} {
		padding: 5px;
		@include trans($duration: .2s,$delay: 0s);
	}
	&:hover {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			background-color: $clrKey;
			color: #fff;
		}
	}
	&--active {
		background-color: $clrKey;
		color: #fff;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
}

.p-newsHeadLine {
	font-weight: 600;
	border-bottom: 1px solid #000;
	@media #{$querySp} {
		padding-bottom: 4px;
		font-size: 2.0rem;
	}
	@media #{$queryPc} {
		padding-bottom: 8px;
		font-size: 2.4rem;
	}
}

.p-newsListWrap {
	@media #{$querySp} {
		margin-bottom: 40px;
	}
	@media #{$queryPc} {
		margin-bottom: 72px;
	}
}

.p-newsListCont {
	display: flex;
	border-bottom: 1px solid #ccc;
	@media #{$querySp} {
		align-items: center;
		flex-wrap: wrap;
		padding: 16px 0;
	}
	@media #{$queryPc} {
		padding: 24px 0;
	}
}

.p-newsList__date {
	@media #{$querySp} {
		margin-right: 24px;
	}
	@media #{$queryPc} {
		margin-right: 80px;
		font-size: 1.5rem;
	}
}

.p-newsList__tag {
	color: #fff;
	text-align: center;
	@media #{$querySp} {
		width: 80px;
		height: 27px;
		line-height: 27px;
		font-size: 1.1rem
	}
	@media #{$queryPc} {
		width: 90px;
		height: 27px;
		margin-right: 16px;
		font-size: 1.3rem;
		line-height: 27px;
	}
	&--release {
		background-color: #c19ac1;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
	&--sds {
		background-color: #dc9898;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
	&--ir {
		background-color: #81b5c6;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
	&--csr {
		background-color: #7fbe98;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
	&--exhibition {
		background-color: #c6bd59;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
	&--other {
		background-color: #999999;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
	}
}

.p-newsList__txt {
	@media #{$querySp} {
		width: 100%;
		padding-top: 16px;
	}
	@media #{$queryPc} {
		flex: 1;
	}
}

.p-newsList__txtLink {
	color: #000;
	position:relative;
	padding-right: 24px;
	@media #{$querySp} {
		width: 100%;
		background: url("../images/ico_arrow_r_bk.svg") no-repeat right center;
		background-size: 6px auto;
	}
	@media #{$queryPc} {
		background: url("../images/ico_arrow_r_bk.svg") no-repeat right center;
		background-size: 8px auto;
		font-size: 1.5rem;
		@include trans($duration: .1s,$delay: 0s);
	}
	&:hover {
		@media #{$querySp} {
		}
		@media #{$queryPc} {
			@include opacity(.5);
		}
	}
	&--pdf {
		background: none;
		@media #{$querySp} {
		}
		@media #{$queryPc} {
		}
		&:after {
			content: "";
			display: inline-block;
			position: absolute;
			right: 0;
			@media #{$querySp} {
				width: 18px;
				height: 22px;
				background: url("../images/ico_pdf.png") no-repeat center top;
				background-size: 14px auto;
			}
			@media #{$queryPc} {
				width: 18px;
				height: 22px;
				background: url("../images/ico_pdf.png") no-repeat center top;
				background-size: 18px auto;
			}
		}
	}
}



.selectdiv {
	position: relative;
	@media #{$querySp} {
	}
	@media #{$queryPc} {
	}
}

select::-ms-expand {
	display: none;
}

.selectdiv:after {
	content: '<>';
	font: 17px "Consolas", monospace;
	color: #333;
	transform: rotate(90deg);
	right: 11px;
	padding: 0 0 2px;
	border-bottom: 1px solid #000;
	position: absolute;
	pointer-events: none;
	@media #{$querySp} {
		top: 5px;
	}
	@media #{$queryPc} {
		top: 9px;
	}
}

.selectdiv select {
	appearance: none;
	display: block;
	width: 100%;
	max-width: 320px;
	padding: 0px 24px;
	line-height: 1.75;
	background-color: #ffffff;
	background-image: none;
	border: 1px solid #000;
	word-break: normal;
	text-align: center;
	@media #{$querySp} {
		height: 31px;
	}
	@media #{$queryPc} {
		height: 40px;
	}
}













































