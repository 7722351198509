@charset "UTF-8";
/* ----------------------------------------------------------------
 ir/business_policy
-----------------------------------------------------------------*/
.c-container--ir {
    .c-inner--md {
        padding: 0;
        @media #{$querySp} {
            width: 95%;
            margin: 0 auto 40px;
        }
    }
    .c-inner--md.c-inner--980{
        padding: 0 10px;
    }
}

.wrap--ir {
    &.wrap--ir_business_policy {
        .wrap-left {
            @media #{$querySp} {
                width: 100%;
                .wrap-img {
                    &.left-img {
                        width: 50%;
                        margin: 0 auto 20px;
                    }
                    &.left-name {
                        width: 40%;
                    }
                }
            }
            @media #{$queryPc} {
                float: left;
                width: 400px;
                height: 754px;
            }
        }
        .wrap-right {
            @media #{$querySp} {

            }
            @media #{$queryPc} {
                float: right;
                width: 600px;
            }
        }

        .wrap-txt-con_box_01 {
            @media #{$querySp} {
                margin:0 0 20px 0;
            }
            @media #{$queryPc} {
                margin:0 0 40px 0;
            }
        }
        .wrap-txt_cot_box_02 {
            @media #{$querySp} {
                margin:0 0 40px 0;
            }
            @media #{$queryPc} {
                margin:0 0 80px 0;
            }
            .wrap-txt {
                a {
                    position: relative;
                    display: inline-block;
                    color: #ff5611;
                    padding: 0px 0 0 26px;
                    &:hover {
                        text-decoration: underline;
                    }
                    &:before {
                        @media #{$querySp} {
                            position: absolute;
                            top: -2px;
                            left: 0;
                            display: inline-block;
                            content: "";
                            width: 18px;
                            height: 22px;
                            background: url("../images/ico_pdf.png") no-repeat center top;
                            background-size: 18px auto;
                        }
                        @media #{$queryPc} {
                            position: absolute;
                            top: 3px;
                            left: 0;
                            display: inline-block;
                            content: "";
                            width: 18px;
                            height: 22px;
                            background: url("../images/ico_pdf.png") no-repeat center top;
                            background-size: 18px auto;
                        }
                    }
                }
                @media #{$querySp} {
                    padding: 0 20px;
                    margin: 0 0 16px 0;
                }
                @media #{$queryPc} {
                    padding: 0 40px;
                    margin: 0 0 32px 0;
                }
            }
            .wrap-img {
                text-align: center;
                img {
                    @media #{$querySp} {
                        width: 100%;
                    }
                }
            }
            ul {
                padding: 0 20px;
                li {
                    padding-left: 1em;
                    text-indent: -3em;
                }
            }
        }
        .wrap-txt_cot_box_03 {
            h5 {
                font-weight: bold;
                font-size: 1.2475em;
                @media #{$querySp} {
                    margin:0 0 10px 0;
                }
                @media #{$queryPc} {
                    margin:0 0 20px 0;
                }
            }
            @media #{$querySp} {
                margin:0 0 30px 0;
            }
            @media #{$queryPc} {
                margin:0 0 60px 0;
            }
        }
        .wrap-txt_cot_box_04 {
            border:1px solid #000;
            padding: 24px 32px;
            @media #{$querySp} {
                margin: 0 0 30px 0;
            }
            @media #{$queryPc} {
                margin: 0 0 60px 0;
            }
            h5 {
                &.under_line {
                    font-size: 1.125em;
                    font-weight: bold;
                    border-bottom: 1px solid #000;
                    @media #{$querySp} {
                        width: 100%;
                        padding:0 0 7px 0;
                        margin: 0 0 10px 0;
                    }
                    @media #{$queryPc} {
                        width: 498px;
                        padding:0 0 14px 0;
                        margin: 0 0 20px 0;
                    }
                }

            }
        }
        .tbl_type_01 {
            box-sizing: border-box;
            border-top: 1px solid #000;
            border-bottom: 1px solid #000;
            tr {
                border-bottom: 1px solid #000;
                td {
                    padding: 10px;
                    &:first-child {
                        @media #{$querySp} {
                            width: 40%;
                        }
                        @media #{$queryPc} {
                            width: 40%;
                        }
                    }
                    &.last-child {
                        @media #{$querySp} {
                            width: 60%;
                        }
                        @media #{$queryPc} {
                            width: 60%;
                        }
                    }
                }
            }
        }
    }
    &.col_2 {
        justify-content: initial;
        .wrap-menu {
            @media #{$querySp} {
                margin-bottom: 30px;
            }
            @media #{$queryPc} {
                float: left;
                margin: 0 2% 0 0;
            }
        }
        :last-child.wrap-menu {
            margin: 0 0 0 0;
        }
    }
}
